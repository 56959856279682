import BigNumber from 'bignumber.js'
import { getSafeGasPrice } from '../web3'

const lockTokens = async (efx, token, amount, forTime, isBaseUnits = false) => {
  let lockAmount = parseFloat(amount)

  if (isBaseUnits) {
    const { decimals } = efx.config['0x'].tokenRegistry[token]

    lockAmount = lockAmount.div(new BigNumber(10).pow(decimals))
  }

  try {
    const safeGasPrice = await getSafeGasPrice()

    const gasPrice = efx.web3.utils.toWei(safeGasPrice.toPrecision(5), 'gwei')

    efx.set('gasPrice', gasPrice)

    if (process.env.NODE_ENV !== 'production') {
      console.log(`gasPrice set to: ${gasPrice}`)
      console.log(`efx.contract.lock(${token}, ${lockAmount}, ${forTime})`)
    }

    return efx.contract.lock(token, lockAmount, forTime)
  } catch (err) {
    throw err
  }
}

export default lockTokens
