import omit from 'lodash/omit';
import { unsubPairs } from './wsUtils';

var removeTradingPair = function removeTradingPair(ws, pairs, channel, candlesData) {
  if (ws && pairs[channel]) {
    var _pairs$channel = pairs[channel],
        interval = _pairs$channel.interval,
        symbols = _pairs$channel.symbols;
    var subscription = Object.values(ws.subs).filter(function (sub) {
      return sub.key === "trade:".concat(interval, ":t").concat(symbols[0]).concat(symbols[1]);
    });

    if (subscription.length) {
      try {
        unsubPairs(ws.send.bind(ws), [subscription[0].chanId]);
        return [omit(pairs, channel), omit(candlesData, channel)];
      } catch (e) {
        console.warn(e);
      }
    }
  }

  return [omit(pairs, channel), candlesData];
};

export default removeTradingPair;