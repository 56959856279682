import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  Button, MenuItem, Alignment,
} from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import TokenIcon from './TokensSelect.icon'
import MenuItems from './TokenSelect.menuItems'
import { withTheme } from '../../utils/misc'
import { WITH_THEME } from './TokensSelect.constants'

const { SELECT_BUTTON, SELECT } = WITH_THEME

const StyledButton = withTheme(Button, SELECT_BUTTON)`
`

const StyledSelect = withTheme(Select, SELECT)`
  & .bp3-popover-target {
    width: 100%;
  }
`

const onSearch = cb => setFn => (event) => {
  cb(event.target.value)
  return setFn(event.target.value)
}

const createList = (list, onItemSelect, onItemSearch, header, footer, setOpen) => ({
  items: list,
  itemRenderer: item => <MenuItem icon='align-left' key={item} text={item} />,
  itemListRenderer: () => (
    <MenuItems
      list={list}
      header={header}
      footer={footer}
      onItemSelect={onItemSelect}
      onItemSearch={onItemSearch}
      setOpen={setOpen}
    />
  ),
})

const TokensMenu = ({
  list,
  selectedToken,
  query,
  onItemSelect,
  onItemSearch,
  header,
  footer,
  isOpen,
  setOpen,
}) => {
  const [search, setSearch] = useState(query)

  useEffect(() => {
    setTimeout(() => {
      setSearch('')
    }, 100)
  }, [selectedToken])

  const filteredList = list.filter(item => item.includes(search.toUpperCase()))

  const tokens = createList(
    filteredList,
    onItemSelect,
    onSearch(onItemSearch)(setSearch),
    header,
    footer,
    setOpen,
  )

  return (
    <StyledSelect
      items={tokens.items}
      filterable={false}
      itemListRenderer={tokens.itemListRenderer}
      itemRenderer={tokens.itemRenderer}
      popoverProps={{
        minimal: true,
        usePortal: true,
        isOpen,
        onInteraction: nextStatus => setOpen(nextStatus),
      }}
    >
      <StyledButton
        icon={(
          <TokenIcon
            symbol={selectedToken}
            size={24}
          />
        )}
        rightIcon='caret-down'
        text={selectedToken}
        minimal
        alignText={Alignment.LEFT}
        large
        onClick={setOpen}
      />
    </StyledSelect>
  )
}

TokensMenu.propTypes = {
  footer: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  header: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  isOpen: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  onItemSearch: PropTypes.func,
  onItemSelect: PropTypes.func,
  query: PropTypes.string,
  selectedToken: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
}

TokensMenu.defaultProps = {
  footer: null,
  header: null,
  onItemSearch: () => null,
  onItemSelect: () => null,
  query: '',
  isOpen: false,
}

export default TokensMenu
