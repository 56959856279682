export const METAMASK_ERROR = {
  NONE: null,
  NOT_DETECTED: 'Please install MetaMask',
  ACCESS_DENIED: 'Please approve Ethfinex Swap App',
  IS_LOCKED: 'Please unlock MetaMask',
}

export const PORTIS_ERROR = {
  NONE: null,
  LOGIN_DENIED: 'Please login to Portis',
  IS_LOCKED: 'Please unlock Portis',
}

export const LEDGER_ERROR = {
  NONE: null,
  LOGIN_DENIED: 'Please login to Ledger',
  IS_LOCKED: 'Please unlock Ledger',
}

export const SWAP_ERROR = {
  NONE: null,
  REVERT: 'The swap could not be reverted',
  UNLOCK: 'Tokens could not be unlocked',
  CANCELLED: 'The order was cancelled',
}

export const WALLET_ERROR = {
  NO_ERROR: null,
  IS_SAME_TOKEN: 'Swapping between the same token is not allowed',
  IS_NOT_SUPPORTED: 'Swap pair not supported',
}

export const AMOUNT_ERROR = {
  NO_ERROR: null,
  IS_NOT_POSITIVE: 'Please enter a positive amount',
  IS_NOT_MINIMUM_SIZE: 'Minimum order size is',
  IS_NOT_BALANCE: 'You do not have enough balance',
}

export const ORDER_BOOK_ERROR = {
  OUT_OF_SEQUENCE: 'OUT_OF_SEQUENCE',
  CHECKSUM_ERROR: 'CHECKSUM_ERROR',
  INVALID_MESSAGE: 'INVALID_MESSAGE',
}
