import { ReactComponent as ETH } from './assets/svg/ETH.svg'
import { ReactComponent as ETHAlt } from './assets/svg/ETH-alt.svg'
import { ReactComponent as USDAlt } from './assets/svg/USD-alt.svg'
import { ReactComponent as OMG } from './assets/svg/OMG.svg'
import { ReactComponent as OMGAlt } from './assets/svg/OMG-alt.svg'
import { ReactComponent as ZRX } from './assets/svg/ZRX.svg'
import { ReactComponent as ZRXAlt } from './assets/svg/ZRX-alt.svg'
import { ReactComponent as NEC } from './assets/svg/NEC.svg'
import { ReactComponent as NECAlt } from './assets/svg/NEC-alt.svg'
import { ReactComponent as SAN } from './assets/svg/SAN.svg'
import { ReactComponent as SANAlt } from './assets/svg/SAN-alt.svg'
import { ReactComponent as SNT } from './assets/svg/SNT.svg'
import { ReactComponent as SNTAlt } from './assets/svg/SNT-alt.svg'
import { ReactComponent as EDO } from './assets/svg/EDO.svg'
import { ReactComponent as EDOAlt } from './assets/svg/EDO-alt.svg'
import { ReactComponent as FUN } from './assets/svg/FUN.svg'
import { ReactComponent as FUNAlt } from './assets/svg/FUN-alt.svg'
import { ReactComponent as REP } from './assets/svg/REP.svg'
import { ReactComponent as REPAlt } from './assets/svg/REP-alt.svg'
import { ReactComponent as MKR } from './assets/svg/MKR.svg'
import { ReactComponent as MKRAlt } from './assets/svg/MKR-alt.svg'
import { ReactComponent as DAI } from './assets/svg/DAI.svg'
import { ReactComponent as DAIAlt } from './assets/svg/DAI-alt.svg'
import { ReactComponent as BAT } from './assets/svg/BAT.svg'
import { ReactComponent as BATAlt } from './assets/svg/BAT-alt.svg'
import { ReactComponent as NIO } from './assets/svg/NIO.svg'
import { ReactComponent as NIOAlt } from './assets/svg/NIO-alt.svg'
import { ReactComponent as SPK } from './assets/svg/SPK.svg'
import { ReactComponent as SPKAlt } from './assets/svg/SPK-alt.svg'
import { ReactComponent as LRC } from './assets/svg/LRC.svg'
import { ReactComponent as LRCAlt } from './assets/svg/LRC-alt.svg'
import { ReactComponent as ENJ } from './assets/svg/ENJ.svg'
import { ReactComponent as ENJAlt } from './assets/svg/ENJ-alt.svg'
import { ReactComponent as USD } from './assets/svg/USD.svg'

const EfxCryptoIcons = {
  ETH,
  ETHAlt,
  USDAlt,
  OMG,
  OMGAlt,
  ZRX,
  ZRXAlt,
  NEC,
  NECAlt,
  SAN,
  SANAlt,
  SNT,
  SNTAlt,
  EDO,
  EDOAlt,
  FUN,
  FUNAlt,
  REP,
  REPAlt,
  MKR,
  MKRAlt,
  DAI,
  DAIAlt,
  BAT,
  BATAlt,
  NIO,
  NIOAlt,
  SPK,
  SPKAlt,
  LRC,
  LRCAlt,
  ENJ,
  ENJAlt,
  USD,
}

export default EfxCryptoIcons
