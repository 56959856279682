import { v3 } from 'murmurhash'
import { WALLET_ERROR } from '../../const'

const onTokenSelect = (
  wallet, dex, wsActions, pairData, select,
) => tokenA => (tokenB) => {
  const { pairsHash, tokenRegistry } = dex.state

  const { chanId, chanIdBooks } = pairData.state

  const { NO_ERROR, IS_SAME_TOKEN, IS_NOT_SUPPORTED } = WALLET_ERROR

  const tokens = {
    from: {
      fromToken: tokenB,
      toToken: tokenA,
      errorSource: 'from',
    },
    to: {
      fromToken: tokenA,
      toToken: tokenB,
      errorSource: 'to',
    },
  }

  const {
    setAlertWallet, setSwapPairWallet, resetBalancesWallet, setSwapAmountsWallet,
  } = wallet.dispatch

  const { subPair, unsubPair } = wsActions

  const { fromToken, toToken, errorSource } = tokens[select]

  const hash = v3(`${fromToken}${toToken}`)

  if (chanId) {
    unsubPair(chanId)
  }

  if (chanIdBooks) {
    unsubPair(chanIdBooks)
  }

  if (pairsHash[hash]) {
    const pair = pairsHash[hash].pair.join('')

    subPair(pair, 'book')

    subPair(pair, 'ticker')

    setAlertWallet('resetSelect', NO_ERROR)

    setSwapPairWallet(tokenRegistry[fromToken], tokenRegistry[toToken], pairsHash[hash].pair)

    resetBalancesWallet([fromToken, toToken])

    setSwapAmountsWallet(undefined, undefined, null)

    return
  }

  if (fromToken === toToken) {
    resetBalancesWallet([fromToken, toToken])

    setAlertWallet(errorSource, IS_SAME_TOKEN)
  } else {
    setAlertWallet(errorSource, IS_NOT_SUPPORTED)
  }

  setSwapPairWallet(tokenRegistry[fromToken], tokenRegistry[toToken], [null, null])
  setSwapAmountsWallet(undefined, undefined, null)
}

export default onTokenSelect
