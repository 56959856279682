import moment from 'moment';

var processStreamEvent = function processStreamEvent(event) {
  try {
    var msg = JSON.parse(event.data);
    var data = msg.data,
        table = msg.table;
    var interval = table.replace('tradeBin', '');
    var _data$ = data[0],
        timestamp = _data$.timestamp,
        open = _data$.open,
        high = _data$.high,
        low = _data$.low,
        close = _data$.close,
        volume = _data$.volume,
        symbol = _data$.symbol;
    var ticker = "".concat(symbol.slice(0, -3), "USD");
    var candleData = [moment(timestamp).valueOf(), open, high, low, close, volume];
    return [ticker, candleData, interval];
  } catch (e) {
    console.warn(e);
  }
};

export default processStreamEvent;