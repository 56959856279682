var makeSubs = function makeSubs(pairs) {
  var subscriptions = [];

  for (var channel in pairs) {
    var _pairs$channel = pairs[channel],
        interval = _pairs$channel.interval,
        symbols = _pairs$channel.symbols;
    subscriptions.push({
      event: 'subscribe',
      channel: 'candles',
      key: "trade:".concat(interval, ":t").concat(symbols[0]).concat(symbols[1])
    });
  }

  return subscriptions;
};

export default makeSubs;