import './index.scss';
import React from 'react';
import { Grommet } from 'grommet';
import { device, size } from 'const';
import { useWindowSize } from 'utils/ui';
import Home from './components/pages/Home';
import theme from './theme';

const App = () => {
  const [windowSize] = useWindowSize();
  const customSettings = {
    ui: { device, windowSize, breakpoint: { ...size } }
  };

  return (
    <div>
      <Grommet theme={{ ...theme, ...customSettings }} full>
        <Home />
      </Grommet>
    </div>
  );
};

export default App;
