import moment from 'moment';

var isNotPastCandle = function isNotPastCandle(availableDataForThePeriod, streamData) {
  var channel = "".concat(streamData[2], ":").concat(streamData[0]);

  if (availableDataForThePeriod[channel] && moment(streamData[1].T).valueOf() > availableDataForThePeriod[channel].end) {
    return true;
  }

  return false;
};

export default isNotPastCandle;