import BigNumber from 'bignumber.js'
import { getSafeGasPrice } from '../web3'

const unlockTokens = async (efx, token, amount, nonce, signature, isBaseUnits = false) => {
  let lockedAmount = new BigNumber(amount)

  if (isBaseUnits) {
    const { decimals } = efx.config['0x'].tokenRegistry[token]

    lockedAmount = lockedAmount.div(new BigNumber(10).pow(decimals))
  }

  try {
    const safeGasPrice = await getSafeGasPrice()

    const gasPrice = efx.web3.utils.toWei(safeGasPrice.toPrecision(5), 'gwei')

    efx.set('gasPrice', gasPrice)

    if (process.env.NODE_ENV !== 'production') {
      console.log(`gasPrice: ${gasPrice} wei`)
      console.log(`efx.contract.unlock(${token}, ${lockedAmount})`)
    }

    const response = await efx.contract.unlock(token, lockedAmount, nonce, signature)

    if (response.error) throw response

    return response
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(err)
    }

    throw err
  }
}

export default unlockTokens
