import { Observable } from 'rxjs';
import ExchangeConnectorWrapper from 'utils/exchangeConnector';

const fetchDataTickersFromWs$ = (symbolsArray, relayName, networkId = 1) => {
  const subscribedSymbols = [];
  return Observable.create(observer => {
    const ethfinex = ExchangeConnectorWrapper.getInstance().getExchange(
      relayName,
      {
        networkId
      }
    );

    if (symbolsArray.length) {
      const unsubPromise = ethfinex.raw.ws
        .getTickers(
          {
            symbols: symbolsArray
          },
          (err, msgWs) => {
            if (err) {
              return observer.error(err);
            }
            if (msgWs.event === 'subscribed') {
              subscribedSymbols[msgWs.chanId] = msgWs.symbol
                .split('t')[1]
                .slice(0, -3);
            }
            if (Array.isArray(msgWs)) {
              return observer.next([subscribedSymbols[msgWs[0]], msgWs[1]]);
            }
            return null;
          }
        )
        // eslint-disable-next-line no-console
        .catch(err => console.error(err));
      return async () => {
        const unsub = await unsubPromise;
        if (unsub) {
          return unsub();
        }
        return null;
      };
    }
  });
};

export default fetchDataTickersFromWs$;
