import _defineProperty from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var addChannelToCandlesData = function addChannelToCandlesData(candlesData, data) {
  console.log(data);
  var channel = "".concat(data[2], ":").concat(data[0]);

  if (candlesData[channel]) {
    return candlesData;
  }

  console.log(channel);
  return _objectSpread({}, candlesData, _defineProperty({}, channel, {
    pair: data[0],
    interval: data[2],
    candles: [],
    updates: null,
    meta: {
      isSnapshot: false,
      isNewCandle: false,
      isUpdateCandle: false
    }
  }));
};

export default addChannelToCandlesData;