import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import { Collapse } from '@blueprintjs/core'
import u from 'updeep'
import SectionContainerHeader from './SectionContainer.header'
import CollapseSwitch from './SectionContainer.collapseSwitch'
import HelpIcon from './SectionContainer.helpIcon'

const WrapperSection = styled.div`
  padding-top: 10px;
  background-color: inherit;
  color: inherit;
  position: relative;
`

const ActionIcon = styled.div`
  margin-left: 5px;
`

const renderActions = (headerTitle, options) => {
  const actions = []

  if (options.collapse) {
    const { isOpen, cbFn } = options.collapse

    actions.push(
      <div
        id={`box-container-${kebabCase(headerTitle)}-action-expand`}
        key={`box-container-${kebabCase(headerTitle)}-action-expand`}
      >
        <ActionIcon>
          <CollapseSwitch
            collapsed={isOpen || false}
            onToggleSwitch={status => cbFn('collapse', { status })}
          />
        </ActionIcon>
      </div>,
    )
  }

  if (options.help) {
    actions.push(
      <div
        id={`box-container-${kebabCase(headerTitle)}-action-help`}
        key={`box-container-${kebabCase(headerTitle)}-action-help`}
      >
        <ActionIcon>
          <HelpIcon text={options.help.text} />
        </ActionIcon>
      </div>,
    )
  }

  return actions
}

const SectionContainer = ({ headerTitle, options, children }) => {
  const [isOpen, setIsOpen] = useState(get(options, ['collapse', 'isOpen'], false))

  const onActionClick = (actionType, actionData) => {
    switch (actionType) {
      case 'collapse': {
        return setIsOpen(actionData.status)
      }
      default:
        return null
    }
  }

  const sectionActions = useCallback(() => {
    let sectionOptions = { ...options }

    if (sectionOptions.collapse) {
      sectionOptions = u({ collapse: { isOpen, cbFn: () => onActionClick } }, sectionOptions)
    }
    return renderActions(headerTitle, sectionOptions)
  }, [headerTitle, isOpen, options])

  return (
    <WrapperSection id={`box-container-${kebabCase(headerTitle)}`}>
      <SectionContainerHeader
        titleText={headerTitle}
        showTitle={!isOpen}
        actions={sectionActions}
      />
      <Collapse keepChildrenMounted isOpen={isOpen}>{children}</Collapse>
    </WrapperSection>
  )
}

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.object),
}

SectionContainer.defaultProps = {
  options: { collapse: { isOpen: true }, help: { text: '' } },
}

export default React.memo(SectionContainer)
