import React from 'react';

const Footer = () => (
  <img src="/img/crypto-icons/color/nec@2x.png" alt="nec-logo" />
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
