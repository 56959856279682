import PropTypes from 'prop-types'
import React from 'react'
import { Image } from 'grommet'

export const ALIGN = {
  START: 'start',
  CENTER: 'center',
  END: 'end',
  STRETCH: 'stretch',
}

export const FIT = {
  COVER: 'cover',
  CONTAIN: 'contain',
}

export const OPACITY = {
  WEAK: 'weak',
  MEDIUM: 'medium',
  STRONG: 'strong',
  STRING: 'string',
  TRUE: true,
  FALSE: false,
}

const SimpleImage = ({
  align, fit, opacity, src,
}) => (
  <Image align={align} fit={fit} opacity={opacity} src={src} />
)

SimpleImage.propTypes = {
  align: PropTypes.oneOf(Object.values(ALIGN)),
  fit: PropTypes.oneOf(Object.values(FIT)),
  opacity: PropTypes.oneOf(Object.values(OPACITY)),
  src: PropTypes.string.isRequired,
}

SimpleImage.defaultProps = {
  align: ALIGN.START,
  fit: FIT.COVER,
  opacity: OPACITY.FALSE,
}

export default React.memo(SimpleImage)
