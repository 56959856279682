import {
  useEffect, useReducer, useMemo, useRef,
} from 'react'
import walletReducer, { initWalletState } from './walletReducer'
import * as actions from './walletActions'
import { mapActions } from '../../utils/misc'
import wallets from '../../utils/wallets'
import { dexWrapper } from '../../utils/dex'
import initHelper from '../../utils/wallets/initHelper'
import onWallet from './onWallet'
import onNewBlock from './onNewBlock'
import { Intent, WALLETS, WALLET_STATUS } from '../../const'
import onLedgerWalletInteraction from './onLedgerWalletInteraction'
import { formatError } from '../../utils/format'

const { LOGGING, LOGGED_IN, LOGGED_OUT } = WALLET_STATUS

const useWallet = (network) => {
  const [data, dispatch] = useReducer(walletReducer, initWalletState)

  const dispatchAction = useMemo(() => mapActions(dispatch)(actions), [dispatch])

  const walletHelper = useRef(initHelper())

  const loginCount = useRef(0)

  const {
    source,
    status,
    swap: { fromToken, toToken },
    endpoint: { blockNumber },
    selectedAccount,
  } = data

  const accountAddress = (selectedAccount && selectedAccount.address) || null

  const { updateWallet } = dispatchAction

  // Initializes correct wallet helper on wallet selection
  try {
    useEffect(() => {
      loginCount.current += 1

      if (source) {
        const prevLoginCount = loginCount.current
        let wallet
        const statusUpdate = { status: LOGGING }

        let onWalletInteraction = () => null
        if (source === WALLETS.LEDGER) {
          onWalletInteraction = (interactionType, isInteractionPending) => {
            onLedgerWalletInteraction(updateWallet)(interactionType, isInteractionPending)
          }
        }

        updateWallet(statusUpdate)

        walletHelper.current = wallets[source]()

        walletHelper.current
          .init({ onWalletInteraction })
          .then(async (result) => {
            const [accounts, networkId] = result
            if (network.id !== networkId) {
              throw Error(`Please connect your wallet to ${network.name} network`)
            }
            wallet = {
              accounts,
              selectedAccount: accounts[0],
              source,
              statusMsg: { msg: null, type: null },
              endpoint: {
                blockNumber: null,
                error: null,
                networkId,
              },
              status: LOGGED_IN,
            }
            if (loginCount.current === prevLoginCount) {
              updateWallet(wallet)
            }
          })
          .catch((err) => {
            wallet = {
              ...wallet,
              status: LOGGED_OUT,
              statusMsg: {
                msg: formatError(err),
                type: Intent.DANGER,
              },
            }
            if (loginCount.current === prevLoginCount) {
              updateWallet(wallet)
            }
          })
      }
    }, [dispatchAction, network.id, network.name, source, updateWallet])
  } catch (err) {
    // TODO: Add error notificaion system.
  }

  // Handles wallet and dex setup login and logout
  useEffect(() => {
    switch (status) {
      case LOGGED_IN: {
        const walletSource = walletHelper.current.getSource()
        walletHelper.current.subscribe(
          onWallet(updateWallet, walletSource.source),
          onNewBlock(updateWallet),
        )
        break
      }
      case LOGGED_OUT: {
        dexWrapper.reset()
        walletHelper.current.close()
        break
      }
      case LOGGING: {
        break
      }
      default: {
        dexWrapper.reset()
        walletHelper.current.close()
      }
    }
  }, [dispatchAction, status, updateWallet])

  // Updates accouns balances on new block
  useEffect(() => {
    if (accountAddress) {
      walletHelper.current
        .getBalances(accountAddress, fromToken, toToken)
        .then((balances) => {
          const b = {
            [fromToken.symbol]: {
              token: balances[0],
              wrapper: balances[2],
              status: null,
            },
            [toToken.symbol]: {
              token: balances[1],
              wrapper: balances[3],
              status: null,
            },
          }
          Object.keys(b).forEach(token =>
            dispatchAction.updateBalancesWallet(token, { ...b[token] }))
        })
        .catch(() => {
          dispatchAction.updateBalancesWallet(fromToken.symbol, [null, null])

          dispatchAction.updateBalancesWallet(toToken.symbol, [null, null])
        })
    }
  }, [dispatchAction, fromToken, toToken, blockNumber, accountAddress])


  const memoWallet = useMemo(() => ([{
    state: data,
    dispatch: dispatchAction,
    helper: walletHelper.current,
  }]), [data, dispatchAction])

  return memoWallet
}

export default useWallet
