import { css } from 'styled-components'

export const WITH_THEME = {
  CONTAINER: 'Container',
}

export const defTheme = {
  [WITH_THEME.CONTAINER]: css`
  `,
}
