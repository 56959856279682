var partial = require('lodash/partial');

var _require = require('rxjs'),
    Subject = _require.Subject;

var addOrder = require('../utils/orders/addOrder');

var deleteOrder = require('../utils/orders/deleteOrder');

var updateOrder = require('../utils/orders/updateOrder');

var addPosition = require('../utils/positions/addPosition');

var deletePosition = require('../utils/positions/deletePosition');

var updatePosition = require('../utils/positions/updatePosition');

var db = require('./db');

module.exports = function (tvChart) {
  var tvUtil = {
    order: {},
    position: {},
    tvChart: tvChart
  };
  var onInteracton$ = new Subject();

  var compose = function compose(funk) {
    return partial(funk, tvUtil, db, onInteracton$);
  };

  tvUtil.interactions$ = onInteracton$; // Orders

  tvUtil.order.add = compose(addOrder);
  tvUtil.order.delete = compose(deleteOrder);
  tvUtil.order.update = compose(updateOrder); // Positions

  tvUtil.position.add = compose(addPosition);
  tvUtil.position.delete = compose(deletePosition);
  tvUtil.position.update = compose(updatePosition);
  return tvUtil;
};