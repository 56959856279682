import HookedWalletSubprovider from 'web3-provider-engine/subproviders/hooked-wallet'
import { toBuffer, toRpcSig, addHexPrefix } from 'ethereumjs-util'
import TransportU2F from '@ledgerhq/hw-transport-u2f'
import Eth from '@ledgerhq/hw-app-eth'
import Tx from 'ethereumjs-tx'
import conf from '../../../../env'
import { getSafeGasPrice, web3Wrapper } from '../../../web3'

export const approveTransaction = onWalletInteraction => async (txParams, cb) => {
  const isInteractionPending = true
  onWalletInteraction('approveTransaction', isInteractionPending)
  try {
    const web3 = web3Wrapper.getInstance()
    const gasLimit = await web3.eth.estimateGas(txParams)
    const safeGasPrice = await getSafeGasPrice()
    const gasPrice = web3.utils.numberToHex(web3.utils.toWei(safeGasPrice.toString(), 'gwei'))
    cb(null, { ...txParams, gasLimit, gasPrice })
  } catch (e) {
    onWalletInteraction('approveTransaction', !isInteractionPending)
    cb(e)
  }
}

// eslint-disable-next-line no-unused-vars
export const signMessage = (ledgerPath, tmp, onWalletInteraction) => async (msgParams, cb) => {
  const isInteractionPending = true
  onWalletInteraction('signMessage', isInteractionPending)
  try {
    const message = toBuffer(msgParams.data).toString('hex')
    const transport = await TransportU2F.create()
    const eth = new Eth(transport)
    const signed = await eth.signPersonalMessage(ledgerPath, message)
    const signature = toRpcSig(signed.v, toBuffer(`0x${signed.r}`), toBuffer(`0x${signed.s}`))
    cb(null, signature)
  } catch (e) {
    onWalletInteraction('signMessage', !isInteractionPending)
    cb(e)
  }
}

const signTransaction = (ledgerPath, onWalletInteraction) => async (txParams, cb) => {
  const isInteractionPending = true
  onWalletInteraction(
    'signTransaction',
    isInteractionPending,
  )
  try {
    const rawTx = {
      ...txParams,
      chainId: conf.network.id,
    }
    const transport = await TransportU2F.create()
    const eth = new Eth(transport)
    const ethTx = new Tx({
      ...rawTx,
      v: Buffer.from([rawTx.chainId]),
      r: toBuffer(0),
      s: toBuffer(0),
    })

    const rsv = await eth.signTransaction(ledgerPath, ethTx.serialize().toString('hex'))
    const signedTx = new Tx({
      ...rawTx,
      r: addHexPrefix(rsv.r),
      s: addHexPrefix(rsv.s),
      v: addHexPrefix(rsv.v),
    })
    cb(null, `0x${signedTx.serialize().toString('hex')}`)
    onWalletInteraction('signTransaction', !isInteractionPending)
  } catch (e) {
    cb(e)
  }
}

export default class LedgerProvider extends HookedWalletSubprovider {
  constructor(path, address, onWalletInteraction = () => null) {
    super({
      getAccounts: cb => cb(null, [this.address]),
      signTransaction: signTransaction(path, onWalletInteraction),
      signPersonalMessage: signMessage(path, 'signPersonalMessage', onWalletInteraction),
      signMessage: signMessage(path, 'signMessage', onWalletInteraction),
      approveTransaction: approveTransaction(onWalletInteraction),
    })
    this.address = address
    this.path = path
    // eslint-disable-next-line no-unused-vars
    const self = this
  }
}
