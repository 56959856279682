import EFX from 'efx-api-node'
import conf from '../../env'

const dexWrapper = (() => {
  let instance

  return {
    init: async (web3, options = {}) => {
      if (!instance) {
        instance = await EFX(web3, {
          api: conf.api,
          ...options,
        })
      }
      return instance
    },
    getInstance: () => instance,
    reset: () => { instance = undefined },
  }
})()

export default dexWrapper
