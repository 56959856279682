import _slicedToArray from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var formatter = {
  tradingview: function tradingview(data) {
    var date = data.date,
        open = data.open,
        close = data.close,
        high = data.high,
        low = data.low,
        volume = data.volume,
        time = data.time;

    if (time) {
      return data;
    }

    return {
      time: date * 1000,
      open: open,
      close: close,
      high: high,
      low: low,
      volume: volume
    };
  },
  default: function _default(data) {
    var _data = _slicedToArray(data, 6),
        date = _data[0],
        open = _data[1],
        close = _data[2],
        high = _data[3],
        low = _data[4],
        volume = _data[5];

    return {
      time: date * 1000,
      open: open,
      close: close,
      high: high,
      low: low,
      volume: volume
    };
  }
};
export default formatter;