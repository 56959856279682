import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { connectWs } from '../../../utils/ws';
var ws;

var makeDataStream = function makeDataStream(wsUrlFn, options) {
  var wsInstance$ = options.wsInstance$;
  var dataFeed$ = Observable.create(function (observer) {
    console.warn('Binance dataFeed$ opened');

    var pushEvent = function pushEvent(event) {
      return observer.next(event);
    };

    ws = connectWs(wsUrlFn(), {
      initSubs: options && options.initSubs || {},
      onReconnectCb: function onReconnectCb(err, data) {
        ws.removeEventListener('message', pushEvent);
        ws = data;
        ws.addEventListener('message', pushEvent);
      }
    });
    ws.addEventListener('message', pushEvent);
    wsInstance$.next(ws);
    return function () {
      if (ws) {
        console.warn('Binance dataFeed$ closed');
        ws.close(1000, 'Close handle was called', {
          keepClosed: true
        });
      }
    };
  }).pipe(filter(function (msg) {
    return msg;
  }));
  return dataFeed$;
};

export default makeDataStream;