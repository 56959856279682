import {
  useEffect, useReducer, useMemo, useRef,
} from 'react'
import Web3 from 'web3'
import dexReducer, { initDexState } from './dexReducer'
import getConfDex from './getConfDex'
import * as actions from './dexActions'
import {
  mapActions, getState, omit, pipe, manageSessionStorage,
} from '../../utils/misc'
import conf from '../../env'
import ExABI from '../../utils/dex/zeroExExchangeV2'
import { SWAP_STATUS, SWAP_STEPS } from '../../const'
import dexUtils from './dexUtils'
import { swapStepUtils } from '../../utils/validation'

const useDex = (from, to, reverse, setSwapPairWallet, walletSource) => {
  const [data, dispatch] = useReducer(dexReducer, initDexState)

  const prevWalletSource = useRef(walletSource)

  const dispatchAction = useMemo(() => mapActions(dispatch)(actions), [dispatch])

  const dexHelper = useRef(dexUtils(dispatchAction.updateAuthSignatures))

  // Stores signatures on sessionStorage
  useEffect(() => {
    if (Object.entries(data.signatures).length !== 0) {
      manageSessionStorage.setItem('signatures', data.signatures)
      dexHelper.current.setSignatures(data.signatures)
    }
  }, [data.signatures])

  // Stores transactions on sessionStorage
  useEffect(() => {
    if (Object.entries(data.transactions).length !== 0) {
      manageSessionStorage.setItem('transactions', data.transactions)
    }
  }, [data.transactions])

  // Restores signatures from sessionStorage
  useEffect(() => {
    dexHelper.current = dexUtils(dispatchAction.updateAuthSignatures)
    if (process.env.NODE_ENV !== 'production') {
      console.log('Restore signature from sessionStorage')
    }

    const restoredSignatures = manageSessionStorage.getItem('signatures')
    dispatchAction.restoreAuthSignatures(restoredSignatures)
  }, [dispatchAction, walletSource])

  // Restores transactions from sessionStorage, filters unsubmitted and sets correct
  // status for settled transactions
  useEffect(() => {
    const restoredTransactions = manageSessionStorage.getItem('transactions')

    if (!restoredTransactions) {
      return
    }

    const filterUnSubmittedTransactions = transactions =>
      Object.values(transactions).reduce((acc, transaction) => {
        if (
          [SWAP_STATUS.LOCK_PENDING, SWAP_STATUS.SIGN_PENDING].includes(
            transaction.status[transaction.status.length - 1],
          )
        ) {
          return acc
        }
        return {
          ...acc,
          [transaction.swap.hash]: { ...transaction, alert: { msg: null, type: null } },
        }
      }, {})

    const filterCompletedTransactions = (transactions) => {
      const stepStatus = swapStepUtils(SWAP_STEPS)
      return Object.values(transactions).filter(
        transaction => stepStatus.getProgress(transaction.status) !== 1,
      )
    }

    const pendingTransactions = pipe(
      filterUnSubmittedTransactions,
      (transactions) => {
        dispatchAction.restoreTransactions(transactions)
        return transactions
      },
      filterCompletedTransactions,
    )(restoredTransactions)

    if (pendingTransactions.length) {
      const id = setInterval(async () => {
        if (pendingTransactions.length === 0) {
          clearInterval(id)
        }

        let dexConf
        let web3
        let ExchangeContract
        let lastBlockNumber

        try {
          dexConf = await getConfDex()

          web3 = new Web3(conf.wsUrlWeb3)

          lastBlockNumber = await web3.eth.getBlockNumber()

          ExchangeContract = new web3.eth.Contract(ExABI, dexConf.exchangeAddress)
        } catch (err) {
          return
        }

        pendingTransactions.forEach((transaction, index) => {
          if (transaction.values && transaction.values.txReceipt) {
            dispatchAction.setStatusTransaction(transaction.swap.hash, SWAP_STATUS.SUCCESS)
            pendingTransactions.splice(index, 1)
            return
          }

          const orderHash = transaction.values && transaction.values.orderHash
          if (orderHash) {
            ExchangeContract.getPastEvents('Fill', {
              filter: {
                orderHash,
              },
              fromBlock: Number(lastBlockNumber) - 1000,
              toBlock: 'latest',
            })
              .then((events) => {
                if (events[0]) {
                  dispatchAction.setStatusTransaction(transaction.swap.hash, SWAP_STATUS.SUCCESS)

                  dispatchAction.setValuesTransaction(transaction.swap.hash, {
                    txReceipt: { ...events[0] },
                  })
                  pendingTransactions.splice(index, 1)
                }
              })
              .catch(() => {})
          }
        })
      }, 2000)
    }
  }, [dispatchAction])

  // Filters and sets correct status on user wallet change
  useEffect(() => {
    if (prevWalletSource.current !== walletSource) {
      let prevTransactions = { ...data.transactions }
      Object.keys(prevTransactions).forEach((key) => {
        prevTransactions[key].alert = { msg: null, type: null }

        if (
          [SWAP_STATUS.LOCK_PENDING, SWAP_STATUS.SIGN_PENDING].includes(
            prevTransactions[key].status[prevTransactions[key].status.length - 1],
          )
        ) {
          prevTransactions = omit(prevTransactions, key)
        }
      })
      prevWalletSource.current = walletSource

      dispatchAction.restoreTransactions(prevTransactions)
    }
  }, [data.transactions, dispatchAction, walletSource])

  // Pulls and updates API configuration
  useEffect(() => {
    setInterval(() => {
      getConfDex()
        .then((dexConf) => {
          dispatchAction.updateApiConfDex(dexConf)
        })
        .catch((err) => {
          dispatchAction.updateApiConfDex({ error: err })
        })
    }, 5000)
  }, [dispatchAction])

  // Pulls and updates API on token selections and tokens switch
  useEffect(() => {
    getConfDex()
      .then((dexConf) => {
        const { tokenRegistry } = dexConf
        const { fromToken, toToken } = conf
        dispatchAction.updateApiConfDex(dexConf)

        setSwapPairWallet(
          tokenRegistry[from] || fromToken,
          tokenRegistry[to] || toToken,
          (reverse && [to, from]) || [from, to],
        )
      })
      .catch((err) => {
        dispatchAction.updateApiConfDex({ error: err })
      })

    return () => {}
  }, [dispatchAction, from, reverse, setSwapPairWallet, to])

  const dex = {
    state: getState(data),
    dispatch: dispatchAction,
    helper: dexHelper.current,
  }

  return [dex]
}

export default useDex
