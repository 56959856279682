import BigNumber from 'bignumber.js'

import { AMOUNT_ERROR } from '../../const'

const isPositiveNumber = (amount) => {
  if (new BigNumber(amount).isPositive()) {
    return amount
  }
  throw new Error(AMOUNT_ERROR.IS_NOT_POSITIVE)
}
export default isPositiveNumber
