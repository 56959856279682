import lockToken from './lockToken'
import createOrder from './createOrder'
import signOrder from './signOrder'
import submitOrder from './submitOrder'
import monitorOrder from './monitorOrder'
import unlockToken from './unlockToken'

const steps = {
  createOrder,
  lockToken,
  signOrder,
  submitOrder,
  monitorOrder,
  unlockToken,
}

export default steps
