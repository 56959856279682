var u = require('updeep');

var _require = require('../../const'),
    lineType = _require.lineType;

module.exports = function (tvUtil, db, onInteraction$, orderId, orderUpdate) {
  var data = orderUpdate.data,
      style = orderUpdate.style;
  var ORDER_LINE = lineType.ORDER_LINE;
  var prevOrder = db.get(orderId, ORDER_LINE);
  data.id = orderId;
  var order = {
    data: u(data, prevOrder.data),
    style: u(style, prevOrder.style)
  };

  try {
    prevOrder.tvLine.remove();
    return tvUtil.order.add(order);
  } catch (err) {
    return {
      error: err.message
    };
  }
};