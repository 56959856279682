import BigNumber from 'bignumber.js'

import { AMOUNT_ERROR } from '../../const'

const isMinOrderSize = token => (amount) => {
  if (new BigNumber(amount).isZero() || new BigNumber(amount).gte(token.minOrderSize)) {
    return amount
  }
  throw new Error(`${AMOUNT_ERROR.IS_NOT_MINIMUM_SIZE} ${token.minOrderSize} ${token.symbol}`)
}

export default isMinOrderSize
