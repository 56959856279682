import {
  Intent, SWAP_STATUS, SWAP_ERROR, ALERT_MSG,
} from '../../const'
import { formatError } from '../format'

const onCancelTransaction = async (helper, dispatch, orderId, swapHash) => {
  try {
    await helper.initDex()
    const response = await helper.cancelOrders([orderId])
    if (response[0] && response[0].error) {
      dispatch.setAlertTransaction(
        swapHash,
        response[0].error.reason,
        Intent.WARNING,
        Date.now(),
      )
    } else {
      dispatch.setAlertTransaction(
        swapHash,
        ALERT_MSG.ORDER_CANCELLED_SUCCESS,
        Intent.SUCCESS,
        Date.now(),
      )
      dispatch.setStatusTransaction(swapHash, SWAP_STATUS.CANCELLED)
      dispatch.updateTransaction(swapHash, { error: { message: SWAP_ERROR.CANCELLED } })
    }
  } catch (err) {
    dispatch.setAlertTransaction(swapHash, formatError(err), Intent.DANGER, Date.now())
  }
}

export default onCancelTransaction
