import React, { useState, useEffect } from 'react';
import { Box } from 'grommet';
import { connect } from 'react-redux';
import TokensTable from 'components/organism/TokensTable';
import PropTypes from 'prop-types';
import { Search } from 'grommet-icons';
import SimpleTextInput from 'components/atoms/SImpleTextInput';
import actions from 'store/actions';
import { DEFAULT_ENDPOINT } from 'const';
import { onSearchToken } from 'utils/forms';

const TokensList = ({ tokens, fetchTokensTickers }) => {
  const [tokenList, setTokenList] = useState(tokens);

  useEffect(() => {
    fetchTokensTickers(tokens);
  }, [fetchTokensTickers, tokens]);

  return (
    <Box id="tokens-list" fill>
      <Box>
        <Box align="center" pad={{ bottom: 'xxsmall' }} background="white">
          <Box
            id="tokens-list-search-wrapper"
            width="large"
            direction="row"
            align="center"
            pad={{ horizontal: 'small', vertical: 'xsmall' }}
            elevation="xsmall"
          >
            <Search color="brand" />
            <SimpleTextInput
              id="tokens-list-search-input"
              type="search"
              plain
              placeholder="Search tokens..."
              onChange={onSearchToken(setTokenList, tokens)}
            />
          </Box>
        </Box>
      </Box>
      <Box fill>
        <TokensTable tokens={tokenList} />
      </Box>
    </Box>
  );
};

TokensList.propTypes = {
  tokens: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchTokensTickers: PropTypes.func.isRequired
};

TokensList.defaultProps = {};

const mapStatoToProps = (state, ownProps) => ({
  ...ownProps,
  tokens: state.tokens
});

const mapDispatchToProps = dispatch => ({
  fetchTokensTickers: tokens =>
    dispatch(
      actions.tokensData.tickers.start(
        tokens,
        DEFAULT_ENDPOINT.relayName,
        DEFAULT_ENDPOINT.networkId
      )
    )
});

export default connect(
  mapStatoToProps,
  mapDispatchToProps
)(React.memo(TokensList));
