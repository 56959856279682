import { useWallet, useWebSocket, useDex, useTicker } from './hooks';

const useInitApp = (from, to, reverse, network) => {
  const [wallet] = useWallet(network);

  const [dex] = useDex(
    from,
    to,
    reverse,
    wallet.dispatch.setSwapPairWallet,
    wallet.state.source
  );

  const [data, ws] = useWebSocket();

  const [pairData] = useTicker(data, dex, wallet);

  return [wallet, dex, data, ws, pairData];
};

export default useInitApp;
