import without from 'lodash/without';
var subscribedPairs = [];

var processStreamEvent = function processStreamEvent(event) {
  var msg = JSON.parse(event.data);

  if (Array.isArray(msg) && subscribedPairs[msg[0]] && msg[1] !== 'hb') {
    var _subscribedPairs$msg$ = subscribedPairs[msg[0]],
        interval = _subscribedPairs$msg$.interval,
        ticker = _subscribedPairs$msg$.ticker;
    return [ticker, msg[1], interval];
  }

  if (msg.event === 'subscribed') {
    var keys = msg.key.split(':');
    subscribedPairs[msg.chanId] = "".concat(keys[1], ":").concat(keys[2].substr(1));
    subscribedPairs[msg.chanId] = {
      chanId: msg.chanId,
      interval: keys[1],
      ticker: keys[2].substr(1)
    };
  }

  if (msg.event === 'unsubscribed') {
    subscribedPairs = without(subscribedPairs, msg.chanId);
  }

  return null;
};

export default processStreamEvent;