export const BAT = 'BAT';
export const BNB = 'BNB';
export const OMG = 'OMG';
export const GNT = 'GNT';
export const MKR = 'MKR';
export const REP = 'REP';
export const ZIL = 'ZIL';
export const ZRX = 'ZRX';
export const USDT = 'USDT';
export const ETH = 'ETH';

export const ERC20_TOKENS = {
  mainnet: {
    BAT: {
      symbol: 'BAT',
      name: 'Basic Attention Token',
      meta: {
        isEthereum: true,
        isRC20: true,
        address: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
        decimals: 18
      }
    },
    USD: {
      symbol: 'USD',
      name: 'US Dollar',
      meta: {
        isEthereum: true,
        isRC20: true,
        address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        decimals: 6
      }
    },
    DAI: {
      symbol: 'DAI',
      name: 'Dai Token',
      meta: {
        isEthereum: true,
        isRC20: true,
        address: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
        decimals: 18
      }
    },
    EDO: {
      symbol: 'EDO',
      name: 'Eidoo Token',
      meta: {
        isEthereum: true,
        isRC20: true,
        address: '0xced4e93198734ddaff8492d525bd258d49eb388e',
        decimals: 18
      }
    },
    ENJ: {
      symbol: 'ENJ',
      name: 'Enjin Coin',
      meta: {
        isEthereum: true,
        isRC20: true,
        address: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
        decimals: 18
      }
    },
    ETH: {
      symbol: 'ETH',
      name: 'Ethereum',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0x',
        decimals: 18
      }
    },
    FUN: {
      symbol: 'FUN',
      name: 'FunFair Token',
      meta: {
        isEthereum: true,
        isRC20: true,
        address: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
        decimals: 18
      }
    },
    MKR: {
      symbol: 'MKR',
      name: 'Maker Token',
      meta: {
        isEthereum: true,
        isRC20: true,
        address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
        decimals: 18
      }
    },
    OMG: {
      symbol: 'OMG',
      name: 'OmiseGO Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
        decimals: 18
      }
    },
    NEC: {
      symbol: 'NEC',
      name: 'Nectar Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0xcc80c051057b774cd75067dc48f8987c4eb97a5e',
        decimals: 18
      }
    },
    SAN: {
      symbol: 'SAN',
      name: 'Status Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098',
        decimals: 18
      }
    },
    SNT: {
      symbol: 'SNT',
      name: 'Status Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0x744d70fdbe2ba4cf95131626614a1763df805b9e',
        decimals: 18
      }
    },
    SPK: {
      symbol: 'SPK',
      name: 'SparksPay Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0x42d6622dece394b54999fbd73d108123806f6a18',
        decimals: 18
      }
    },
    REP: {
      symbol: 'REP',
      name: 'Reputation Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0x1985365e9f78359a9b6ad760e32412f4a445e862',
        decimals: 18
      }
    },
    TKN: {
      symbol: 'TKN',
      name: 'Monolith Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '"0xaaaf91d9b90df800df4f55c205fd6989c977e73a',
        decimals: 18
      }
    },
    ZRX: {
      symbol: 'ZRX',
      name: '0x Protocol Token',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0xe41d2489571d322189246dafa5ebde1f4699f498',
        decimals: 18
      }
    },
    WBT: {
      symbol: 'WBT',
      name: 'Wrapped Bitcoin',
      meta: {
        isEthereum: true,
        isERC20: true,
        address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        decimals: 8
      }
    }
  }
};

export default ERC20_TOKENS;
