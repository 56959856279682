import { WALLETS } from '../../../components/WalletsBox/WalletsBox.constants'
import { PORTIS_ERROR } from '../../../const/errors'
import connectPortis from './utils/connectPortis'
import unsubscribe from './utils/unsubscribe'
import monitorNewBlock from './utils/monitorNewBlock'
import { getTokensBalances, web3Wrapper } from '../../web3'
import conf from '../../../env'
import { ERC20Abi } from '../../../const'
import initHelper from '../initHelper'

const helperPortis = () => {
  const source = WALLETS.PORTIS
  let ERC20Contract
  let networkId
  let portis
  let subNewBlock
  let web3

  return {
    getWeb3: () => web3,
    init: async () => {
      const { portisAppId, network } = conf

      let accounts = []

      try {
        [web3, portis] = connectPortis(portisAppId, network.name)

        accounts = await web3.eth.getAccounts()

        networkId = await web3.eth.net.getId()

        if (!accounts.length) {
          throw Error(PORTIS_ERROR.IS_LOCKED)
        }

        accounts = accounts.reduce((acc, account) => [...acc, { address: account, path: null }], [])

        ERC20Contract = new web3.eth.Contract(ERC20Abi)
      } catch (err) {
        throw err
      }

      return [accounts, networkId]
    },

    unsubscribe: async () => {
      unsubscribe(subNewBlock)
    },

    subscribe: async (onAccounts, onNewBlock) => {
      try {
        if (subNewBlock) {
          await subNewBlock.unsubscribe()
        }
      } catch (err) {
        return err
      }

      subNewBlock = monitorNewBlock(web3, onNewBlock)

      return { unsubscribe: () => unsubscribe(subNewBlock) }
    },

    getAccounts: async () => web3.eth.getAccounts(),

    getBalances: async (account, fromToken, toToken) =>
      getTokensBalances(account, fromToken, toToken, web3, ERC20Contract),

    getSource: () => ({ source, portis }),

    close: async () => {
      web3Wrapper.reset()
      unsubscribe(subNewBlock)
    },
  }
}

export default () => Object.assign({}, initHelper(), helperPortis())
