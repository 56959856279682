import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'grommet';
import styled from 'styled-components';

const StyledText = styled(Text)`
  vertical-align: middle;
`;

const SimpleText = ({ children, ...props }) => (
  <StyledText {...props}>{children}</StyledText>
);

SimpleText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

SimpleText.defaultProps = {};

export default React.memo(SimpleText);
