import styled from 'styled-components'

const FlexDiv = styled.div`
  ${props => `
  margin-right: 5px;
  margin-left: 5px;
  ${(props.header
    && `
      padding-top: 2px; 
      padding-bottom: 5px; 
      font-size: 10px;
    `)
    || ''}
  ${(props.row
    && `
      font-size: 14px; 
      padding-bottom: 5px;
    `)
    || ''}
  ${(props.flex
    && `
      display: flex; 
    `)
    || (props.address
      && `
      ${props.select && 'cursor: pointer'}
      flex: 1; 
      flex-grow: 2;
      text-overflow: ellipsis; 
      white-space: nowrap; 
      overflow: hidden;
      @media (max-width: 400px) {
        display: block; 
        min-width: 85%;
      }
    `)
    || (props.symbol
      && `
          flex: 1; 
          max-width: 50px;
          @media (max-width: 400px) {
            flex: 1;
            max-width: 30px;
          }
        `)
    || (props.amount
      && `
          ${props.skeleton
            && `
            border-color: rgba(206, 217, 224, 0.2) !important;
            border-radius: 2px;
            margin-top: 2px;
            margin-bottom: 2px;
            box-shadow: none !important;
            background: rgba(206, 217, 224, 0.2);
            background-clip: padding-box !important;
            cursor: default;
            color: transparent !important;
            -webkit-animation: 1000ms linear infinite alternate skeleton-glow;
            animation: 1000ms linear infinite alternate skeleton-glow;
            pointer-events: none;
            width: 100%;
            height: 15px;
          `}
          ${props.selectable
            && `
            cursor:pointer;
            span:hover {
              cursor:pointer;
              text-decoration: underline;
            }
          `}
          flex: 1; text-align: right;
          @media (max-width: 400px) {
            flex: 1;
            text-align: right;  
          }
        `)
    || (props.lock
      && `
          flex: 1; 
          max-width: 100px; 
          text-align: left; 
          padding-left: 5px; 
          margin-top: -5px;
        `)
    || (props.browse
      && `
          flex: 1; 
          text-align: right; 
          padding-left: 5px; 
          margin-top: -5px;
          max-width: 20px;
        `)
    || (props.balance
      && `
          flex: 1; 
          width: 25px; 
          text-align: right; 
          padding-left: 5px; 
        `)
    || (props.col
      && `
          width: 50%;
          @media (max-width: 400px) {
            display: block; 
            width: 50%;
          }
        `)}
`}
`

export default FlexDiv
