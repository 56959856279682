var _defineProperty = require("/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var u = require('updeep');

var _require = require('../../const'),
    defaultOrderProps = _require.defaultOrderProps,
    interactionType = _require.interactionType,
    defaultOrderStyleProps = _require.defaultOrderStyleProps,
    lineType = _require.lineType;

var makeInteractionMsg = require('../misc/makeInteractionMsg');

module.exports = function (tvUtil, db, onInteraction$, order) {
  var tvChart = tvUtil.tvChart;
  var data = order.data,
      style = order.style;

  var fullOrderData = _objectSpread({}, defaultOrderProps, {}, data);

  var fullOrderStyle = _objectSpread({}, defaultOrderStyleProps, {}, style);

  var id = fullOrderData.id,
      price = fullOrderData.price,
      quantity = fullOrderData.quantity,
      tooltip = fullOrderData.tooltip,
      modifyTooltip = fullOrderData.modifyTooltip,
      cancelTooltip = fullOrderData.cancelTooltip,
      editable = fullOrderData.editable,
      text = fullOrderData.text;
  var extendLeft = fullOrderStyle.extendLeft,
      lineLength = fullOrderStyle.lineLength,
      lineStyle = fullOrderStyle.lineStyle,
      lineWidth = fullOrderStyle.lineWidth,
      bodyFont = fullOrderStyle.bodyFont,
      quantityFont = fullOrderStyle.quantityFont,
      lineColor = fullOrderStyle.lineColor,
      bodyBorderColor = fullOrderStyle.bodyBorderColor,
      bodyBackgroundColor = fullOrderStyle.bodyBackgroundColor,
      bodyTextColor = fullOrderStyle.bodyTextColor,
      quantityBorderColor = fullOrderStyle.quantityBorderColor,
      quantityBackgroundColor = fullOrderStyle.quantityBackgroundColor,
      quantityTextColor = fullOrderStyle.quantityTextColor,
      cancelButtonBorderColor = fullOrderStyle.cancelButtonBorderColor,
      cancelButtonBackgroundColor = fullOrderStyle.cancelButtonBackgroundColor,
      cancelButtonIconColorString = fullOrderStyle.cancelButtonIconColorString;
  var ON_ORDER_ADD = interactionType.ON_ORDER_ADD,
      ON_ORDER_CANCEL = interactionType.ON_ORDER_CANCEL,
      ON_ORDER_MODIFY = interactionType.ON_ORDER_MODIFY,
      ON_ORDER_MOVE = interactionType.ON_ORDER_MOVE;
  var ORDER_LINE = lineType.ORDER_LINE;

  try {
    var orderLine = tvChart.createOrderLine(); // General properties methods

    orderLine.setPrice(price).setQuantity(quantity).setText(text).setCancelTooltip(cancelTooltip).setEditable(editable).setModifyTooltip(modifyTooltip).setTooltip(tooltip).onMove(function onMove() {
      var message = _objectSpread({}, makeInteractionMsg(ON_ORDER_MOVE, db.get(id, ORDER_LINE)), {
        update: {
          price: this.getPrice()
        }
      });

      onInteraction$.next(message);
    }).onModify(function onModify() {
      onInteraction$.next(makeInteractionMsg(ON_ORDER_MODIFY, db.get(id, ORDER_LINE)));
    }).onCancel(function onCancel() {
      onInteraction$.next(makeInteractionMsg(ON_ORDER_CANCEL, db.get(id, ORDER_LINE)));
    }); // Style methods

    orderLine.setExtendLeft(extendLeft).setLineLength(lineLength).setLineStyle(lineStyle).setLineWidth(lineWidth).setBodyFont(bodyFont).setQuantityFont(quantityFont).setLineColor(lineColor).setBodyBorderColor(bodyBorderColor).setBodyBackgroundColor(bodyBackgroundColor).setBodyTextColor(bodyTextColor).setQuantityBorderColor(quantityBorderColor).setQuantityBackgroundColor(quantityBackgroundColor).setQuantityTextColor(quantityTextColor).setCancelButtonBorderColor(cancelButtonBorderColor).setCancelButtonBackgroundColor(cancelButtonBackgroundColor).setCancelButtonIconColor(cancelButtonIconColorString);
    db.add(u(data, {}), style, orderLine, ORDER_LINE);
    onInteraction$.next(makeInteractionMsg(ON_ORDER_ADD, db.get(id, ORDER_LINE)));
    return orderLine;
  } catch (err) {
    return {
      error: err.message
    };
  }
};