import { SWAP_STATUS } from '../../const'

const initTransaction = swap => ({
  swap,
  alert: { msg: null, type: null },
  status: [SWAP_STATUS.CONFIRMATION_PENDING],
  error: { message: null },
  values: {},
})

export default initTransaction
