import { orderHashUtils } from '@0x/order-utils'
import {
  monitorTransaction,
  cancelOrders,
  dexWrapper,
  lockTokens,
  unlockTokens,
  revertSwap,
  initTransaction,
  makeSignature,
} from '../../utils/dex'
import { web3Wrapper } from '../../utils/web3'

const dexUtils = (updateAuthSignatures) => {
  let dex
  let web3
  let signatures = {}

  return {
    initDex: async () => {
      if (dex) {
        dexWrapper.reset()
      }
      try {
        web3 = web3Wrapper.getInstance()
        dex = await dexWrapper.init(web3)
        return dex
      } catch (err) {
        throw err
      }
    },

    approveToken: token => dex.contract.approve(token),

    async cancelOrders(orders) {
      const account = dex.get('account')
      const { nonce, signature } = await this.makeSignature(account)

      return cancelOrders(dex, orders, nonce, signature)
    },

    createOrder: async (tradePair, amount, swapPrice, orderExpire) => {
      dexWrapper.reset()
      dex = await dexWrapper.init(web3)
      return dex.contract.createOrderV2(tradePair, amount, swapPrice, orderExpire)
    },

    getConfig: () => dex.config['0x'],

    getOrderHashHex: order => orderHashUtils.getOrderHashHex(order),

    async makeSignature(account) {
      const authSignature = await makeSignature(dex.sign, account, signatures)
      const { nonce, signature } = authSignature

      this.setSignatures({
        [account]: {
          nonce,
          signature,
        },
      })

      updateAuthSignatures(account, nonce, signature)

      return { nonce, signature }
    },

    isTokenApproved: token => dex.contract.isApproved(token),

    initTransaction: swap => initTransaction(swap),

    lockTokens: async (token, fromTokenAmount, forTime, isBaseUnits) => lockTokens(dex, token, fromTokenAmount, forTime, isBaseUnits),

    async monitorTransaction(orderHash, orderId, cb) {
      const account = dex.get('account')

      const { nonce, signature } = await this.makeSignature(account)

      monitorTransaction(web3, dex, orderHash, orderId, cb, nonce, signature)
    },

    revertSwap: async steps => revertSwap(dex, steps),

    getSignatures: () => signatures,

    setSignatures: (sig) => {
      signatures = { ...signatures, ...sig }
      return signatures
    },

    submitOrder: async (
      tradePair,
      amount,
      price,
      cid = 1,
      gid = new Date().getTime(),
      signedOrder,
      orderExpire,
    ) => dex.submitOrder(tradePair, amount, price, cid, gid, signedOrder, orderExpire),

    async unlockTokens(token, amount, isBaseUnits) {
      const account = dex.get('account')

      const { nonce, signature } = await this.makeSignature(account)

      await unlockTokens(dex, token, amount, nonce, signature, isBaseUnits)
    },
  }
}

export default dexUtils
