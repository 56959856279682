const onNewBlock = cb => ((err, newBlockHeaders) => {
  const { number } = newBlockHeaders
  const payload = {
    endpoint: {
      blockNumber: number || null,
      error: err || null,
    },
  }

  cb(payload)
})

export default onNewBlock
