import PropTypes from 'prop-types'
import React from 'react'
import { PanelStack } from '@blueprintjs/core'
import { ThemeProvider } from 'styled-components'
import WalletsBoxPanel from './WalletsBox.panels'
import { withTheme } from '../../utils/misc'
import { defTheme, WITH_THEME } from './WalletsBox.constants'
import WalletsContext from './WalletsBox.context'

const { CONTAINER, PANEL_TITLE, PANEL } = WITH_THEME

const WalletsWrapper = withTheme.styled.div`
  ${props => props.theme[CONTAINER]}
`

const StyledTitle = withTheme.styled.div`
  text-transform: uppercase;
  ${props => props.theme[PANEL_TITLE]}
`

const StyledPanelStack = withTheme(PanelStack, PANEL)`
  & .bp3-panel-stack-view {
    overflow-y: hidden;
  }
`

const onClick = props => openPanel => (wallet) => {
  openPanel({
    component: WalletsBoxPanel,
    props,
    title: <StyledTitle>{wallet}</StyledTitle>,
  })
  props.onSelectWallet({ source: wallet })
}

const WalletsBox = ({
  network,
  isReverseSwap,
  onGetAccounts,
  onResetWallet,
  onSelectAccount,
  onSelectFullBalance,
  onSelectWallet,
  onUnlockTokens,
  selectedWallet,
  style,
}) => {
  const theme = { ...defTheme, ...style }

  const initialPanel = {
    component: WalletsBoxPanel,
    props: {
      network,
      onClick: onClick({
        network,
        onSelectWallet,
      }),
    },
    title: <StyledTitle>SUPPORTED WALLETS</StyledTitle>,
  }

  return (
    <ThemeProvider theme={theme}>
      <WalletsContext.Provider
        value={{
          isReverseSwap,
          onGetAccounts,
          onSelectAccount,
          onSelectFullBalance,
          onUnlockTokens,
          selectedWallet,
        }}
      >
        <WalletsWrapper theme={theme}>
          <StyledPanelStack initialPanel={initialPanel} onClose={onResetWallet} />
        </WalletsWrapper>
      </WalletsContext.Provider>
    </ThemeProvider>
  )
}

WalletsBox.propTypes = {
  isReverseSwap: PropTypes.bool.isRequired,
  network: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  onGetAccounts: PropTypes.func.isRequired,
  onResetWallet: PropTypes.func.isRequired,
  onSelectAccount: PropTypes.func.isRequired,
  onUnlockTokens: PropTypes.func.isRequired,
  onSelectWallet: PropTypes.func.isRequired,
  onSelectFullBalance: PropTypes.func.isRequired,
  selectedWallet: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.objectOf(PropTypes.array),
}

WalletsBox.defaultProps = {
  style: {},
}

export default React.memo(WalletsBox)
