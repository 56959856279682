const cancelOrders = async (efx, orders = null, nonce, signature) => {
  let o

  try {
    o = orders || (await efx.getOrders(null, null, nonce, signature))
  } catch (err) {
    return err
  }

  const cancelPrms = o.map(order => efx.cancelOrder(order.id || order))

  return Promise.all(cancelPrms)
    .then(results => results)
    .catch((err) => {
      throw err
    })
}

export default cancelOrders
