const formatError = (err, defError = 'Unknown error') => {
  if (Array.isArray(err.errors) && err.errors.length && err.errors[0].msg) {
    return err.errors[0].msg
  }

  let error = defError

  if (err instanceof Error) {
    error = err.message
  }

  if (err.error) {
    error = err.error.reason || err.error.message || defError
  }

  return error.split(/\r?\n/, 1)[0]
}

export default formatError
