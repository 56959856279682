import moment from 'moment';
import { debugError, makeTimeChunks } from '.';
import timePeriods from './timePeriods';
import { fetchCandles$ } from '../exchanges/observables';

var makeChunkCalls = function makeChunkCalls(pair, interval, start, end, opts) {
  var options = opts.options,
      makeCandlesUrlFn = opts.makeCandlesUrlFn,
      requestOptions = opts.requestOptions;

  if (!options.makeChunkCalls) {
    return [fetchCandles$(makeCandlesUrlFn(pair, interval, start, end), requestOptions)];
  }

  var apiLimit = options.apiLimit || 1000;
  var timePeriod = timePeriods[interval.slice(-1)];
  var unixInterval = moment.duration(Number(interval.slice(0, interval.length - 1)), timePeriod).asMilliseconds();
  var chunksSize = Math.ceil(apiLimit * unixInterval);
  var timeIntervalChunks = makeTimeChunks(start, end, chunksSize);
  return timeIntervalChunks.map(function (chunk) {
    try {
      return makeCandlesUrlFn(pair, interval, chunk.fromTime, chunk.toTime);
    } catch (e) {
      return debugError(e.message, options.debug);
    }
  }).map(function (url) {
    return fetchCandles$(url, requestOptions);
  });
};

export default makeChunkCalls;