export const DEX_API_CONF_UPDATE = 'DEX_API_CONF_UPDATE'
export const DEX_TRANSACTION_ADD = 'DEX_TRANSACTION_ADD'
export const DEX_TRANSACTIONS_RESTORE = 'DEX_TRANSACTIONS_RESTORE'
export const DEX_TRANSACTION_UPDATE = 'DEX_TRANSACTION_UPDATE'
export const DEX_STATUS_TRANSACTION_SET = 'DEX_STATUS_TRANSACTION_SET'
export const DEX_VALUES_TRANSACTION_SET = 'DEX_VALUES_TRANSACTION_SET'
export const DEX_ALERT_TRANSACTION_SET = ' DEX_ALERT_TRANSACTION_SET'
export const DEX_TRANSACTION_DELETE = 'DEX_TRANSACTION_DELETE'
export const DEX_AUTH_SIGNATURES_UPDATE = 'DEX_AUTH_SIGNATURES_UPDATE'
export const DEX_AUTH_SIGNATURES_RESTORE = 'DEX_AUTH_SIGNATURES_RESTORE'
