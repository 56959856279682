import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Button,
  Spinner,
  Intent,
  AnchorButton,
  Position,
  Tooltip,
} from '@blueprintjs/core'
import styled from 'styled-components'
import { withTheme } from '../../utils/misc'
import { WITH_THEME } from './WalletsBox.constants'
import FlexDiv from './WalletsBox.flexDiv'

const { ACCOUNTS_LIST_CONTAINER, ACCOUNTS_LIST_SELECT } = WITH_THEME

const WrapperList = withTheme.styled.div`
  ${props => props.theme[ACCOUNTS_LIST_CONTAINER]}
`

const StyledButton = styled(Button)``

const StyledNav = withTheme.styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledSelectPath = styled.select`
  ${props => props.theme[ACCOUNTS_LIST_SELECT]}
`
const WrapperSpinner = withTheme.styled.div`
  padding-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-right: auto;
  width: 100%;
`

const WrapperButton = withTheme.styled.div`
  && .bp3-button {
    :hover {
      background: none;
    }
  }
`

const WrapperAccountsList = withTheme.styled.div`
  margin-top: 5px;
  height: 115px;
`

const AccountsList = ({ accounts, onGetAccounts, onSelectAccount }) => {
  const [page, setPage] = useState(1)
  const [path, setPath] = useState('legacy')
  const [isLoading, setIsLoading] = useState(true)
  const [ui, setUi] = useState({ isListOpen: false })

  useEffect(() => {
    setIsLoading(false)
  }, [accounts])

  const setLoadingAnd = fn => (args) => {
    setIsLoading(true)
    fn(args)
  }

  const onSelectPath = (selectedPath) => {
    setPath(selectedPath)
    onGetAccounts(selectedPath, 0, 5)
  }

  const onPrev = () => {
    if (page === 5) {
      return
    }
    onGetAccounts(path, page * 5, 5)
    setPage(i => i - 1)
  }

  const onNext = () => {
    onGetAccounts(path, page * 5, 5)
    setPage(i => i + 1)
  }

  const renderAccounts = useCallback(
    (onSelect, addressPath) =>
      accounts.map(account => (
        <FlexDiv flex row key={`account-address-${account.address}`}>
          <FlexDiv
            address
            select
            onClick={() => {
              onSelect(account.address, addressPath)
              setUi({ ...ui, isListOpen: !ui.isListOpen })
            }}
          >
            {account.address}
          </FlexDiv>
          <FlexDiv balance>{`${Number(account.balance).toFixed(5)} ETH`}</FlexDiv>
          <FlexDiv browse>
            <Tooltip content='Browse on etherscan.io' position={Position.BOTTOM}>
              <WrapperButton>
                <AnchorButton
                  href={`https://etherscan.io/address/${account.address}`}
                  rightIcon='share'
                  minimal
                  target='_blank'
                  small
                />
              </WrapperButton>
            </Tooltip>
          </FlexDiv>
        </FlexDiv>
      )),
    [accounts, ui],
  )

  return (
    <WrapperList>
      {!ui.isListOpen ? (
        <StyledButton minimal onClick={() => setUi({ ...ui, isListOpen: !ui.isListOpen })}>
          <div>More</div>
        </StyledButton>
      ) : (
        <StyledSelectPath
          name='path'
          onChange={event => setLoadingAnd(onSelectPath)(event.target.value)}
        >
          <option value='legacy'>Legacy</option>
          <option value='live'>Live</option>
        </StyledSelectPath>
      )}

      <Collapse isOpen={ui.isListOpen}>
        <WrapperAccountsList>
          {isLoading ? (
            <div>
              <WrapperSpinner>
                <Spinner intent={Intent.PRIMARY} />
              </WrapperSpinner>
            </div>
          ) : (
            <div>{renderAccounts(onSelectAccount, path)}</div>
          )}
        </WrapperAccountsList>
        <StyledNav>
          <StyledButton minimal disabled={page === 1} onClick={setLoadingAnd(onPrev)}>
              Prev
          </StyledButton>
          <StyledButton minimal onClick={setLoadingAnd(onNext)}>
              Next
          </StyledButton>
        </StyledNav>
      </Collapse>
    </WrapperList>
  )
}

AccountsList.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  onGetAccounts: PropTypes.func.isRequired,
  onSelectAccount: PropTypes.func.isRequired,
}

AccountsList.defaultProps = {
  accounts: [],
}

export default React.memo(AccountsList)
