import { makeCandlesRestApiUrl } from '../../../utils';
import { INTERVALS_CONVERSION } from '../const';

var makeCandlesUrl = function makeCandlesUrl(status, REST_ROOT_URL) {
  return function (symbols, interval) {
    var tickInterval = INTERVALS_CONVERSION[interval];
    return makeCandlesRestApiUrl(status.exchange.name, REST_ROOT_URL, {
      marketName: "".concat(symbols[1], "-").concat(symbols[0]),
      tickInterval: tickInterval
    });
  };
};

export default makeCandlesUrl;