import { calcQuoteCcyAmount, calcBaseCcyAmount } from '.'

const calculateAmounts = (amount, price, reverse, select) => {
  const quoteCcyAmount = calcQuoteCcyAmount(amount, price).toFixed(5)
  const baseCcyAmount = calcBaseCcyAmount(amount, price).toFixed(5)
  const swapAmounts = {
    from: {
      fromTokenAmount: amount,
      toTokenAmount: (reverse && baseCcyAmount) || quoteCcyAmount,
    },
    to: {
      fromTokenAmount: (reverse && quoteCcyAmount) || baseCcyAmount,
      toTokenAmount: amount,
    },
  }
  const { fromTokenAmount, toTokenAmount } = swapAmounts[select]

  return [fromTokenAmount, toTokenAmount]
}

export default calculateAmounts
