/* eslint-disable guard-for-in */

/* eslint-disable no-restricted-syntax */
var U19 = 'U19';
var USD = 'USD';
var XBT = 'XBT';

var makeSubs = function makeSubs(pairs) {
  var subscriptions = [];

  for (var channel in pairs) {
    var _pairs$channel = pairs[channel],
        interval = _pairs$channel.interval,
        symbols = _pairs$channel.symbols;
    var quoteSymbol = symbols[0] === XBT ? USD : U19;
    subscriptions.push({
      op: 'subscribe',
      args: "tradeBin".concat(interval, ":").concat(symbols[0]).concat(quoteSymbol)
    });
  }

  return subscriptions;
};

export default makeSubs;