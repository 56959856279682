import 'chartist/dist/chartist.min.css'
import ChartistGraph from 'react-chartist'
import PropTypes from 'prop-types'
import React, { useRef, useEffect, useState } from 'react'
import moment from 'moment'
import styled, { withTheme } from 'styled-components'
import { formatChartYValue } from 'utils/format'

const adjustStokeWidth = ui => (ui.windowSize.outerWidth > ui.breakpoint.tablet
  ? 30
  : 10)

const StyledChart = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .ct-series-a .ct-bar {
    stroke-opacity: 0.9;
    stroke: #053e80;
    stroke-width: ${props => adjustStokeWidth(props.theme.ui)};
  }

  .ct-label {
  }
`
const getWeek = (monthOffset, weekOffset) =>
  parseInt(
    moment()
      .startOf('month')
      .add(monthOffset, 'month')
      .add(weekOffset, 'week')

      .format('x'),
    10,
  )

const getTicks = () => {
  const oneMonthAgo = moment()
    .startOf('month')
    .subtract(1, 'month')
    .format('x')

  const ticks = [
    getWeek(-1, 0),
    getWeek(-1, 1),
    getWeek(-1, 2),
    getWeek(-1, 3),
    getWeek(0, 0),
    getWeek(0, 1),
    getWeek(0, 2),
    getWeek(0, 3),
  ]

  return ticks.filter(tick => tick >= oneMonthAgo)
}

const ChartBar = ({ data }) => {
  const chartRef = useRef(null)
  const ticks = getTicks()
  const [chartSize, setChartSize] = useState([0, 0, true])

  const [width, height, isLoading] = chartSize

  const options = {
    width,
    height,
    high: Math.max(...data.series[0]),
    low: 0,
    axisX: {
      showGrid: false,
      ticks: getTicks(),
      labelInterpolationFnc: (value) => {
        const epochValue = moment(value).format('x')
        if (ticks.includes(Number(epochValue))) {
          return moment(value).format('DD/MM/YY')
        }
        return null
      },
    },
    axisY: {
      showGrid: false,
      labelInterpolationFnc: value => formatChartYValue(value),
    },
  }

  const type = 'Bar'
  useEffect(() => {
    if (!chartRef.current) return

    setChartSize([
      chartRef.current.getBoundingClientRect().width,
      chartRef.current.getBoundingClientRect().height,
      false,
    ])
  }, [])

  return (
    <StyledChart ref={chartRef}>
      {!isLoading && <ChartistGraph data={data} options={options} type={type} />}
    </StyledChart>
  )
}

ChartBar.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired,
}

ChartBar.defaultProps = {}

export default withTheme(React.memo(ChartBar))
