import PropTypes from 'prop-types'
import React from 'react'
import TransactionRow from './TransactionBox.listRow'


const TransactionsBoxList = ({ transactions, onCancel }) => transactions.map(transaction => (
  <TransactionRow
    key={`transaction-row-${transaction.swap.hash}`}
    transaction={transaction}
    onCancel={onCancel}
  />
))

TransactionsBoxList.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.objectOf(PropTypes.array),
  onCancel: PropTypes.func.isRequired,
}

TransactionsBoxList.defaultProps = {
  style: {},
}

export default React.memo(TransactionsBoxList)
