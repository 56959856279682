import { SWAP_STATUS } from '../../../../const'

const { LOCK_PENDING, LOCK_DONE } = SWAP_STATUS

const lockToken = async ({
  dexHelper, tokenSymbol, fromTokenAmount, lockExpireTime, onStatus,
}) => {
  try {
    onStatus(LOCK_PENDING)

    if (tokenSymbol !== 'ETH') {
      if ((await dexHelper.isTokenApproved(tokenSymbol)) === '0') {
        await dexHelper.approveToken(tokenSymbol)
      }
    }

    await dexHelper.lockTokens(tokenSymbol, fromTokenAmount, lockExpireTime)

    onStatus(LOCK_DONE)
  } catch (err) {
    throw err
  }
}

export default lockToken
