var _defineProperty = require("/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var md5 = require('md5');

module.exports = {
  db: new Map(),
  add: function add(data, style, tvLine, type) {
    var id = data.id;
    this.db.set(md5(id + type), {
      data: data,
      style: style,
      tvLine: tvLine
    });
    return true;
  },
  del: function del(id, type) {
    return this.db.delete(md5(id + type));
  },
  get: function get(id, type) {
    var order = this.db.get(md5(id + type));
    return order ? _objectSpread({}, order) : order;
  }
};