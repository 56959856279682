import BigNumber from 'bignumber.js';

const formatTokenAmount = (amount, decimals = 5) => {
  const tokenAmount = new BigNumber(amount);

  if (tokenAmount.gt(100)) {
    return tokenAmount.toFormat(2);
  }
  return tokenAmount.toFormat(4);
};

export default formatTokenAmount;
