import { METAMASK_ERROR } from '../../../../const'

const monitorAccounts = async (
  web3, cbFn,
) => {
  const emitter = web3.givenProvider.publicConfigStore.on('update', async (data) => {
    const { selectedAddress } = data

    let accounts

    try {
      accounts = await web3.eth.getAccounts()
    } catch (err) {
      cbFn(err, null)
      return err
    }

    if (!accounts.length) {
      const statusMsg = Error(METAMASK_ERROR.IS_LOCKED)

      cbFn(statusMsg, accounts)

      return statusMsg
    }

    cbFn(null, accounts)

    return selectedAddress
  })

  return emitter
}

export default monitorAccounts
