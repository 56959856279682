import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const WrapperHeader = styled.div`
  font-weight: 700;
  text-align: center;
  & span {
    font-size: 12px;
  }
`

const WrapperActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 0;
  right: 0;
`
const WrapperTitle = styled.div`
  color: inherit;
  height: 20px;
`

const SectionContainerHeader = ({ titleText, actions, showTitle }) => (
  <WrapperHeader>
    <WrapperTitle>{showTitle && titleText}</WrapperTitle>
    <WrapperActions>{actions()}</WrapperActions>
  </WrapperHeader>
)

SectionContainerHeader.propTypes = {
  showTitle: PropTypes.bool,
  titleText: PropTypes.string.isRequired,
  actions: PropTypes.func.isRequired,
}

SectionContainerHeader.defaultProps = {
  showTitle: true,
}

export default React.memo(SectionContainerHeader)
