import { css } from 'styled-components'

export const WITH_THEME = {
  CONTAINER: 'Container',
  CANCELLATION_POPOVER: 'Cancellation',
  ACTIONS: 'Actions',
  PROGRESS_BAR: 'ProgressBar',
}

export const defTheme = {
  [WITH_THEME.CONTAINER]: css``,
  [WITH_THEME.CANCELLATION_POPOVER]: css``,
  [WITH_THEME.ACTIONS]: css``,
  [WITH_THEME.PROGRESS_BAR]: css``,
}
