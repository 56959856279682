const monitorNewBlock = (
  web3, cbFn,
) => {
  const subscription = web3.eth.subscribe('newBlockHeaders', (err) => {
    if (err) {
      cbFn(err, null)
    }
  })
    .on('data', (blockHeader) => {
      cbFn(null, blockHeader)
    })
    .on('error', (err) => {
      if (err) {
        cbFn(err, null)
      }
    })

  return subscription
}

export default monitorNewBlock
