import _objectWithoutProperties from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import { EXCHANGE_NAME, ERROR } from '../const';
export var makeQuery = function makeQuery() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var query = Object.keys(params).reduce(function (acc, param) {
    return "".concat(acc).concat(param, "=").concat(params[param], "&");
  }, '');
  return "".concat(query.slice(0, -1));
};

var makeCandlesRestApiUrl = function makeCandlesRestApiUrl(exchangeName, REST_ROOT_URL, params) {
  switch (exchangeName) {
    case EXCHANGE_NAME.BITFINEX:
      {
        var symbol = params.symbol,
            interval = params.interval,
            rest = _objectWithoutProperties(params, ["symbol", "interval"]);

        return "".concat(REST_ROOT_URL, "/candles/trade:").concat(interval, ":t").concat(symbol, "/hist?limit=5000&").concat(makeQuery(rest));
      }

    case EXCHANGE_NAME.BINANCE:
      {
        return "".concat(REST_ROOT_URL, "/klines?limit=1000&").concat(makeQuery(params));
      }

    case EXCHANGE_NAME.BITMEX:
      {
        return "".concat(REST_ROOT_URL, "/trade/bucketed?").concat(makeQuery(params));
      }

    case EXCHANGE_NAME.BITTREX:
      {
        return "".concat(REST_ROOT_URL, "/market/GetTicks?").concat(makeQuery(params));
      }

    case EXCHANGE_NAME.POLONIEX:
      {
        return "".concat(REST_ROOT_URL, "?command=returnChartData&").concat(makeQuery(params));
      }

    case EXCHANGE_NAME.KAIKO:
      {
        var _symbol = params.symbol,
            exchange = params.exchange,
            _rest = _objectWithoutProperties(params, ["symbol", "exchange"]); // https://<eu|us>.market-api.kaiko.io/v1/data/trades.v1/exchanges/cbse/spot/btc-usd/aggregations/ohlcv


        return "".concat(REST_ROOT_URL, "/cbse/spot/").concat(_symbol, "/aggregations/ohlcv?").concat(makeQuery(_rest));
      }

    default:
      throw Error(ERROR.EXCHANGE_NOT_SUPPORTED);
  }
};

export default makeCandlesRestApiUrl; // https://poloniex.com/public?command=returnChartData&currencyPair=BTC_XMR&start=1546300800&end=1546646400&period=14400