import BigNumber from 'bignumber.js'

const createOrder = async ({
  dexHelper,
  swapPrice,
  pair,
  fromTokenAmount,
  // eslint-disable-next-line no-unused-vars
  toTokenAmount,
  reverse,
  orderExpireTime,
}) => {
  try {
    await dexHelper.initDex()

    let amount

    const dexConfig = dexHelper.getConfig()

    const tradePair = pair.join('')

    const price = new BigNumber(swapPrice)

    if (reverse) {
      amount = new BigNumber(fromTokenAmount).div(price)
      if (
        pair[1] === 'USD'
        && dexConfig
        && dexConfig.tokenRegistry
        && dexConfig.tokenRegistry.USD
      ) {
        amount /= 1 + (dexConfig.tokenRegistry.USD.settleSpread || 0)
      }
    } else {
      amount = new BigNumber(-fromTokenAmount)
    }

    const order = await dexHelper.createOrder(
      tradePair,
      amount.toString(),
      price.toString(),
      orderExpireTime,
    )

    const orderHash = dexHelper.getOrderHashHex(order)

    if (!reverse) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`Sell ${pair[0]} - Buy ${pair[1]}`)
      }
    } else if (process.env.NODE_ENV !== 'production') {
      console.log(`Sell ${pair[1]} - Buy ${pair[0]}`)
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log(
        `efx.contract.createOrderV2(${tradePair}, ${amount.toString()}, ${price.toString()}, ${orderExpireTime})`,
      )
    }

    return [amount.toString(), order, orderHash]
  } catch (err) {
    throw err
  }
}

export default createOrder
