import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import { Box } from 'grommet';
import TokenIcon from 'components/atoms/TokenIcon';
import TokenLineChart from 'components/molecules/TokenLineChart';
import TickerPrice from 'components/molecules/TickerPrice';
import { connect } from 'react-redux';
import { formatTokenAmount } from 'utils/format';
import get from 'lodash/get';
import actions from 'store/actions';
import tvcd from 'tvcd/src/tvcd';

const TableWrapper = styled.div`
  a {
    text-decoration: none;
  }
  cursor: pointer;
`;

// const isPositive = number => number && Number(number) >= 0;

const TokensTableRow = ({
  tokenSymbol,
  theme,
  tickersData,
  setSelectedToken
}) => {
  const chartWidth =
    theme.ui.windowSize.outerWidth > theme.ui.breakpoint.tablet
      ? undefined
      : theme.ui.windowSize.outerWidth - 10;

  const [candlesData, setCandlesData] = useState([]);

  useEffect(() => {
    const dataSource = tvcd('bitfinex');
    // console.log(`useEffect ${tokenSymbol}`);
    const channel = `15m:${tokenSymbol}USD`;

    dataSource.data$(channel).subscribe(data => {
      if (data[channel]) {
        setCandlesData(data[channel].candles);
      }
    });
    return () => {
      console.log('umounted');
    };
  }, [tokenSymbol]);

  const onClick = useCallback(() => {
    console.log('click');
    return setSelectedToken(tokenSymbol);
  }, [setSelectedToken, tokenSymbol]);

  return (
    <TableWrapper>
      <Box
        border={{
          color: 'neutral-3',
          size: 'small',
          style: 'solid',
          side: 'left'
        }}
        round="xxsmall"
        onClick={onClick}
      >
        <Box
          background="white"
          pad={{ left: '0px', top: '0px', right: 'xsmall' }}
          direction="row"
          align="center"
          justify="between"
        >
          <Box margin="xxsmall" direction="row" align="center">
            <TokenIcon symbol={tokenSymbol} size={20} asText={tokenSymbol} />
          </Box>
          <Box>
            <TickerPrice
              symbols={[tokenSymbol, 'USD']}
              showSymbol={false}
              curPrice={formatTokenAmount(tickersData.lastPrice || 0)}
            />
          </Box>
        </Box>

        <Box
          background="white"
          pad={{
            top: '0px',
            left: 'xxsmall',
            right: 'xxsmall',
            bottom: 'none'
          }}
        >
          <TokenLineChart data={candlesData} chartWidth={chartWidth} />
        </Box>
      </Box>
    </TableWrapper>
  );
};

TokensTableRow.propTypes = {
  tokenSymbol: PropTypes.string.isRequired,
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  ).isRequired,
  tickersData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number]))
    .isRequired,
  setSelectedToken: PropTypes.func.isRequired
};

TokensTableRow.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  const { tokenSymbol } = ownProps;
  const { tickers } = state.tokensData;

  return {
    ...ownProps,
    tickersData: get(tickers, [tokenSymbol], {})
  };
};

const mapDispatchToProps = dispatch => ({
  setSelectedToken: token => {
    dispatch(actions.selectedToken.base.token.set(token));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(React.memo(TokensTableRow)));
