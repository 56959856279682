import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import SimpleText from 'components/atoms/SimpleText';
import { formatTokenAmount } from 'utils/format';
import styled, { keyframes } from 'styled-components';
import BigNumber from 'bignumber.js';
import { Box } from 'grommet';

const variationColor = {
  positive: '#00873D',
  negative: '#A2423D',
  novariation: '#444444'
};

const StyledPriceVariation = styled(SimpleText)`
  padding-right: 5px;
  color: ${props => {
    if (props.variation === 0) {
      return variationColor.novariation;
    }
    return props.variation > 0
      ? variationColor.positive
      : variationColor.negative;
  }};
`;

const StyledSimpleText = styled(SimpleText)`
  border-bottom-color: ${props => {
    if (props.variation === 0) {
      return variationColor.novariation;
    }
    return props.variation > 0
      ? variationColor.positive
      : variationColor.negative;
  }};
  border-bottom-width: 2px;
  border-bottom-style: solid;
  /* font-weight: 700; */
`;

const calcVariation = (curPrice, prevPrice) => {
  const curPriceBn = new BigNumber(curPrice);

  const prevPriceBn = new BigNumber(prevPrice);

  if (!prevPriceBn.eq(0)) {
    const variation = curPriceBn
      .minus(prevPriceBn)
      .dividedBy(curPriceBn)
      .multipliedBy(100)
      .toFixed(4);
    return variation >= 0 ? `+${variation}` : variation;
  }
  return new BigNumber(0).toFixed(4);
};

const TickerPrice = ({ curPrice, symbols, showSymbol }) => {
  const [priceVariation, setPriceVariation] = useState(0);

  const prevPrice = useRef(curPrice);

  const [baseTokenSymbol, quoteTokenSymbol] = symbols;

  const renderSymbol = showSymbol
    ? `${baseTokenSymbol}/${quoteTokenSymbol}`
    : '';

  const showDollar = symbols[1] === 'USD' ? '$' : '';

  useEffect(() => {
    if (curPrice === 0) return;
    setPriceVariation(calcVariation(curPrice, prevPrice.current));
    prevPrice.current = curPrice;
  }, [curPrice]);

  return (
    <Box>
      <Box direction="row" align="end" animation="fadeIn" key={curPrice}>
        <StyledPriceVariation variation={Number(priceVariation)} size="xsmall">
          {Number(priceVariation) !== 0 ? `${priceVariation}` : `-`}
        </StyledPriceVariation>
        <StyledSimpleText size="small" variation={Number(priceVariation)}>
          {` ${showDollar}${formatTokenAmount(curPrice || 0)} ${renderSymbol}`}
        </StyledSimpleText>
      </Box>
    </Box>
  );
};

TickerPrice.propTypes = {
  showSymbol: PropTypes.bool,
  symbols: PropTypes.arrayOf(PropTypes.string).isRequired,
  curPrice: PropTypes.string.isRequired
};

TickerPrice.defaultProps = {
  showSymbol: true
};

export default React.memo(TickerPrice);
