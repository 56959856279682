import { createActions } from 'redux-actions';

const tokensData = createActions(
  {
    TICKERS: {
      SET: (tokenSymbol, data) => [tokenSymbol, data],
      START: (tokens, relayName, networdId) => ({
        tokens,
        relayName,
        networdId
      }),
      STOP: undefined
    }
  },
  { namespace: '_' }
);

export default { ...tokensData };
