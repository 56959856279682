/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { config } from './const';

let subscrition;

const lastBar = {
  new: {},
  update: {},
  initial: {}
};

const formatDate = epoch => moment(epoch).format('DD/MM/YYYY, HH:mm:ss SSS');

const tvData = (exchangeService, exchangeName, symbols) => ({
  onReady: cb => {
    console.log('===== onReady running');
    const { supported_resolutions } = config[exchangeName.toLowerCase()];
    setTimeout(
      () =>
        cb({
          supported_resolutions
        }),
      0
    );
  },

  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    console.log('==== Search Symbols running');
  },

  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    console.log('====== resolveSymbol running');

    if (!config[exchangeName.toLowerCase()]) {
      onResolveErrorCallback(`Exchange ${exchangeName} not supported.`);
    }

    const { intraday_multipliers, supported_resolutions } = config[
      exchangeName.toLowerCase()
    ];

    const symbolStub = {
      data_status: 'streaming',
      description: '',
      // exchange: exchangeName,
      has_intraday: true,
      intraday_multipliers,
      minmov: 1,
      name: `${symbols[0]}/${symbols[1]}`,
      pricescale: 100000000,
      session: '24x7',
      supported_resolutions,
      ticker: `${symbols[0]}/${symbols[1]}`,
      timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      type: 'crypto',
      volume_precision: 6,
      has_empty_bars: true
    };

    if (symbols[1].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
      symbolStub.pricescale = 100;
    }

    setTimeout(() => {
      onSymbolResolvedCallback(symbolStub);
    }, 0);
  },

  getBars: async (
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest
  ) => {
    console.log('===== getBars running');
    console.log(
      `Requesting bars from ${formatDate(from * 1000)} to ${formatDate(
        to * 1000
      )}`
    );
    const { makeTimeFrame } = config[exchangeName.toLowerCase()];
    const [baseSymbol, quoteSymbol] = symbolInfo.name.split('/');

    // eslint-disable-next-line max-len
    console.log(
      `exchangeService.fetchCandles(${[
        baseSymbol,
        quoteSymbol
      ]}, ${makeTimeFrame(resolution)}, ${from * 1000}, ${to * 1000})`
    );

    const bars = await exchangeService.fetchCandles(
      [baseSymbol, quoteSymbol],
      makeTimeFrame(resolution),
      from * 1000,
      to * 1000,
      1000
    );

    if (!bars.length) {
      return onHistoryCallback(cloneDeep(bars), { noData: true });
    }

    lastBar.initial = { ...bars[bars.length - 1] };
    console.log(`Initial data point: ${formatDate(lastBar.initial.time)}`);

    console.log(
      `Received bars from ${formatDate(bars[0].time)} to ${formatDate(
        bars[bars.length - 1].time
      )}`
    );

    onHistoryCallback(cloneDeep(bars), { noData: false });
  },

  subscribeBars(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) {
    console.log('===== subscribeBars runnning');
    const { makeTimeFrame } = config[exchangeName.toLowerCase()];
    const channel = `${makeTimeFrame(resolution)}:${symbols[0]}${symbols[1]}`;

    if (subscrition) {
      subscrition.unsubscribe();
    }

    const subscribedPairs = exchangeService.getTradingPairs();

    if (!subscribedPairs[channel]) {
      exchangeService.addTradingPair(symbols, {
        timeFrame: makeTimeFrame(resolution)
      });
    }

    subscrition = exchangeService.data$([channel]).subscribe(data => {
      if (data && data[channel] && data[channel].meta.updateIndex === 0) {
        console.log(data[channel].meta.updateIndex);
        const { updateIndex } = data[channel].meta;
        console.log(data);

        if (data[channel].meta.isNewCandle) {
          lastBar.new = { ...data[channel].candles[updateIndex] };

          console.log(
            `### NEW data point: ${new Date(lastBar.new.time).toUTCString()} ${
              data[channel].candles[updateIndex].time
            }`
          );
          console.log('isNewCandle:', data[channel].meta.isNewCandle);
          console.log('updateIndex:', data[channel].meta.updateIndex);
        } else {
          lastBar.update = { ...data[channel].candles[updateIndex] };
          console.log(
            `### UPDATE data point: ${new Date(
              lastBar.update.time
            ).toUTCString()} ${data[channel].candles[updateIndex].time}`
          );
          console.log('isNewCandle:', data[channel].meta.isNewCandle);
          console.log('updateIndex:', data[channel].meta.updateIndex);
        }
        console.log(`
        Initial: ${lastBar.initial.time &&
          new Date(lastBar.initial.time).toUTCString()} ${lastBar.initial.time}
        Update: ${lastBar.update.time &&
          new Date(lastBar.update.time).toUTCString()} ${lastBar.update.time}
        New: ${lastBar.new.time && new Date(lastBar.new.time).toUTCString()} ${
          lastBar.new.time
        }
        `);
        console.log(
          `Sending to TV: ${new Date(
            data[channel].candles[updateIndex].time
          ).toUTCString()} ${data[channel].candles[updateIndex].time}`
        );
        onRealtimeCallback(cloneDeep(data[channel].candles[updateIndex]));
      }

      // if (
      //   data &&
      //   data[channel] &&
      //   (data[channel].meta.isNewCandle === true ||
      //     data[channel].meta.isUpdateCandle === true)
      // ) {
      //   console.log(data);
      //   if (data[channel].meta.isNewCandle) {
      //     lastBar.new = { ...data[channel].updates };
      //     console.log(
      //       `### NEW data point: ${formatDate(lastBar.new.time)} ${
      //         data[channel].updates.time
      //       }`
      //     );
      //     console.log('isNewCandle:', data[channel].meta.isNewCandle);
      //     console.log('isUpdateCandle:', data[channel].meta.isUpdateCandle);
      //   }
      //   if (data[channel].meta.isUpdateCandle) {
      //     lastBar.update = { ...data[channel].updates };
      //     console.log(
      //       `### UPDATE data point: ${formatDate(lastBar.update.time)} ${
      //         data[channel].updates.time
      //       }`
      //     );
      //     console.log('isNewCandle:', data[channel].meta.isNewCandle);
      //     console.log('isUpdateCandle:', data[channel].meta.isUpdateCandle);
      //   }
      //   console.log(`
      //   Initial: ${lastBar.initial.time && formatDate(lastBar.initial.time)}
      //   Update: ${lastBar.update.time && formatDate(lastBar.update.time)}
      //   New: ${lastBar.new.time && formatDate(lastBar.new.time)}
      //   `);
      //   // console.log(data);
      //   onRealtimeCallback(cloneDeep(data[channel].updates));
      // }
    });
  },

  unsubscribeBars: subscriberUID => {
    console.log('===== unsubscribeBars running');
  },

  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    console.log('===== calculateHistoryDepth running');
    console.warn(resolution, resolutionBack, intervalBack);
    // return resolution < 60 ? { resolutionBack: 'D', intervalBack: '1' } : undefined
    //   if (resolution === '1D') {
    //     return {
    //       resolutionBack: 'M',
    //       intervalBack: 6,
    //     }
    // }
  },

  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    console.log('===== getMarks running');
  },

  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {
    console.log('===== getTimeScaleMarks running');
  },

  getServerTime: cb => {
    console.log('===== getServerTime running');
  }
});

export default tvData;
