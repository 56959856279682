import { css } from 'styled-components'

export const WALLET_STATUS = {
  ERROR: 'error',
  LOGGING: 'logging',
  LOGGED_IN: 'logged in',
  LOGGED_OUT: 'logged out',
}

export const WALLETS = {
  METAMASK: 'metamask',
  PORTIS: 'portis',
  LEDGER: 'ledger',
  NONE: null,
}

export const SUPPORTED_WALLETS = [WALLETS.METAMASK, WALLETS.PORTIS, WALLETS.LEDGER]

export const WITH_THEME = {
  ACCOUNTS_LIST_CONTAINER: 'AccountsListContainer',
  ACCOUNTS_LIST_SELECT: 'AccountsListSelect',
  CONTAINER: 'Container',
  PANEL: 'Panel',
  PANEL_WALLETS_BUTTON: 'PanelWalletButton',
  PANEL_ACTION_BUTTON: 'PanelUnlockButton',
  PANEL_TITLE: 'PanelTitle',
}

export const defTheme = {
  [WITH_THEME.ACCOUNTS_LIST_CONTAINER]: css``,
  [WITH_THEME.ACCOUNTS_LIST_SELECT]: css``,
  [WITH_THEME.CONTAINER]: css``,
  [WITH_THEME.PANEL]: css``,
  [WITH_THEME.PANEL_WALLETS_BUTTON]: css``,
  [WITH_THEME.PANEL_ACTION_BUTTON]: css``,
  [WITH_THEME.PANEL_TITLE]: css``,
}
