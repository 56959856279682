import { handleActions } from 'redux-actions'
import {
  addTransaction,
  deleteTransation,
  restoreAuthSignatures,
  restoreTransactions,
  setAlertTransaction,
  setStatusTransaction,
  setValuesTransaction,
  updateApiConfDex,
  updateAuthSignatures,
  updateTransaction,
} from './dexActions'
import conf from '../../env'
import { omit } from '../../utils/misc'

export const initDexState = {
  error: null,
  exchangePairs: [],
  quoteTokens: [...conf.quoteTokens],
  signatures: {},
  tokenRegistry: {},
  transactions: {},
}

const dexReducer = handleActions(
  {
    [updateApiConfDex]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    [restoreTransactions]: (state, action) => ({
      ...state,
      transactions: { ...action.payload },
    }),

    [addTransaction]: (state, action) => ({
      ...state,
      transactions: { ...state.transactions, ...action.payload },
    }),

    [deleteTransation]: (state, action) => {
      const newTransactions = omit(state.transactions, action.payload)
      return {
        ...state,
        transactions: {
          ...newTransactions,
        },
      }
    },

    [updateTransaction]: (state, action) => {
      const [hash] = Object.keys(action.payload)
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [hash]: {
            ...state.transactions[hash],
            ...action.payload[hash],
          },
        },
      }
    },

    [updateAuthSignatures]: (state, action) => {
      const [account] = Object.keys(action.payload)
      return {
        ...state,
        signatures: {
          ...state.signatures,
          [account]: {
            ...action.payload[account],
          },
        },
      }
    },

    [restoreAuthSignatures]: (state, action) => ({
      ...state,
      signatures: { ...action.payload },
    }),

    [setAlertTransaction]: (state, action) => {
      const [hash] = Object.keys(action.payload)
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [hash]: {
            ...state.transactions[hash],
            alert: {
              ...action.payload[hash],
            },
          },
        },
      }
    },

    [setStatusTransaction]: (state, action) => {
      const [hash] = Object.keys(action.payload)
      const status = [...state.transactions[hash].status, action.payload[hash]]
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [hash]: {
            ...state.transactions[hash],
            status,
          },
        },
      }
    },

    [setValuesTransaction]: (state, action) => {
      const [hash] = Object.keys(action.payload)
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [hash]: {
            ...state.transactions[hash],
            values: {
              ...state.transactions[hash].values,
              ...action.payload[hash],
            },
          },
        },
      }
    },
  },
  initDexState,
)

export default dexReducer
