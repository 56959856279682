import { EXCHANGE_NAME, ERROR } from '../const';
export var makeBinanceQuery = function makeBinanceQuery() {
  var pairs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var query = Object.keys(pairs).reduce(function (acc, pair) {
    return "".concat(acc).concat(pairs[pair].ticker.toLowerCase(), "@kline_").concat(pairs[pair].interval, "/");
  }, '');
  return "".concat(query.slice(0, -1));
};

var makeCandlesWsApiUrl = function makeCandlesWsApiUrl(exchangeName, wsRootUrl, pairs) {
  switch (exchangeName) {
    case EXCHANGE_NAME.BINANCE:
      {
        return "".concat(wsRootUrl, "/stream?streams=").concat(makeBinanceQuery(pairs));
      }

    default:
      throw Error(ERROR.EXCHANGE_NOT_SUPPORTED);
  }
};

export default makeCandlesWsApiUrl;