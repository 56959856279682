/* eslint-disable import/prefer-default-export */

export const DEFAULT_TRADING_VIEW_OPTIONS = {
  debug: false,
  container_id: 'tv_chart_container',
  library_path: '/charting_library/',
  locale: 'en',
  disabled_features: [
    'volume_force_overlay',
    'header_widget',
    'left_toolbar',
    'control_bar',
    'timeframes_toolbar',
    'remove_library_container_border',
    'border_around_the_chart'
  ],
  // enabled_features: ['study_templates'],
  charts_storage_url: 'https://saveload.tradingview.com',
  charts_storage_api_version: '1.1',
  client_id: 'tradingview.com',
  user_id: 'public_user_id',
  fullscreen: false,
  autosize: true,
  studies_overrides: {},
  overrides: {
    // "mainSeriesProperties.showCountdown": true,
    // 'paneProperties.background': '#131722',
    // 'paneProperties.vertGridProperties.color': '#363c4e',
    // 'paneProperties.horzGridProperties.color': '#363c4e',
    // 'symbolWatermarkProperties.transparency': 90,
    // 'scalesProperties.textColor': '#AAA',
    // 'mainSeriesProperties.candleStyle.wickUpColor': '#336854',
    // 'mainSeriesProperties.candleStyle.wickDownColor': '#7f323f',
    volumePaneSize: 'tiny'
  }
};

export const TIMEFRAMES = {
  bitfinex: [
    { text: '3y', resolution: '7D', description: '3 years' },
    { text: '1y', resolution: '1D', description: '1 year' },
    { text: '3m', resolution: '720', description: '3 months' },
    { text: '1m', resolution: '360', description: '1 month' },
    { text: '7d', resolution: '60', description: '7 day' },
    { text: '3d', resolution: '30', description: '3 days' },
    { text: '1d', resolution: '15', description: '1 day' },
    { text: '6h', resolution: '5', description: '6 hours' },
    { text: '1h', resolution: '1', description: '1 hours' }
  ]
};

export const CUSTOM_TIMEFRAMES = {
  bitfinex: [
    {
      text: '1y',
      resolution: '1D',
      description: '1 year',
      descriptionResolution: '1 day'
    },
    {
      text: '1m',
      resolution: '60',
      description: '1 month',
      descriptionResolution: '1 hour'
    },
    {
      text: '3d',
      resolution: '15',
      description: '3 days',
      descriptionResolution: '15 minutes'
    },
    {
      text: '1d',
      resolution: '5',
      description: '1 day',
      descriptionResolution: '5 minutes'
    },
    {
      text: '1h',
      resolution: '1',
      description: '1 hours',
      descriptionResolution: '1 minute'
    }
  ]
};

export const tradingPairs = {
  bitfinex: [
    ['BTC', 'USD'],
    ['ETH', 'USD'],
    ['ZRX', 'USD'],
    ['OMG', 'USD']
  ],
  getTradingPairs(exchangeName) {
    return this[exchangeName.toLowerCase()];
  }
};
