import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SimpleText from 'components/atoms/SimpleText';
import SimpleImage from '../SimpleImage';

const StyledSimpleImage = styled.div`
  & img {
    height: ${props => props.size.toString()}px;
    vertical-align: middle;
  }
  color: red;
`;

const FlexDiv = styled.div`
  display: flex;
  div:nth-child(2) {
    margin-left: 5px;
    align-items: center;
  }
`;

const TokenIcon = ({ symbol, size, color, asText }) => (
  <FlexDiv>
    <div>
      <StyledSimpleImage size={size}>
        <SimpleImage
          alt={`token-icon-${symbol}`}
          color={color}
          src={`/img/crypto-icons/color/${symbol.toLowerCase()}@2x.png`}
        />
      </StyledSimpleImage>
    </div>
    <div>
      <div>
        {asText && (
          <SimpleText weight="bold" size="small">
            {asText}
          </SimpleText>
        )}
      </div>
    </div>
  </FlexDiv>
);

TokenIcon.propTypes = {
  asText: PropTypes.string,
  symbol: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.bool
};

TokenIcon.defaultProps = {
  asText: null,
  size: 32,
  color: true
};

export default TokenIcon;
