import { Intent } from '../../const'

export const interactionMsg = {
  approveTransaction: 'Transaction waiting for approval on your Ledger device',
  signMessage: 'Message waiting for signature on your Ledger device',
  signTransaction: 'Transaction waiting for signature on your Ledger device',
}

const onLedgerWalletInteraction = cb => (interactionType, isInteractionPending) => {
  const payload = {
    statusMsg: {
      msg: isInteractionPending ? interactionMsg[interactionType] : null,
      type: isInteractionPending ? Intent.WARNING : null,
    },
  }
  cb(payload)
}

export default onLedgerWalletInteraction
