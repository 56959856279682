import { SWAP_STATUS } from '../../../../const'

const { SETTLEMENT_PENDING } = SWAP_STATUS

const monitorOrder = async ({
  dexHelper, orderHash, orderId, onOrderSettled, onStatus,
}) => {
  try {
    onStatus(SETTLEMENT_PENDING)

    await dexHelper.monitorTransaction(orderHash, orderId, onOrderSettled)
  } catch (err) {
    throw err
  }
}

export default monitorOrder
