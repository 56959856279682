import { combineReducers } from 'redux';
import tokensReducer from './tokens';
import tokensDataReducer from './tokensData';
import selectedTokenReducer from './selectedToken';
import chartReducer from './chart';

const reducers = {
  tokens: tokensReducer,
  selectedToken: selectedTokenReducer,
  tokensData: tokensDataReducer,
  chart: chartReducer
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
