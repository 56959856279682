import 'chartist/dist/chartist.min.css';
import Chartist from 'chartist';
import ChartistGraph from 'react-chartist';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';

const makeStrokeColor = chartData => {
  const { data } = chartData.series[0];
  const seriesLength = data.length;
  const currPrice = data[0];
  const initialPrice = data[seriesLength - 1];

  if (!currPrice || !initialPrice) {
    return true;
  }
  if (currPrice.y >= initialPrice.y) {
    return true;
  }
  return false;
};

const StyledChart = styled.div`
  display: flex;
  .ct-series-a .ct-area {
    fill: ${props => (props.isPositive ? '#00873D' : '#A2423D')};
    fill-opacity: 0.1;
    stroke: none;
  }

  .divider {
    display: flex;
  }

  .ct-series-a .ct-line {
    stroke: ${props => (props.isPositive ? '#00873D' : '#A2423D')};
    stroke-width: 2px;
  }

  .ct-label {
    font-size: 8px;
  }

  #chartPie .ct-label {
    font-size: 12px;
    font-weight: 700;
  }
`;
const getHour = (dayOffset, hourOffset) =>
  parseInt(
    moment()
      .startOf('day')
      .add(dayOffset, 'days')
      .add(hourOffset, 'hours')
      .format('x'),
    10
  );

const getTicks = () => {
  const oneDayAgo = moment()
    .startOf('hour')
    .subtract(24, 'hours')
    .format('x');

  const ticks = [
    getHour(-1, 0), // 00:00
    getHour(-1, 6), // 06:00
    getHour(-1, 12), // 12:00
    getHour(-1, 18), // 18:00
    getHour(0, 0), // 00:00
    getHour(0, 6), // 06:00
    getHour(0, 12), // 12:00
    getHour(0, 18) // 18:00
  ];

  return ticks.filter(tick => tick >= oneDayAgo);
};

const ChartLine = ({ data }) => {
  const chartData = data;
  const type = 'Line';
  const options = {
    chartPadding: {
      top: 0,
      right: 0,
      left: -10,
      bottom: -12
    },
    width: '100%',
    height: 80,
    fullWidth: true,
    axisY: {
      showGrid: false,
      labelOffset: {
        x: 10
      },
      offset: 2,
      showLabel: false
    },
    axisX: {
      type: Chartist.FixedScaleAxis,
      low: parseInt(
        moment()
          .startOf('hour')
          .subtract(24, 'hours')
          .format('x'),
        10
      ),
      high: parseInt(
        moment()
          .startOf('hour')
          .format('x'),
        10
      ),
      ticks: getTicks(),
      labelInterpolationFnc: value => moment(value).format('HH:mm'),
      showGrid: false,
      labelOffset: {
        x: -10,
        y: 0
      }
    },
    showPoint: false,
    showArea: true,
    lineSmooth: false
  };

  const isPositiveMarket = useMemo(() => makeStrokeColor(chartData), [
    chartData
  ]);

  return (
    <StyledChart isPositive={isPositiveMarket}>
      <ChartistGraph data={chartData} options={options} type={type} />
    </StyledChart>
  );
};

ChartLine.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired
};

ChartLine.defaultProps = {};

export default ChartLine;
