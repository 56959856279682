import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import { H6 } from '@blueprintjs/core'
import { withTheme } from '../../utils/misc'
import { defTheme, WITH_THEME } from './TransactionsBox.constants'
import { SWAP_STATUS } from '../../const'
import TransactionsBoxtransactions from './TransactionBox.list'
import SimpleAlert from '../SimpleAlert'

const { CONTAINER } = WITH_THEME

const StyledDiv = withTheme.styled.div`
  ${props => props.theme[CONTAINER]}
`

const WrapperAlert = withTheme.styled.div`
  padding-bottom: 5px;
`

export const renderAlerts = transactions =>
  transactions.map(transaction => (
    <Col xs={12} key={`transaction-alert-${transaction.swap.hash}`}>
      <WrapperAlert>
        <SimpleAlert
          key={transaction.alert.msg + transaction.alert.time}
          text={transaction.alert.msg}
          type={transaction.alert.type}
          visible
          delay={10000}
        />
      </WrapperAlert>
    </Col>
  ))

const TransactionsBox = ({ transactions, style, onCancel }) => {
  const compTheme = { ...defTheme, ...style }

  const sortedtransactions = useMemo(
    () =>
      Object.values(transactions)
        .filter(transaction => transaction.status.includes(SWAP_STATUS.LOCK_PENDING))
        .sort((a, b) => b.swap.timestamp - a.swap.timestamp)
        .slice(0, 5),
    [transactions],
  )

  return (
    <ThemeProvider theme={compTheme}>
      <StyledDiv>
        <Row>
          <Col xs={12}>
            <H6>Last 5 transactions</H6>
          </Col>
        </Row>
        <Row>{renderAlerts(sortedtransactions)}</Row>
        <Row>
          <Col xs={12}>
            <Row>
              {sortedtransactions.length === 0 ? (
                <Col xs={12}>No recent transactions</Col>
              ) : (
                <TransactionsBoxtransactions
                  transactions={sortedtransactions}
                  onCancel={onCancel}
                />
              )}
            </Row>
          </Col>
        </Row>
      </StyledDiv>
    </ThemeProvider>
  )
}

TransactionsBox.propTypes = {
  transactions: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.objectOf(PropTypes.array),
  onCancel: PropTypes.func.isRequired,
}

TransactionsBox.defaultProps = {
  style: {},
}

export default React.memo(TransactionsBox)
