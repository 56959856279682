import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img`
  width: 25px;
`;

const Logo = ({ color, size }) => (
  <StyledLogo src="/img/png/flare-dex-logo.png" alt="flare-dex-logo" />
);

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  color: PropTypes.string
};

Logo.defaultProps = {
  size: 'small',
  color: 'plain'
};

export default Logo;
