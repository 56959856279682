const manageSessionStorage = (() => ({
  getItem: (key) => {
    const sessionData = JSON.parse(sessionStorage.getItem('swapS'))
    return sessionData && sessionData[key]
  },

  setItem: (key, data) => {
    let sessionData = JSON.parse(sessionStorage.getItem('swapS'))
    sessionData = { ...sessionData, [key]: data }
    sessionStorage.setItem('swapS', JSON.stringify(sessionData))
  },
}))()

export default manageSessionStorage
