const getTokensBalances = (account, fromToken, toToken, web3, contract) => {
  const ERC20 = contract
  const arrTokenPrms = [fromToken, toToken].map((token) => {
    if (token.tokenAddress) {
      ERC20.options.address = token.tokenAddress

      return ERC20.methods.balanceOf(account)
        .call({})
    }
    return web3.eth.getBalance(account)
  })

  const arrWrapperPrms = [fromToken, toToken].map((token) => {
    ERC20.options.address = token.wrapperAddress
    return ERC20.methods.balanceOf(account)
      .call({})
  })

  const prms = [...arrTokenPrms, ...arrWrapperPrms]
  return Promise.all(prms)
    .catch((err) => {
      throw err
    })
}

export default getTokensBalances
