import { useEffect, useReducer, useState } from 'react'
import { connectWs, subPair, unsubPair } from '../../utils/ws'
import conf from '../../env'
import { bindFn } from '../../utils/misc'
import tickerReducer, { initTickerState } from './tickerReducer'
import onMessageWs from './onMessageWs'
import onReconnectWs from './onReconnectWs'

const useWebSocket = () => {
  const [data, dispatch] = useReducer(tickerReducer, initTickerState)

  const [ws, setWebSocket] = useState({
    ws: {},
    subPair,
    unsubPair,
  })

  const [baseToken, quoteToken] = conf.pair

  const url = conf.wsUrl

  useEffect(() => {
    const initSubs = [{
      event: 'subscribe',
      channel: 'ticker',
      symbol: `t${baseToken}${quoteToken}`,
    },
    {
      event: 'subscribe',
      channel: 'book',
      symbol: `t${baseToken}${quoteToken}`,
      freq: 'F1',
      prec: 'P0',
    }]

    const wsConn = connectWs(
      url,
      { onMessage: onMessageWs(dispatch), onReconnect: onReconnectWs(setWebSocket), initSubs },
    )

    setWebSocket({
      ws: wsConn,
      subPair: subPair(bindFn(wsConn)),
      unsubPair: unsubPair(bindFn(wsConn)),
    })

    return () => {
      wsConn.close()
    }
  }, [baseToken, quoteToken, url])

  return [data, ws]
}

export default useWebSocket
