import { useEffect, useRef, useCallback } from 'react'
import { getPairData, calcQuoteCcyAmount, calcBaseCcyAmount } from '../../utils/misc'
import { SWAP_STATUS } from '../../const'
import { getPriceLevel } from '../../utils/dex'

const useTicker = (data, dex, wallet) => {
  const {
    swap: { pair },
  } = wallet.state

  const pData = useCallback(() => getPairData(data, pair.join(''), dex.state), [
    data,
    pair,
    dex.state,
  ])

  const tickerHelper = useRef(() => null)

  const settleSpread = (Object.keys(dex.state.tokenRegistry).length
      && dex.state.tokenRegistry.USD
      && dex.state.tokenRegistry.USD.settleSpread)
    || 0

  tickerHelper.current = {
    getPriceLevel: useCallback(
      (amount, reverse, spread = settleSpread) =>
        getPriceLevel(pair.join(''), data, amount, reverse, spread),
      [data, pair, settleSpread],
    ),
  }

  const booksPair = data.books[pair.join('')]

  const prevMcnt = useRef()

  // Uupdates amounts on user input
  useEffect(() => {
    const {
      swap: {
        reverse, fromTokenAmount, status, toTokenAmount, lastUserInput,
      },
    } = wallet.state

    const mcnt = booksPair && booksPair.books.mcnt

    if (
      prevMcnt.current !== mcnt
      && booksPair
      && booksPair.books
      && booksPair.books.asks
      && booksPair.books.bids
      && booksPair.books.asks[0]
      && booksPair.books.bids[0]
    ) {
      const price = tickerHelper.current.getPriceLevel(fromTokenAmount, reverse)

      let newFromTokenAmount

      let newToTokenAmount

      if (status === SWAP_STATUS.NEW && fromTokenAmount && toTokenAmount) {
        newFromTokenAmount = fromTokenAmount

        if (lastUserInput === 'from') {
          if (!reverse) {
            newToTokenAmount = calcQuoteCcyAmount(fromTokenAmount, price).toFixed(5)
          } else {
            newToTokenAmount = calcBaseCcyAmount(fromTokenAmount, price).toFixed(5)
          }
        } else {
          newToTokenAmount = toTokenAmount

          if (!reverse) {
            newFromTokenAmount = calcBaseCcyAmount(toTokenAmount, price).toFixed(5)
          } else {
            newFromTokenAmount = calcQuoteCcyAmount(toTokenAmount, price).toFixed(5)
          }
        }
        prevMcnt.current = mcnt

        wallet.dispatch.setSwapAmountsWallet(newFromTokenAmount, newToTokenAmount, price)
      }
    }
  }, [booksPair, wallet.dispatch, wallet.state])

  const pairData = {
    state: pData(),
    dispatch: () => null,
    helper: tickerHelper.current,
  }

  return [pairData]
}

export default useTicker
