import Web3 from 'web3';
import ProviderEngine from 'web3-provider-engine';
import RpcSubProvider from 'web3-provider-engine/subproviders/rpc';
import SubscriptionSubprovider from 'web3-provider-engine/subproviders/subscriptions';
import conf from '../../env';

const web3Wrapper = (() => {
  let instance;

  return {
    init: (provider, isSubprovider) => {
      if (!instance) {
        if (provider && !isSubprovider) {
          instance = new Web3(provider);
          return instance;
        }

        const engine = new ProviderEngine();
        if (provider) {
          engine.addProvider(provider);
        }
        engine.addProvider(new SubscriptionSubprovider());
        engine.addProvider(new RpcSubProvider({ rpcUrl: conf.rpcUrlWeb3 }));
        engine.start();
        instance = new Web3(engine);
      }
      return instance;
    },
    getInstance: () => instance,
    reset: () => {
      instance = undefined;
    }
  };
})();

export default web3Wrapper;
