import { css } from 'styled-components'

export const WITH_THEME = {
  CONTAINER: 'Container',
  DIALOG: 'Dialog',
  DIALOG_CANCEL_BUTTON: 'DialogCancelButton',
  DIALOG_CONFIRM_BUTTON: 'DialogConfirmButton',
  DIALOG_TITLE: 'DialogTitle',
}

export const defTheme = {
  [WITH_THEME.CONTAINER]: css``,
  [WITH_THEME.DIALOG]: css``,
  [WITH_THEME.DIALOG_CANCEL_BUTTON]: css``,
  [WITH_THEME.DIALOG_CONFIRM_BUTTON]: css``,
  [WITH_THEME.DIALOG_CONTENT]: css``,
  [WITH_THEME.DIALOG_TITLE]: css``,
}
