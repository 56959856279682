/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable global-require */
import { signatureUtils } from '@0x/order-utils'
import conf from '../../env'

if (process.env.JEST !== 'true') {
  var {
    Web3ProviderEngine,
    RPCSubprovider,
    MetamaskSubprovider,
    SignerSubprovider,
  } = require('@0x/subproviders')
}

const signOrder = async (web3, account, order, orderHashHex) => {
  const { providerUrl } = conf

  const providerEngine = new Web3ProviderEngine()

  const { currentProvider } = web3
  // needed for eth_signTypedOrder_v1/v3 discrepancies between providers
  // (Metamask & Portis require the _v3 suffix)
  // 0x throws 'INVALID SIGNATURE' for Trust/Coinbase if SignerSubprovider is used
  const subProvider = currentProvider.isMetaMask
    || currentProvider.isStatus
    || currentProvider.isPortis
    || currentProvider.isToshi
    || currentProvider.isTrust
    ? new MetamaskSubprovider(currentProvider)
    : new SignerSubprovider(currentProvider)

  providerEngine.addProvider(subProvider)

  providerEngine.addProvider(new RPCSubprovider(providerUrl))

  providerEngine.start()

  // HACK: Signing typed data is currently broken in Trust Wallet - forcing hash signing
  let signedOrder

  if (currentProvider.isTrust) {
    signedOrder = {
      ...order,
      signature: await signatureUtils.ecSignHashAsync(providerEngine, orderHashHex, account),
    }
  } else {
    signedOrder = await signatureUtils.ecSignOrderAsync(providerEngine, order, account)
  }

  return signedOrder
}

export default signOrder
