/* eslint-disable no-console */
import {
  SWAP_STATUS, Intent, SWAP_STEPS, orderExpireTime, lockExpireTime,
} from '../../const'
import { formatError, toUnitAmount } from '../format'
import { swapStepUtils } from '../validation'
import runSwapStep from './utils'

const { SUBMISSION_ERROR } = SWAP_STATUS

const onConfirmSwap = async (wallet, dex, pairData) => {
  const revertSteps = []
  const {
    updateTransaction,
    setStatusTransaction,
    setValuesTransaction,
    setAlertTransaction,
  } = dex.dispatch
  const {
    swap: {
      pair,
      fromTokenAmount,
      toTokenAmount,
      fromTokenAmountBaseUnits,
      toTokenAmountBaseUnits,
      reverse,
      price,
      hash,
      fromToken,
      toToken,
    },
    selectedAccount,
  } = wallet.state

  await dex.helper.initDex()
  const dexConfig = await dex.helper.getConfig()
  const fromTokenSymbol = (reverse && pair[1]) || pair[0]
  const toTokenSymbol = (reverse && pair[0]) || pair[1]
  const tradePair = pair.join('')

  const fromTokenAmountSwap = fromTokenAmountBaseUnits
    ? toUnitAmount(fromTokenAmountBaseUnits, fromToken.decimals).toString()
    : fromTokenAmount
  const toTokenAmountSwap = toTokenAmountBaseUnits
    ? toUnitAmount(toTokenAmountBaseUnits, toToken.decimals).toString()
    : toTokenAmount

  if (process.env.NODE_ENV !== 'production') {
    console.log(`Trade pair: ${tradePair}`)
    console.log(`In App spread corrected price: ${price}`)
    console.log(`From swap amount: ${(reverse && toTokenAmountSwap) || -fromTokenAmountSwap}`)
    console.log(`Spread: ${dexConfig.tokenRegistry.USD.settleSpread}`)
  }

  const stepStatus = swapStepUtils(SWAP_STEPS)

  const validateAndUpdateStatus = (status) => {
    setStatusTransaction(hash, stepStatus.validate(status))
  }

  try {
    // Locks tokens
    await runSwapStep('lockToken', {
      dexHelper: dex.helper,
      tokenSymbol: fromTokenSymbol,
      fromTokenAmount: fromTokenAmountSwap,
      lockExpireTime,
      onStatus: validateAndUpdateStatus,
    })

    const currentSwapPriceWithNoSpread = Number(
      pairData.helper.getPriceLevel(fromTokenAmount, reverse, 0),
    )

    // Creates order object
    const [swapAmount, order, orderHash] = await runSwapStep('createOrder', {
      dexHelper: dex.helper,
      swapPrice: currentSwapPriceWithNoSpread,
      pair,
      fromTokenAmount: fromTokenAmountSwap,
      toTokenAmount: toTokenAmountSwap,
      reverse,
      orderExpireTime,
      onStatus: validateAndUpdateStatus,
    })

    // Updates transaction values
    setValuesTransaction(hash, {
      orderHash,
      tradePair,
      swapAmount,
      swapPrice: currentSwapPriceWithNoSpread,
      orderExpireTime,
      order,
    })

    // Signs order object
    const signedOrder = await runSwapStep('signOrder', {
      walletHelper: wallet.helper,
      accountAddress: selectedAccount.address,
      order,
      orderHash,
      onStatus: validateAndUpdateStatus,
    })

    // Submits orders to API
    const response = await runSwapStep('submitOrder', {
      dexHelper: dex.helper,
      tradePair,
      swapAmount,
      swapPrice: currentSwapPriceWithNoSpread,
      signedOrder,
      orderExpireTime,
      onStatus: validateAndUpdateStatus,
    })

    // Waits for settlement and unlocks if success
    if (response.length) {
      const [orderId] = response

      revertSteps.unshift(['cancelOrder', [orderId]])

      setValuesTransaction(hash, {
        orderId,
      })

      updateTransaction(hash, { revertSteps })

      await runSwapStep('monitorOrder', {
        dexHelper: dex.helper,
        orderHash,
        orderId,
        onOrderSettled: async (err, data) => {
          try {
            const isUnlockSuccess = await runSwapStep('unlockToken', {
              dex,
              tokenSymbol: toTokenSymbol,
              orderHash: hash,
              validateStatus: stepStatus.validate,
              data: { err, settlementData: data },
            })
            if (isUnlockSuccess) {
              wallet.dispatch.updateBalancesWallet(toTokenSymbol, {
                unlocking: false,
                wrapper: '0',
              })
            }
          } catch (errUnlock) {
            if (process.env.NODE_ENV !== 'production') {
              console.warn(errUnlock)
            }
          }
        },
        onStatus: validateAndUpdateStatus,
      })
    } else {
      throw Error(formatError(response))
    }
  } catch (err) {
    const message = formatError(err)

    if (process.env.NODE_ENV !== 'production') {
      console.warn(err)
      console.warn(`CATCH: ${message}`)
    }

    setAlertTransaction(hash, `${SUBMISSION_ERROR}: ${message}`, Intent.DANGER, Date.now())
    setStatusTransaction(hash, SUBMISSION_ERROR)
    updateTransaction(hash, { error: { message } })
    try {
      await dex.helper.revertSwap(revertSteps)
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(e)
      }

      setAlertTransaction(hash, formatError(e), Intent.DANGER, Date.now())
    }
  }
}

export default onConfirmSwap
