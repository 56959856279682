export const WALLET_ALERT_SET = 'WALLET_ALERT_SET'
export const WALLET_BALANCES_RESET = 'WALLET_BALANCES_RESET'
export const WALLET_BALANCES_UPDATE = 'WALLET_BALANCES_UPDATE'
export const WALLET_RESET = 'WALLET_RESET'
export const WALLET_SELECTED_ACCOUNT_SET = 'WALLET_SELECTED_ACCOUNT_SET'
export const WALLET_UPDATE = 'WALLET_UPDATE'
export const WALLET_SWAP_UPDATE = 'WALLET_SWAP_UPDATE'
export const WALLET_SWAP_PAIR_UPDATE = 'WALLET_SWAP_PAIR_UPDATE'
export const WALLET_SWAP_PAIR_AMOUNTS_UPDATE = 'WALLET_SWAP_PAIR_AMOUNTS_UPDATE'
export const WALLET_SWAP_PAIR_AMOUNTS_BASE_UNITS_UPDATE = 'WALLET_SWAP_PAIR_AMOUNTS_BASE_UNITS_UPDATE'
export const WALLET_SWAP_STATUS_UPDATE = 'WALLET_SWAP_STATUS_UPDATE'

export const WALLETS = {
  METAMASK: 'metamask',
  PORTIS: 'portis',
  LEDGER: 'ledger',
  NONE: null,
}
