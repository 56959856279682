import React from 'react';
import { Box, Grid } from 'grommet';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import TopBarLogo from 'components/molecules/TopBarLogo';
import SimpleText from 'components/atoms/SimpleText';
import TopBarLinks from 'components/molecules/TopBarLinks';
import { Link } from 'react-router-dom';

const TitleLink = styled(SimpleText)`
  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const makeLayout = ui => {
  let isMobile = true;
  let columns = ['25px', 'flex', '25px'];
  let areas = [
    { name: 'logo', start: [0, 0], end: [1, 0] },
    { name: 'title', start: [1, 0], end: [2, 0] },
    { name: 'links', start: [2, 0], end: [3, 0] }
  ];

  if (ui.windowSize.innerWidth > ui.breakpoint.tablet) {
    columns = ['100px', 'flex'];
    areas = [
      { name: 'logo', start: [0, 0], end: [1, 0] },
      { name: 'links', start: [1, 0], end: [2, 0] }
    ];
    isMobile = !isMobile;
  }

  return { columns, areas, isMobile };
};

const StyledTitle = styled.div`
  font-weight: 700;
`;

const TopBar = ({ theme }) => {
  const { ui } = theme;

  const { columns, areas, isMobile } = makeLayout(ui);
  return (
    <Grid fill rows={['flex']} columns={columns} gap="small" areas={areas}>
      <Box gridArea="logo">
        <TopBarLogo withTitle={!isMobile} />
      </Box>
      {isMobile && (
        <Box gridArea="title" align="center">
          <StyledTitle>
            <TitleLink>
              <Link to="/">FlareDEX</Link>
            </TitleLink>
          </StyledTitle>
        </Box>
      )}
      <Box gridArea="links" align="end">
        <TopBarLinks isMobile={isMobile} />
      </Box>
    </Grid>
  );
};

TopBar.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  ).isRequired
};

TopBar.defaultProps = {};

export default withTheme(TopBar);
