const getPriceLevel = (pair, data, amount, reverse, settleSpread) => {
  let pLevel
  if (!data.books[pair] && !data.tickers[pair]) {
    return null
  }

  if (!data.books[pair] || !amount) {
    pLevel = data.tickers[pair].lastPrice
  } else {
    const {
      books: {
        psnap: { bids, asks },
      },
    } = data.books[pair]


    const side = reverse ? asks : bids

    if (!side[0]) {
      throw Error('No available orders to fill')
    }

    let total = reverse ? side[0][0] * side[0][2] : side[0][2]
    pLevel = Number(side[0][0])

    let i = 1
    while (total < amount) {
      if (side[i]) {
        total = reverse ? side[i][0] * side[i][2] : side[i][2]
        pLevel = Number(side[i][0])
        i += 1
      } else {
        pLevel = Number(side[side.length - 1][0])
        break
      }
    }
  }

  if (pair.slice(-3) === 'USD' && settleSpread) {
    pLevel *= 1 + settleSpread
  }

  return pLevel
}

export default getPriceLevel
