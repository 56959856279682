import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Logo from 'components/atoms/Logo';
import SimpleText from 'components/atoms/SimpleText';
import { Box } from 'grommet';

const LogoWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  > div:first-of-type {
    flex: 1;
    width: 50px;
  }
  > div:nth-of-type(2) {
    flex: 1;
    padding-left: 10px;
    font-weight: 700;
  }
`;

const TopBarLogo = ({ withTitle }) => (
  <LogoWrapper>
    <div>
      <Logo size="medium" />
    </div>
    {withTitle && (
      <div>
        <SimpleText>FlareDEX</SimpleText>
      </div>
    )}
  </LogoWrapper>
);

TopBarLogo.propTypes = {
  withTitle: PropTypes.bool
};

TopBarLogo.defaultProps = {
  withTitle: false
};

export default TopBarLogo;
