import PropTypes from 'prop-types';
import React from 'react';
import TokenIcon from './TokensSelect.icon';
import { WITH_THEME } from './TokensSelect.constants';
import { withTheme } from '../../utils/misc';

const { SELECT_MENU_HEADER } = WITH_THEME;

const StyledDiv = withTheme.styled.div`
  ${props => props.theme[SELECT_MENU_HEADER]}
  display: flex;
  padding: 10px;
  cursor: pointer; 
  > div {
    flex: 1;
  }
`;

const Header = ({ tokens, onClick, setOpen }) => {
  const renderTokens = tokens.map((token, key) => (
    <div
      tabIndex={key}
      key={`header-token-${token}`}
      role="link"
      onClick={() => {
        onClick(token);
        setOpen(false);
      }}
      onKeyPress={e => {
        if (e.keyCode === 13) {
          onClick(token);
          setOpen(false);
        }
      }}
    >
      <TokenIcon asText isImg symbol={token} size={24} color="black" withText />
    </div>
  ));
  return <StyledDiv>{renderTokens}</StyledDiv>;
};

Header.propTypes = {
  tokens: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOpen: PropTypes.func,
  onClick: PropTypes.func
};

Header.defaultProps = {
  onClick: () => null,
  setOpen: () => null
};

export default Header;
