import { signOrder, dexWrapper } from '../dex'

const initHelper = () => {
  const web3 = null
  const source = null

  return {
    getWeb3: () => web3,
    init: () => Promise.resolve([]),
    close: () => Promise.resolve(),
    async getAccounts() {
      return this.getWeb3() ? this.getWeb3().eth.getAccounts() : []
    },
    getBalances: () => null,
    getETHBalances: () => null,
    getSource: () => source,
    sign: async data => dexWrapper.getInstance().sign(data),
    signOrder(account, order, orderHash) {
      return signOrder(this.getWeb3(), account, order, orderHash)
    },
    subscribe: () => Promise.resolve(),
    unsubscribe: () => Promise.resolve(),
  }
}

export default initHelper
