import TransportU2F from '@ledgerhq/hw-transport-u2f'
import Eth from '@ledgerhq/hw-app-eth'
import { LEDGER_ERROR } from '../../../../const/errors'
import LedgerProvider from './LedgerProvider'
import { web3Wrapper } from '../../../web3'

const { LOGIN_DENIED } = LEDGER_ERROR

const path = "44'/60'/0'/0/0"

const connectLedger = async (onWalletInteraction) => {
  try {
    const transport = await TransportU2F.create()

    const eth = new Eth(transport)

    const account = await eth.getAddress(path)

    if (!account.address) {
      throw new Error(LOGIN_DENIED)
    }

    const address = account.address.toLowerCase()

    const provider = new LedgerProvider(path, address, onWalletInteraction)

    const web3 = web3Wrapper.init(provider, true)

    return web3
  } catch (err) {
    throw err
  }
}

export default connectLedger
