const getSafeGasPrice = async () => {
  try {
    const res = await fetch('https://ethgasstation.info/json/ethgasAPI.json')
    const data = await res.json()
    return (data.average * 1.25) / 10
  } catch (e) {
    console.error(e)
    return 5
  }
}

export default getSafeGasPrice
