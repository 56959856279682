import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import SimpleText from 'components/atoms/SimpleText';
import { formatTokenAmount } from 'utils/format';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { Box } from 'grommet';
import { FormUp, FormDown } from 'grommet-icons';

const variationColor = {
  positive: '#00873D',
  negative: '#A2423D',
  novariation: '#444444'
};

const StyledPriceVariation = styled(SimpleText)`
  padding-right: 5px;
  color: ${props => {
    if (props.variation === 0) {
      return variationColor.novariation;
    }
    return props.variation > 0
      ? variationColor.positive
      : variationColor.negative;
  }};
`;

const StyledSimpleText = styled(SimpleText)`
  /* font-size: 24px; */
  #tv-ticker-price {
    margin-right: 5px;
    border-bottom-color: ${props => {
      if (props.variation === 0) {
        return variationColor.novariation;
      }
      return props.variation > 0
        ? variationColor.positive
        : variationColor.negative;
    }};
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }
  #tv-ticker-base {
    font-weight: 700;
  }
`;

const calcVariation = (curPrice, prevPrice) => {
  const curPriceBn = new BigNumber(curPrice);

  const prevPriceBn = new BigNumber(prevPrice);

  if (!prevPriceBn.eq(0)) {
    const variation = curPriceBn
      .minus(prevPriceBn)
      .dividedBy(curPriceBn)
      .multipliedBy(100)
      .toFixed(4);
    return variation >= 0 ? `+${variation}` : variation;
  }
  return new BigNumber(0).toFixed(4);
};

const TickerPrice = ({ curPrice, symbols }) => {
  const [priceVariation, setPriceVariation] = useState(0);

  const prevPrice = useRef(curPrice);

  const [baseTokenSymbol, quoteTokenSymbol] = symbols;

  const showDollar = symbols[1] === 'USD' ? '$' : '';

  useEffect(() => {
    if (curPrice === 0) return;
    setPriceVariation(calcVariation(curPrice, prevPrice.current));
    prevPrice.current = curPrice;
  }, [curPrice]);

  useEffect(() => {
    setPriceVariation(0);
  }, [baseTokenSymbol, quoteTokenSymbol]);

  return (
    <Box>
      <Box direction="row" align="end" key={curPrice}>
        {Number(priceVariation) !== 0 && (
          <Box>
            {Number(priceVariation) > 0 ? (
              <FormUp color={variationColor.positive} />
            ) : (
              <FormDown color={variationColor.negative} />
            )}
          </Box>
        )}
        <Box>
          <StyledPriceVariation
            variation={Number(priceVariation)}
            size="medium"
          >
            {Number(priceVariation) !== 0 ? `${priceVariation}` : `-`}
          </StyledPriceVariation>
        </Box>

        <StyledSimpleText size="large" variation={Number(priceVariation)}>
          <Box direction="row">
            <div id="tv-ticker-price">
              {` ${showDollar}${formatTokenAmount(curPrice || 0)}`}
            </div>
            <div id="tv-ticker-base">{baseTokenSymbol}</div>
            <div>/</div>
            <div id="tv-ticker-quote">{quoteTokenSymbol}</div>
          </Box>
        </StyledSimpleText>
      </Box>
    </Box>
  );
};

TickerPrice.propTypes = {
  symbols: PropTypes.arrayOf(PropTypes.string).isRequired,
  curPrice: PropTypes.string.isRequired
};

TickerPrice.defaultProps = {
  showSymbol: true
};

export default React.memo(TickerPrice);
