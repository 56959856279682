import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import tvUtil from 'tv-lines/src/index';
import { widget } from './charting_library/charting_library.min';

const TvWrapper = styled.div`
  /* height: calc(100vh - 80px); */
  height: 100%;
`;

let tvWidget = null;

const TradingViewChart = ({
  exchangeService,
  TradinViewOptions,
  onChartReady
}) => {
  useEffect(() => {
    // eslint-disable-next-line new-cap
    tvWidget = new widget(TradinViewOptions);

    tvWidget.onChartReady(() => {
      const chart = tvWidget.chart();
      onChartReady(tvWidget);
      // chart
      //   .createPositionLine()
      //   .onModify(function() {
      //     this.setText('onModify called');
      //   })
      //   .onReverse('onReverse called', function(text) {
      //     this.setText(text);
      //   })
      //   .onClose('onClose called', function(text) {
      //     this.setText(text);
      //   })
      //   .setText('PROFIT: 71.1 (3.31%)')
      //   .setTooltip('Additional position information')
      //   .setProtectTooltip('Protect position')
      //   .setCloseTooltip('Close position')
      //   .setReverseTooltip('Reverse position')
      //   .setQuantity('8.235')
      //   .setPrice(184.5)
      //   .setExtendLeft(true)
      //   .setLineStyle(0)
      //   .setLineLength(25);

      // const tvChartUtil = tvUtil(chart);
      // tvChartUtil.interactions$.subscribe(interaction => {
      //   console.log(interaction);
      // });

      // const order = {
      //   data: {
      //     id: 123,
      //     price: '179',
      //     quantity: '50',
      //     tooltip: 'Custom tooltip',
      //     editable: true,
      //     text: 'First test order'
      //   }
      // };

      // const orderLine = tvChartUtil.order.add(order);

      // const position = {
      //   data: {
      //     id: 123,
      //     price: '180',
      //     quantity: '50',
      //     tooltip: 'Custom tooltip',
      //     editable: true,
      //     text: 'First test position'
      //   },
      //   style: {
      //     lineColor: 'rgb(0, 0, 0)',
      //     reverseButtonBackgroundColor: 'rgba(0, 0, 0, 0.75)'
      //   }
      // };

      // const positionLine = tvChartUtil.position.add(position);
      // setTimeout(() => {
      //   console.log('update');
      //   tvChartUtil.order.update(123, {
      //     data: {
      //       price: 179.5,
      //       quantity: '50',
      //       tooltip: 'Custom tooltip changed',
      //       editable: true,
      //       text: 'First test order modified'
      //     },
      //     style: {
      //       lineColor: 'rgb(0, 17, 255)',
      //       quantityBackgroundColor: 'rgb(0, 17, 255)'
      //     }
      //   });
      //   tvChartUtil.position.update(123, {
      //     data: {
      //       price: 179,
      //       quantity: '50',
      //       tooltip: 'Custom tooltip changed',
      //       editable: true,
      //       text: 'First test position modified'
      //     },
      //     style: {
      //       lineColor: 'rgb(0, 17, 255)',
      //       quantityBackgroundColor: 'rgb(0, 17, 255)'
      //     }
      //   });
      // }, 2000);

      // setTimeout(() => {
      //   tvChartUtil.order.delete(123);
      // }, 5000);
      // setTimeout(() => {
      //   tvChartUtil.position.delete(123);
      // }, 10000);

      // chart
      //   .createOrderLine()
      //   .setTooltip('Tooltip')
      //   .setPrice(188)
      //   .setQuantity('2')
      //   .onMove(function() {
      //     this.setText('onMove called');
      //   })
      //   .onModify('onModify called', function(text) {
      //     this.setText(text);
      //   })
      //   .onCancel('onCancel called', function(text) {
      //     this.setText(text);
      //   })

      //   .setText('STOP: 73.5 (5,64%)');

      // Fired when the intervall is changed. We then set the time range shown on screen,
      // so for example, the last 2 hours

      chart.onIntervalChanged().subscribe(null, (interval, obj) => {
        // const now = moment();
        // switch (obj.timeframe) {
        //   case '2h': {
        //     const twoHoursBefore = now.subtract(2, 'hours').valueOf();
        //     chart.setVisibleRange({
        //       from: twoHoursBefore,
        //       to: now.valueOf()
        //     });
        //     break;
        //   }
        //   case '6h': {
        //     const sixHoursBefore = now.subtract(6, 'hours').valueOf();
        //     console.log(sixHoursBefore, now.valueOf());
        //     chart.setVisibleRange({
        //       from: sixHoursBefore,
        //       to: now.valueOf()
        //     });
        //     break;
        //   }
        //   case '1d': {
        //     const oneDayBefore = now.subtract(1, 'days').valueOf();
        //     chart.setVisibleRange({
        //       from: oneDayBefore,
        //       to: now.valueOf()
        //     });
        //     break;
        //   }
        //   default:
        // }
      });
    });
    return () => {
      if (tvWidget !== null) {
        tvWidget.remove();
        tvWidget = null;
      }
    };
  }, [TradinViewOptions, exchangeService, onChartReady]);

  return <TvWrapper id={TradinViewOptions.container_id} />;
};

TradingViewChart.propTypes = {
  exchangeService: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ).isRequired,
  TradinViewOptions: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
      PropTypes.string,
      PropTypes.array
    ])
  ).isRequired,
  onChartReady: PropTypes.func
};

TradingViewChart.defaultProps = {
  onChartReady: () => {}
};

export default React.memo(TradingViewChart);
