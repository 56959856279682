import { SWAP_STATUS } from '../../../../const'

const { SIGN_PENDING, SIGN_DONE } = SWAP_STATUS

const signOrder = async ({
  walletHelper, accountAddress, order, orderHash, onStatus,
}) => {
  try {
    onStatus(SIGN_PENDING)

    const signedOrder = await walletHelper.signOrder(
      accountAddress,
      { ...order },
      orderHash,
    )

    onStatus(SIGN_DONE)

    return signedOrder
  } catch (err) {
    throw err
  }
}

export default signOrder
