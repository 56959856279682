import { v3 } from 'murmurhash'
import { SWAP_STATUS, Intent } from '../../const'
import { validateAmounts } from '../validation'
import { formatError } from '../format'

const onOpenSwap = (wallet, dex) => {
  const {
    swap: {
      fromToken,
      toToken,
      pair,
      fromTokenAmount,
      toTokenAmount,
      fromTokenAmountBaseUnits,
      price,
    },
    balances,
  } = wallet.state

  const {
    updateSwapWallet,
    setAlertWallet,
  } = wallet.dispatch

  const balance = (balances[fromToken.symbol] && balances[fromToken.symbol].token) || 0

  let validationOptions = {
    isPositiveNumber: true,
    isMinOrderSize: true,
    isBalanceSufficient: true,
  }

  if (fromTokenAmountBaseUnits) {
    validationOptions = {
      ...validationOptions,
      ...{ isPositiveNumber: false, isBalanceSufficient: false },
    }
  }

  try {
    validateAmounts(fromToken, toToken, fromTokenAmount, toTokenAmount, balance, validationOptions)
    setAlertWallet('from', null, null)
  } catch (err) {
    setAlertWallet('from', formatError(err), Intent.DANGER)
    return
  }

  const timestamp = Date.now()
  const swapHash = v3(`${price}${pair}${timestamp}`)
  const payload = {
    timestamp,
    hash: swapHash,
    status: SWAP_STATUS.CONFIRMATION_PENDING,
  }

  const newTransaction = dex.helper.initTransaction({ ...wallet.state.swap, ...payload })

  updateSwapWallet(payload)

  dex.dispatch.addTransaction(swapHash, newTransaction)
}

export default onOpenSwap
