import { createAction } from 'redux-actions'
import {
  WS_CHANNEL_ADD, WS_CHANNEL_REMOVE, WS_TICKER_UPDATE, WS_BOOKS_UPDATE, WS_BOOKS_RESET,
} from './const'

export const addChannelWs = createAction(WS_CHANNEL_ADD)
export const removeChannelWs = createAction(WS_CHANNEL_REMOVE)
export const resetBooksWs = createAction(WS_BOOKS_RESET)
export const updateBooksWs = createAction(WS_BOOKS_UPDATE)
export const updateTickerWs = createAction(WS_TICKER_UPDATE)
