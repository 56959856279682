import { subPair, unsubPair } from '../../utils/ws'
import { bindFn } from '../../utils/misc'

const onReconnectWs = setStateFn => (err, wsInstance) => setStateFn({
  ws: wsInstance,
  subPair: subPair(bindFn(wsInstance)),
  unsubPair: unsubPair(bindFn(wsInstance)),
})

export default onReconnectWs
