export { Intent } from '@blueprintjs/core'

export const orderExpireTime = 3600 / 2
export const lockExpireTime = 500

export const WALLETS = {
  METAMASK: 'metamask',
  PORTIS: 'portis',
  LEDGER: 'ledger',
  NONE: null,
}

export const WALLET_STATUS = {
  ERROR: 'error',
  LOGGING: 'logging',
  LOGGED_IN: 'logged in',
  LOGGED_OUT: 'logged out',
}

export const ETH = 'ETH'
export const USDT = 'USDT'
export const USD = 'USD'
export const DAI = 'DAI'
export const ASKS = 'asks'
export const BIDS = 'bids'
export const HB = 'hb'
export const CS = 'cs'

export const SWAP_STATUS = {
  CANCELLED: 'Cancelled',
  CANCELLATION_PENDING: 'Cancellation pending',
  SUCCESS: 'Swap done',
  CONFIRMATION_PENDING: 'Confirmation pending',
  ERROR: 'Error',
  SETTLEMENT_DONE: 'Settlement done',
  SETTLEMENT_MINED: 'Settlement mined',
  SETTLEMENT_PENDING: 'Settlement pending',
  LOCK_DONE: 'Lock done',
  LOCK_PENDING: 'Lock pending',
  NEW: 'New',
  SIGN_DONE: 'Signature done',
  SIGN_PENDING: 'Signature pending',
  SUBMISSION_ERROR: 'Submission error',
  SUBMIT_DONE: 'Submit done',
  SUBMIT_PENDING: 'Submit pending',
  UNLOCK_DONE: 'Unlock done',
  UNLOCK_PENDING: 'Unlock pending',
  UNLOCK_ERROR: 'Tokens could not be unlocked',
  REVERT_DONE: 'Revert successful',
}

export const SWAP_STEPS = [
  SWAP_STATUS.CONFIRMATION_PENDING,
  SWAP_STATUS.LOCK_PENDING,
  SWAP_STATUS.LOCK_DONE,
  SWAP_STATUS.SIGN_PENDING,
  SWAP_STATUS.SIGN_DONE,
  SWAP_STATUS.SUBMIT_PENDING,
  SWAP_STATUS.SUBMIT_DONE,
  SWAP_STATUS.SETTLEMENT_PENDING,
  SWAP_STATUS.SETTLEMENT_DONE,
  SWAP_STATUS.UNLOCK_PENDING,
  SWAP_STATUS.SUCCESS,
]

export const ALERT_MSG = {
  ORDER_CANCELLED_SUCCESS: 'Order cancelled successfully',
}
