import { METAMASK_ERROR } from '../../../../const/errors';
import isApproved from './isApproved';
import { web3Wrapper } from '../../../web3';

const { NOT_DETECTED, ACCESS_DENIED } = METAMASK_ERROR;

const connectMetamask = async () => {
  const approved = await isApproved();

  web3Wrapper.reset();

  if (!approved) {
    const provider = window.ethereum;

    try {
      await provider.enable();
      const web3 = web3Wrapper.init(provider, false);

      return web3;
    } catch (err) {
      throw new Error(ACCESS_DENIED);
    }
  } else if (window.web3) {
    const provider = window.web3.currentProvider;

    const web3 = web3Wrapper.init(provider, false);

    return web3;
  } else {
    throw new Error(NOT_DETECTED);
  }
};

export default connectMetamask;
