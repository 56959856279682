var _defineProperty = require("/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var u = require('updeep');

var _require = require('../../const'),
    defaultPositionProps = _require.defaultPositionProps,
    interactionType = _require.interactionType,
    defaultPositionStyleProps = _require.defaultPositionStyleProps,
    lineType = _require.lineType;

var makeInteractionMsg = require('../misc/makeInteractionMsg');

module.exports = function (tvUtil, db, onInteraction$, position) {
  var tvChart = tvUtil.tvChart;
  var data = position.data,
      style = position.style;

  var fullPositionData = _objectSpread({}, defaultPositionProps, {}, data);

  var fullPositionStyle = _objectSpread({}, defaultPositionStyleProps, {}, style);

  var id = fullPositionData.id,
      price = fullPositionData.price,
      quantity = fullPositionData.quantity,
      tooltip = fullPositionData.tooltip,
      protectTooltip = fullPositionData.protectTooltip,
      reverseTooltip = fullPositionData.reverseTooltip,
      closeTooltip = fullPositionData.closeTooltip,
      text = fullPositionData.text;
  var extendLeft = fullPositionStyle.extendLeft,
      lineLength = fullPositionStyle.lineLength,
      lineStyle = fullPositionStyle.lineStyle,
      lineWidth = fullPositionStyle.lineWidth,
      bodyFont = fullPositionStyle.bodyFont,
      quantityFont = fullPositionStyle.quantityFont,
      lineColor = fullPositionStyle.lineColor,
      bodyBorderColor = fullPositionStyle.bodyBorderColor,
      bodyBackgroundColor = fullPositionStyle.bodyBackgroundColor,
      bodyTextColor = fullPositionStyle.bodyTextColor,
      quantityBorderColor = fullPositionStyle.quantityBorderColor,
      quantityBackgroundColor = fullPositionStyle.quantityBackgroundColor,
      quantityTextColor = fullPositionStyle.quantityTextColor,
      reverseButtonBorderColor = fullPositionStyle.reverseButtonBorderColor,
      reverseButtonBackgroundColor = fullPositionStyle.reverseButtonBackgroundColor,
      reverseButtonIconColor = fullPositionStyle.reverseButtonIconColor,
      closeButtonBorderColor = fullPositionStyle.closeButtonBorderColor,
      closeButtonBackgroundColor = fullPositionStyle.closeButtonBackgroundColor,
      closeButtonIconColor = fullPositionStyle.closeButtonIconColor;
  var ON_POSITION_ADD = interactionType.ON_POSITION_ADD,
      ON_POSITION_CLOSE = interactionType.ON_POSITION_CLOSE,
      ON_POSITION_MODIFY = interactionType.ON_POSITION_MODIFY,
      ON_POSITION_REVERSE = interactionType.ON_POSITION_REVERSE;
  var POSITION_LINE = lineType.POSITION_LINE;

  try {
    var positionLine = tvChart.createPositionLine(); // General properties methods

    positionLine.setPrice(price).setQuantity(quantity).setText(text).setProtectTooltip(protectTooltip).setCloseTooltip(closeTooltip).setReverseTooltip(reverseTooltip).setTooltip(tooltip).onReverse(function onMove() {
      onInteraction$.next(makeInteractionMsg(ON_POSITION_REVERSE, db.get(id, POSITION_LINE)));
    }).onModify(function onModify() {
      onInteraction$.next(makeInteractionMsg(ON_POSITION_MODIFY, db.get(id, POSITION_LINE)));
    }).onClose(function onCancel() {
      onInteraction$.next(makeInteractionMsg(ON_POSITION_CLOSE, db.get(id, POSITION_LINE)));
    }); // Style methods

    positionLine.setExtendLeft(extendLeft).setLineLength(lineLength).setLineStyle(lineStyle).setLineWidth(lineWidth).setBodyFont(bodyFont).setQuantityFont(quantityFont).setLineColor(lineColor).setBodyBorderColor(bodyBorderColor).setBodyBackgroundColor(bodyBackgroundColor).setBodyTextColor(bodyTextColor).setQuantityBorderColor(quantityBorderColor).setQuantityBackgroundColor(quantityBackgroundColor).setQuantityTextColor(quantityTextColor).setReverseButtonBorderColor(reverseButtonBorderColor).setReverseButtonBackgroundColor(reverseButtonBackgroundColor).setReverseButtonIconColor(reverseButtonIconColor).setCloseButtonBorderColor(closeButtonBorderColor).setCloseButtonBackgroundColor(closeButtonBackgroundColor).setCloseButtonIconColor(closeButtonIconColor);
    db.add(u(data, {}), style, positionLine, POSITION_LINE);
    onInteraction$.next(makeInteractionMsg(ON_POSITION_ADD, db.get(id, POSITION_LINE)));
    return positionLine;
  } catch (err) {
    return {
      error: err.message
    };
  }
};