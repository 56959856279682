/* eslint-disable func-names */
import ExchangeConnector from 'exchange-connector'

const ExchangeConnectorWrapper = (function () {
  let instance

  return {
    getInstance() {
      if (!instance) {
        instance = new ExchangeConnector()
      }
      return instance
    },
    getNewInstance() {
      return new ExchangeConnector()
    },
  }
}())

export default ExchangeConnectorWrapper
