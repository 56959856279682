var _require = require('../../const'),
    lineType = _require.lineType;

var ORDER_LINE = lineType.ORDER_LINE;

module.exports = function (tvUtil, db, onInteraction$, id) {
  var _db$get = db.get(id, ORDER_LINE),
      tvLine = _db$get.tvLine;

  tvLine.remove();
  return db.del(id, ORDER_LINE);
};