import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from '@blueprintjs/core'
import styled from 'styled-components'

const StyledDiv = styled.div`
  cursor: pointer;
`

const CollapseSwitch = ({ collapsed, onToggleSwitch }) => (
  <StyledDiv onClick={() => onToggleSwitch(!collapsed)}>
    {collapsed ? <Icon icon='chevron-down' /> : <Icon icon='chevron-up' />}
  </StyledDiv>
)

CollapseSwitch.propTypes = {
  collapsed: PropTypes.bool,
  onToggleSwitch: PropTypes.func,
}

CollapseSwitch.defaultProps = {
  collapsed: true,
  onToggleSwitch: () => {},
}

export default React.memo(CollapseSwitch)
