import _defineProperty from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import omit from 'lodash/omit';
import WS from 'ws';
import conf from '../../env';
import pingWs from './pingWs'; // eslint-disable-next-line import/no-cycle

import reconnectWs from './reconnectWs';

function connectWs(url) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var defOpts = {
    initSubs: [],
    keepAlive: null,
    maxRetry: conf.wsMaxRetry,
    onErroCb: null,
    onMessageCb: null,
    onPongCb: null,
    onReconnectCb: null,
    onSubscriptionCb: null,
    retryDelay: conf.wsRetryDelay,
    timeout: conf.wsTimeout
  };

  var connOpts = _objectSpread({}, defOpts, {}, opts);

  var onOpenCb = connOpts.onOpenCb,
      onCloseCb = connOpts.onCloseCb,
      onMessageCb = connOpts.onMessageCb,
      onErrorCb = connOpts.onErrorCb,
      onSubscriptionCb = connOpts.onSubscriptionCb,
      onPongCb = connOpts.onPongCb;
  var ws = typeof window !== 'undefined' && window.WebSocket ? new WebSocket(url, connOpts.wsProtocols) : new WS(url);
  var pongTime = new Date().getTime();
  var td;

  var isStaleFn = function isStaleFn(pingTime) {
    if (pingTime - pongTime > connOpts.timeout) {
      if (ws.readyState === 1) {
        ws.close(3000, 'Connection timeout.');
      }

      if (td) {
        clearInterval(td);
      }

      if (onErrorCb) {
        onErrorCb(new Error('Stale connection detected.'), null);
      }
    }
  };

  ws.subs = connOpts.subs || {};
  ws.addEventListener('open', function (event) {
    if (connOpts.keepAlive && ws.readyState === 1) {
      td = pingWs(ws.send.bind(ws), isStaleFn, connOpts.keepAlive.msg, connOpts.keepAlive.time || conf.wsPingTime);
    }

    var initSubs = connOpts.initSubs;
    var subs = Object.keys(ws.subs);

    if (initSubs.length && !subs.length) {
      initSubs.forEach(function (sub) {
        ws.send(JSON.stringify(sub));
      });
    } else {
      subs.forEach(function (sub) {
        ws.send(JSON.stringify(ws.subs[sub]));
        ws.subs = omit(ws.subs, sub);
      });
    }

    if (onOpenCb) {
      onOpenCb(null, event);
    }
  });
  ws.addEventListener('close', function (event) {
    if (td) {
      clearInterval(td);
    }

    if (event.code !== 1000 && event.code !== 1005) {
      reconnectWs(url, _objectSpread({}, connOpts, {
        subs: _objectSpread({}, ws.subs)
      }));
    }

    if (onCloseCb) {
      onCloseCb(null, event);
    }
  });
  ws.addEventListener('error', function (err) {
    if (onErrorCb) {
      onErrorCb(err, null);
    }
  });
  ws.addEventListener('message', function (event) {
    if (onSubscriptionCb) {
      ws.subs = onSubscriptionCb(null, event, _objectSpread({}, ws.subs));
    }

    if (onPongCb) {
      var timeStamp = onPongCb(null, event);

      if (timeStamp) {
        pongTime = timeStamp;
      }
    }

    if (onMessageCb) {
      onMessageCb(null, event);
    }
  });
  return ws;
}

export default connectWs;