import ChartBar from 'components/atoms/ChartBar'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const NoChartData = styled.div`
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  margin: auto;
  text-align: center;
`

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TokenBarChart = ({ data, mapFn }) => {
  const chartData = useMemo(() => mapFn(data), [data, mapFn])
  if (!data.length) {
    return <NoChartData>No data</NoChartData>
  }

  return (
    <ChartWrapper>
      <ChartBar data={chartData} />
    </ChartWrapper>
  )
}

TokenBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  mapFn: PropTypes.func.isRequired,
}

TokenBarChart.defaultProps = {
}

export default React.memo(TokenBarChart)
