// Add your own proxy service if needed
export const api = `https://api.deversifi.com/v1`;
export const wsUrl = 'wss://api-pub.bitfinex.com/ws/2';
export const wsPingTime = 2000;
export const wsTimeout = 5000;
export const wsRetryDelay = 2000;
export const wsMaxRetry = Infinity;

// Add your own Portis App Id
export const portisAppId = 'eaee8f7f-b131-4f98-88ad-8ed4b24db60b';
export const KOVAN = 'kovan';
export const MAINNET = 'mainnet';
export const network = { id: 1, name: MAINNET };
export const providerUrl =
  'https://eth-mainnet.alchemyapi.io/jsonrpc/x-tRjho6wz229YCx8pftqHQm3vPxhfYA';
export const rpcUrlWeb3 =
  'https://mainnet.infura.io/v3/564c354d54514e138db390e6576bde5f';
export const wsUrlWeb3 =
  'wss://mainnet.infura.io/ws/v3/564c354d54514e138db390e6576bde5f';
export const efxNonceExpiration = 3600 * 3;

export const zeroExContractAddress =
  '0x080bf510FCbF18b91105470639e9561022937712';

export const assetsUrl = 'https://www.bitfinex.com';

export const quoteTokens = ['ETH', 'USD', 'DAI'];

export const pair = ['ETH', 'USD'];

export const fromToken = {
  decimals: 18,
  wrapperAddress: '0x50cb61afa3f023d17276dcfb35abf85c710d1cff',
  minOrderSize: 0.1,
  symbol: 'ETH'
};

export const toToken = {
  decimals: 6,
  wrapperAddress: '0x33d019eb137b853f0cdf555a5d5bd2749135ac31',
  tokenAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  minOrderSize: 25,
  settleSpread: 0,
  symbol: 'USD'
};
