import _toConsumableArray from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var isLastNthDataPoint = function isLastNthDataPoint(points, candles, entry) {
  var isNew = true;
  var i = 0;

  for (i; i <= points - 1; i += 1) {
    if (candles[i] && entry.time === candles[i].time) {
      isNew = false;
      break;
    }
  }

  return [isNew ? 0 : i, isNew];
};

var updateCandles = function updateCandles(update, candlesData, formatFn) {
  try {
    var _update = _slicedToArray(update, 3),
        pair = _update[0],
        data = _update[1],
        interval = _update[2];

    var channel = "".concat(interval, ":").concat(pair); // INITIAL SHAPSHOT

    if (Array.isArray(data[0])) {
      var candles = data.map(function (point) {
        return formatFn(point);
      });
      return _objectSpread({}, candlesData, _defineProperty({}, channel, {
        pair: pair,
        interval: interval,
        candles: candles,
        meta: {
          isSnapshot: true,
          isNewCandle: false,
          updateIndex: undefined
        }
      }));
    } // UPDATE


    if (!Array.isArray(data[0])) {
      var entry = formatFn(data);
      var meta;

      var _candles = _toConsumableArray(candlesData[channel].candles);

      var _isLastNthDataPoint = isLastNthDataPoint(2, _candles, entry),
          _isLastNthDataPoint2 = _slicedToArray(_isLastNthDataPoint, 2),
          i = _isLastNthDataPoint2[0],
          isNew = _isLastNthDataPoint2[1];

      if (isNew) {
        // console.log(`${pair} => NEW point`);
        _candles.unshift(entry);

        meta = {
          isSnapshot: false,
          isNewCandle: true,
          updateIndex: 0,
          isUpdateCandle: false
        };
      } else {
        // console.log(`${pair} => UPDATE point ${i}`);
        _candles[i] = entry;
        meta = {
          isSnapshot: false,
          isNewCandle: false,
          updateIndex: i,
          isUpdateCandle: true
        };
      }

      return _objectSpread({}, candlesData, _defineProperty({}, channel, {
        pair: pair,
        interval: interval,
        candles: _toConsumableArray(_candles),
        meta: meta
      }));
    }

    return candlesData;
  } catch (e) {
    console.warn(e);
  }
};

export default updateCandles;