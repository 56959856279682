import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import FlexDiv from './WalletsBox.flexDiv'
import BalanceRow from './WalletBox.balanceRow'

const WalletsBalances = ({
  balances,
  fromToken,
  toToken,
  onUnlock,
  onSelectFullBalance,
  isReverseSwap,
}) => {
  const tokens = useMemo(() => {
    const t = [fromToken, toToken]

    if (isReverseSwap) {
      t.reverse()
    }
    return t
  }, [fromToken, isReverseSwap, toToken])

  return (
    <React.Fragment>
      <React.Fragment>
        <FlexDiv flex header>
          <FlexDiv col>
            <FlexDiv symbol>TOKEN</FlexDiv>
            <FlexDiv />
          </FlexDiv>
          <FlexDiv col>
            <FlexDiv amount>BALANCE</FlexDiv>
            <FlexDiv amount>LOCKED</FlexDiv>
          </FlexDiv>
        </FlexDiv>
        {tokens.map((token) => {
          const tokenBalances = balances[token.symbol] || undefined
          return (
            <BalanceRow
              key={`token-balances-row-${token.symbol}`}
              tokenBalances={tokenBalances}
              tokenData={token}
              onUnlock={onUnlock}
              onSelectFullBalance={onSelectFullBalance}
            />
          )
        })}
      </React.Fragment>
    </React.Fragment>
  )
}

WalletsBalances.propTypes = {
  isReverseSwap: PropTypes.bool.isRequired,
  balances: PropTypes.objectOf(PropTypes.object).isRequired,
  fromToken: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  ).isRequired,
  toToken: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  ).isRequired,
  onUnlock: PropTypes.func.isRequired,
  onSelectFullBalance: PropTypes.func.isRequired,
}

export default React.memo(WalletsBalances)
