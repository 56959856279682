import {
  SWAP_STATUS, SWAP_ERROR, Intent, ALERT_MSG,
} from '../../../../const'
import { formatError } from '../../../format'

const {
  CANCELLED, SETTLEMENT_DONE, SUCCESS, UNLOCK_PENDING, UNLOCK_ERROR,
} = SWAP_STATUS

const unlockToken = async ({
  dex, tokenSymbol, orderHash, validateStatus, data,
}) => {
  const { err, settlementData } = data

  const {
    setStatusTransaction,
    setAlertTransaction,
    updateTransaction,
    setValuesTransaction,
  } = dex.dispatch

  let revertSteps = null

  let transactionStatus

  let transactionData = { revertSteps, error: { message: null } }

  let type = Intent.SUCCESS

  let error

  if (err) {
    return null
  }

  if (settlementData && settlementData.cid) {
    if (settlementData.status.includes('EXECUTED')) {
      setStatusTransaction(orderHash, validateStatus(SETTLEMENT_DONE))
    }
    if (settlementData.status.includes('CANCELED')) {
      setAlertTransaction(orderHash, ALERT_MSG.ORDER_CANCELLED_SUCCESS, Intent.SUCCESS, Date.now())
      setStatusTransaction(orderHash, CANCELLED)
      updateTransaction(orderHash, { error: { message: SWAP_ERROR.CANCELLED } })
    }
    return null
  }

  if (settlementData && settlementData.returnValues) {
    setStatusTransaction(orderHash, validateStatus(UNLOCK_PENDING))

    try {
      await dex.helper.unlockTokens(
        tokenSymbol,
        settlementData.returnValues.takerAssetFilledAmount,
        true,
      )
      transactionStatus = validateStatus(SUCCESS)
    } catch (e) {
      error = e
      revertSteps = [
        'unlock',
        [tokenSymbol, settlementData.returnValues.takerAssetFilledAmount, true],
      ]
      transactionStatus = UNLOCK_ERROR
      transactionData = { revertSteps, error: { message: formatError(e) } }
      type = Intent.WARNING
    }
    setValuesTransaction(orderHash, {
      txReceipt: settlementData,
    })
    setAlertTransaction(orderHash, transactionStatus, type, Date.now())
    setStatusTransaction(orderHash, transactionStatus)
    updateTransaction(orderHash, transactionData)
    if (error) {
      throw error
    }
  }

  return true
}

export default unlockToken
