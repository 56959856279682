import { createActions } from 'redux-actions';

const chart = createActions(
  {
    SET: setting => {
      console.log(setting);
      return setting;
    }
  },
  { namespace: '_' }
);

export default { ...chart };
