const unsubscribe = async (subWeb3, subMetamask) => {
  let err

  try {
    if (subWeb3) {
      await subWeb3.unsubscribe()
    }
  } catch (e) {
    err = e
  }

  try {
    if (subMetamask) {
      subMetamask.removeAllListeners('update')
    }
  } catch (e) {
    err = e
  }

  return err || true
}

export default unsubscribe
