export const tokenAnalystApiUrl = 'https://api.tokenanalyst.io/analytics'

export const EFX = 'EFX'
export const ETHFINEX = 'Ethfinex'

export const KOVAN = 'kovan'
export const KOVAN_ID = 42
export const ROPSTEN = 'ropsten'
export const ROPSTEN_ID = 3
export const MAINNET = 'mainnet'
export const MAINNET_ID = 1

export const NETWORKS = {
  kovan: {
    id: KOVAN_ID,
    name: 'kovan',
    etherscan: 'https://kovan.etherscan.io/',
  },
  ropsten: {
    id: ROPSTEN_ID,
    name: 'ropsten',
    etherscan: 'https://ropsten.etherscan.io/',
  },
  mainnet: {
    id: MAINNET_ID,
    name: 'mainnet',
    etherscan: 'https://etherscan.io/',
  },
}

export const ENDPOINTS = {
  infura: {
    name: 'infura',
    https: {
      kovan: {
        dev: 'https://kovan.infura.io/metamask',
        prod: 'https://kovan.infura.io/metamask',
      },
      ropsten: {
        dev: 'https://ropsten.infura.io/metamask',
        prod: 'https://ropsten.infura.io/metamask',
      },
      mainnet: {
        dev: 'https://mainnet.infura.io/v3/3fe47e5272bc4d38b6f0b247d94a6cd1',
        prod: 'https://mainnet.infura.io/v3/3fe47e5272bc4d38b6f0b247d94a6cd1',
      },
    },
    wss: {
      kovan: {
        dev: 'wss://kovan.infura.io/ws',
        prod: 'wss://kovan.infura.io/ws',
      },
      ropsten: {
        dev: 'wss://ropsten.infura.io/ws',
        prod: 'wss://ropsten.infura.io/ws',
      },
      mainnet: {
        dev: 'wss://mainnet.infura.io/ws',
        prod: 'wss://mainnet.infura.io/ws',
      },
    },
  },
  local: {
    name: 'local',
    https: {
      kovan: {
        dev: 'http://localhost:8545',
        prod: 'http://localhost:8545',
      },
      ropsten: {
        dev: 'http://localhost:8545',
        prod: 'http://localhost:8545',
      },
      mainnet: {
        dev: 'http://localhost:8545',
        prod: 'http://localhost:8545',
      },
    },
    wss: {
      kovan: {
        dev: 'ws://localhost:8546',
        prod: 'ws://localhost:8546',
      },
      ropsten: {
        dev: 'ws://localhost:8546',
        prod: 'ws://localhost:8546',
      },
      mainnet: {
        dev: 'ws://localhost:8546',
        prod: 'ws://localhost:8546',
      },
    },
  },
}

export const DEFAULT_ENDPOINT = {
  networkId: MAINNET_ID,
  networkName: MAINNET,
  relayName: ETHFINEX,
  provider: ENDPOINTS.infura.wss.mainnet,
}
