import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import Flash from 'react-reveal/Flash'
import {
  Icon,
  Spinner,
  Intent,
  Tooltip,
  Position,
  Popover,
  H5,
  Button,
  ProgressBar,
} from '@blueprintjs/core'
import { WITH_THEME } from './TransactionsBox.constants'
import { withTheme } from '../../utils/misc'
import CryptoIcon from '../CryptoIcon'
import { SWAP_STATUS, SWAP_ERROR } from '../../const'
import useTransactionProgress from '../../hooks/useTransactionProgress'

const { CANCELLATION_POPOVER, ACTIONS, PROGRESS_BAR } = WITH_THEME

const TokenAmount = withTheme.styled.div`
  font-size: 1em;
`

const IconDiv = withTheme.styled.div`
`

const FlexWrapperTransaction = withTheme.styled.div`
  display: flex;
  @media (max-width: 400px) {
    flex: 1;
    flex-direction: column;
  }
  > div:first-of-type {
    width: 150px;
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  > div:nth-of-type(2) {
    padding-left: 5px;
    width: 20px;
    @media (max-width: 400px) {
      display: none;
      padding-left: 0px;
      width: 100%;
      text-align: center;
    }
  }
  > div:nth-of-type(3) { 
    padding-left: 5px;
    width: 150px;
    @media (max-width: 400px) {
      padding-left: 0px;
      width: 100%;
    }
   }
  > div:nth-of-type(4) {
    ${props => props.theme[ACTIONS]}
  }
`

const FlexWrapperProgress = withTheme.styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  }
  > div:first-of-type {
    width: 320px;
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  > div:nth-of-type(2) {
    padding-left: 10px;
    margin-top: -5px;
    @media (max-width: 400px) {
    }
  }
  ${props => props.theme[PROGRESS_BAR]}
`

const FlexWrapperActions = withTheme.styled.div`
  ul {
    padding: 0;
    margin: 0 0 0 10px;
    list-style: none;
    display: flex;
    justify-content: flex-end; 
    @media (max-width: 400px) {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end; 
    }
`

const StyledSpinner = withTheme(Spinner)`
  padding-top: 2px;
`

const StyledCancelContent = withTheme.styled.div`
  ${props => props.theme[CANCELLATION_POPOVER]}
`

const StyledIcon = withTheme.styled(Icon)`
  cursor: pointer;
  padding-right: 5px;
`

const currentStatus = status => status[status.length - 1]

const renderAction = (transaction, isCancelOpen, setCancelOpen, onCancel) => {
  const {
    swap: { hash, timestamp, network },
    error,
    values,
    status,
  } = transaction

  const isError = () => !!error.message

  return {
    browse: values && values.txReceipt && values.txReceipt.transactionHash && (
      <li id={`transaction-browse-${hash}`}>
        <Tooltip content='Browse on etherscan.io' position={Position.BOTTOM}>
          <StyledIcon
            icon='share'
            iconSize={16}
            tagName='a'
            target='_blank'
            href={`https://${
              network.id === 42 ? `${network.name}.` : ''
            }etherscan.io/tx/${values.txReceipt.transactionHash}`}
          />
        </Tooltip>
      </li>
    ),

    cancel: !isError() && currentStatus(status) === SWAP_STATUS.SETTLEMENT_PENDING && (
      <li id={`transaction-cancel-${hash}`}>
        <Popover minimal isOpen={isCancelOpen[hash]} enforceFocus={false}>
          <StyledIcon
            icon='cross'
            iconSize={16}
            onClick={() => setCancelOpen({ ...isCancelOpen, [hash]: true })}
          />
          <StyledCancelContent key={`cancel-${hash}`}>
            <H5>Cancel transaction</H5>
            <p>Are you sure you want to cancel this transaction?</p>
            <div>
              <Button
                minimal
                onClick={() => {
                  setCancelOpen({ ...isCancelOpen, [hash]: false })
                  onCancel(values.orderId, hash)
                }}
              >
                Yes
              </Button>
              <Button minimal onClick={() => setCancelOpen({ ...isCancelOpen, [hash]: false })}>
                No
              </Button>
            </div>
          </StyledCancelContent>
        </Popover>
      </li>
    ),

    info: !isError() && (
      <li id={`transaction-info-${hash}`}>
        <Tooltip
          content={(
            <div>
              {new Date(timestamp).toLocaleDateString('en-GB', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                minute: 'numeric',
                hour: 'numeric',
                second: 'numeric',
              })}
              <br />
              Price:
              {`  ${values.swapPrice || '-'}`}
              <br />
              Status:
              {`  ${currentStatus(status)}`}
            </div>
)}
          position={Position.BOTTOM}
        >
          <StyledIcon icon='info-sign' iconSize={16} />
        </Tooltip>
      </li>
    ),

    waiting: !isError()
      && ![
        SWAP_STATUS.SUCCESS,
        SWAP_STATUS.ERROR,
        SWAP_ERROR.UNLOCK,
        SWAP_STATUS.SUBMISSION_ERROR,
      ].includes(currentStatus(status)) && (
        <li id={`transaction-waiting-${hash}`}>
          <StyledSpinner intent={Intent.SUCCESS} size={15} />
        </li>
    ),

    warning: isError() && (
      <li id={`transaction-warning-${hash}`}>
        <Tooltip content={error.message || error} position={Position.BOTTOM}>
          <StyledIcon icon='warning-sign' iconSize={16} />
        </Tooltip>
      </li>
    ),
  }
}


const TransactionRow = ({ transaction, onCancel }) => {
  const {
    swap: {
      fromToken, toToken, fromTokenAmount, toTokenAmount, hash,
    },
    status,
  } = transaction

  const [progress] = useTransactionProgress(status)

  const [isCancelOpen, setCancelOpen] = useState({})

  const actions = renderAction(transaction, isCancelOpen, setCancelOpen, onCancel)

  return (
    <Col xs={12} key={`transaction-${hash}`}>
      <FlexWrapperTransaction>
        <div id={`transaction-from-amount-${hash}`}>
          <Row between='xs'>
            <Col>
              <CryptoIcon symbol={fromToken.symbol} size={16} />
            </Col>
            <Col>
              <TokenAmount>{`${parseFloat(fromTokenAmount)} ${fromToken.symbol}`}</TokenAmount>
            </Col>
          </Row>
        </div>
        <div id={`transaction-icon-${hash}`}>
          <Row center='xs' middle='xs'>
            <Col xs={12}>
              <IconDiv>
                <Icon icon='double-chevron-right' iconSize={16} />
              </IconDiv>
            </Col>
          </Row>
        </div>
        <div id={`transaction-to-amount-${hash}`}>
          <Row center='xs'>
            <Col xs={12} sm>
              <Row between='xs'>
                <Col>
                  <CryptoIcon symbol={toToken.symbol} size={16} />
                </Col>
                <Col>
                  <TokenAmount>{`${parseFloat(toTokenAmount)} ${fromToken.symbol}`}</TokenAmount>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div id={`transaction-actions-${hash}`}>
          <FlexWrapperActions>
            <ul>
              {actions.warning}
              {actions.info}
              {actions.cancel}
              {actions.browse}
              {actions.waiting}
            </ul>
          </FlexWrapperActions>
        </div>
      </FlexWrapperTransaction>
      <Row>
        <Col xs={12}>
          <FlexWrapperProgress>
            <Flash duration={5000} when={currentStatus(status).includes('pending')}>
              <div id={`transaction-progress-bar-${hash}`}>
                <ProgressBar
                  animate={false}
                  stripes={false}
                  value={progress}
                  intent={Intent.PRIMARY}
                />
              </div>
            </Flash>
            <div id={`transaction-progress-level-${hash}`}>{`${(progress * 100).toFixed(2)}%`}</div>
          </FlexWrapperProgress>
        </Col>
      </Row>
    </Col>
  )
}

TransactionRow.propTypes = {
  transaction: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default React.memo(TransactionRow)
