import { tokens, ui, tokensData, selectedToken, chart } from './initial';

const initialState = {
  selectedToken,
  tokens,
  tokensData,
  ui,
  chart
};

export default initialState;
