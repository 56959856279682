import { handleActions } from 'redux-actions';
import actions from 'store/actions';
import u from 'updeep';
import initialState from '../initialState';
import { ERC20_TOKENS } from '../../../const/tokens';

const selectedTokenReducer = handleActions(
  {
    [actions.selectedToken.base.token.set]: (state, action) => {
      return u(
        { baseToken: { ...ERC20_TOKENS.mainnet[action.payload] } },
        state
      );
    },
    [actions.selectedToken.quote.token.set]: (state, action) => {
      return u({ quoteToken: { ...action.payload } }, state);
    }
  },
  initialState.selectedToken
);

export default selectedTokenReducer;
