import { validateAmounts } from '../validation'
import { getTokenBalance, calculateAmounts } from '../misc'
import { formatError } from '../format'
import { Intent } from '../../const'

const onAmountChange = (wallet, pairData, select) => (amountString) => {
  const {
    setSwapAmountsWallet,
    setAlertWallet,
    updateSwapWallet,
    setSwapAmountsBaseUnitsWallet,
  } = wallet.dispatch
  const {
    swap: { fromToken, toToken, reverse },
    balances,
  } = wallet.state
  const amount = amountString

  if (amount === '') {
    setSwapAmountsWallet(amount, '')
    return
  }

  let price

  try {
    price = pairData.helper.getPriceLevel(amountString, reverse)
  } catch (err) {
    setAlertWallet(select, formatError(err), Intent.WARNING)
    return
  }

  const [fromTokenAmount, toTokenAmount] = calculateAmounts(amount, price, reverse, select)
  const balance = getTokenBalance(fromToken.symbol, balances)

  try {
    validateAmounts(fromToken, toToken, fromTokenAmount, toTokenAmount, balance)
    setAlertWallet(select, null, null)
  } catch (err) {
    setAlertWallet(select, formatError(err), Intent.DANGER)
  }

  updateSwapWallet({ lastUserInput: select })
  setSwapAmountsWallet(fromTokenAmount, toTokenAmount, price)
  setSwapAmountsBaseUnitsWallet(null, null, price)
}

export default onAmountChange
