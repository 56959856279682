import BigNumber from 'bignumber.js'

import { AMOUNT_ERROR } from '../../const'

const isBalanceSufficient = (token, availableBalance) => (amount) => {
  const baseUnitsAmount = new BigNumber(amount).times(new BigNumber(10)
    .pow(token.decimals))

  if (baseUnitsAmount.lt(new BigNumber(availableBalance))) {
    return amount
  }
  throw new Error(`${AMOUNT_ERROR.IS_NOT_BALANCE} ${token.symbol}`)
}

export default isBalanceSufficient
