import _defineProperty from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import omit from 'lodash/omit';

var onSubscriptionMsg = function onSubscriptionMsg(err, event, subs) {
  try {
    var msg = JSON.parse(event.data);

    if (msg.subscribe && msg.success) {
      return _objectSpread({}, subs, _defineProperty({}, msg.subscribe, _objectSpread({}, msg.request)));
    }

    if (msg.unsubscribe && msg.success) {
      return omit(subs, [msg.unsubscribe]);
    }

    return subs;
  } catch (e) {
    return subs;
  }
};

export default onSubscriptionMsg;