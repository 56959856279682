import React from 'react';
import styled from 'styled-components';
import { Button, Box } from 'grommet';
import actions from 'store/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CUSTOM_TIMEFRAMES } from '../../../const/tv';

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  border: 1px solid #00739d;
  color: white;
  border-radius: 2px;
  background: #00739d;
  height: 25px;
  font-size: 12px;
  line-height: 12px;
  opacity: ${props => {
    return props.isSelected ? '1' : '0.5';
  }};
`;

// const StyledSelect = styled(Select)`
//   /* min-width: 200px; */
// `;

// const intervalSelectOptions = CUSTOM_TIMEFRAMES.bitfinex.map(timeFrame => {
//   return timeFrame.text;
// });

const TvControls = ({ setChart, chart }) => {
  return (
    <Box direction="row">
      <Box>
        <ControlsWrapper>
          {CUSTOM_TIMEFRAMES.bitfinex.map(timeFrame => {
            return (
              <StyledButton
                isSelected={timeFrame.text === chart.timeFrame.text}
                key={timeFrame.text}
                gap="xxsmall"
                margin="xxsmall"
                label={timeFrame.text.toUpperCase()}
                onClick={() => {
                  setChart({ timeFrame });
                }}
              />
            );
          })}
        </ControlsWrapper>
      </Box>
      {/* <Box>
        <StyledSelect
          // dropHeight="small"
          plain
          margin="0px"
          id="select"
          name="select"
          placeholder="Select resolution"
          value={chart.timeFrame.descriptionResolution}
          // valueLabel={chart.timeFrame.descriptionResolution}
          valueKey="resolution"
          labelKey="descriptionResolution"
          options={CUSTOM_TIMEFRAMES.bitfinex}
          onChange={({ option }) => setChart({ timeFrame: option })}
          // {...rest}
        />
      </Box> */}
    </Box>
  );
};

TvControls.propTypes = {
  chart: PropTypes.objectOf(PropTypes.object).isRequired,
  setChart: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    chart: state.chart
  };
};

const mapDispatchToProps = dispatch => ({
  setChart: setting => {
    dispatch(actions.chart.set(setting));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TvControls));
