import PropTypes from 'prop-types'
import React from 'react'
import {
  Button, Intent, Tooltip, Position, Spinner,
} from '@blueprintjs/core'
import { ThemeProvider } from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import { withTheme } from '../../utils/misc'
import { defTheme, WITH_THEME } from './ConfirmDialog.constants'
import { SWAP_STATUS, SWAP_ERROR } from '../../const'

const { DIALOG_CANCEL_BUTTON, DIALOG_CONFIRM_BUTTON } = WITH_THEME

const CancelButton = withTheme(Button, DIALOG_CANCEL_BUTTON)`
`

const ConfirmButton = withTheme(Button, DIALOG_CONFIRM_BUTTON)`
`

const ConfirmDialogButtons = ({
  statusProgress, onConfirm, onClose, onCancel, style,
}) => {
  const theme = { ...defTheme, ...style }

  const {
    SUCCESS, SUBMISSION_ERROR, SETTLEMENT_PENDING, LOCK_PENDING,
  } = SWAP_STATUS

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        {statusProgress.length === 1 && (
          <Row middle='xs' around='xs'>
            <Col xs={12} sm>
              <Row center='xs'>
                <Col>
                  <ConfirmButton large text='CONFIRM' minimal onClick={onConfirm} />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm>
              <Row center='xs'>
                <Col>
                  <CancelButton large text='BACK' minimal onClick={onCancel} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {(statusProgress.includes(LOCK_PENDING) || statusProgress.includes(SUBMISSION_ERROR)) && (
          <Row middle='xs' around='xs'>
            <Col xs={12} sm>
              <Row center='xs'>
                <Col>
                  <Tooltip
                    content={(
                      <div>
                        You can close this dialog or wait for confirmation.
                        <br />
                        Your wallet will prompt to confirm the next steps, when
                        required.
                      </div>
                    )}
                    position={Position.BOTTOM}
                    disabled={[SUCCESS, SUBMISSION_ERROR].includes(
                      statusProgress[statusProgress.length - 1],
                    )}
                  >
                    <CancelButton large text='CLOSE' minimal onClick={onClose} />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {!statusProgress.includes(LOCK_PENDING)
          && !statusProgress.includes(SETTLEMENT_PENDING)
          && !statusProgress.includes(SUBMISSION_ERROR)
          && !statusProgress.includes(SUCCESS)
          && !statusProgress.includes(SWAP_ERROR.UNLOCK)
          && statusProgress.length !== 1 && (
            <Row middle='xs' around='xs'>
              <Col xs={12} sm>
                <Row center='xs'>
                  <Col>
                    <Tooltip
                      content='Please wait for the swap to complete.'
                      position={Position.BOTTOM}
                    >
                      <CancelButton
                        large
                        text={<Spinner intent={Intent.PRIMARY} size={35} />}
                        minimal
                        onClick={() => {}}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
        )}
      </React.Fragment>
    </ThemeProvider>
  )
}

ConfirmDialogButtons.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.array),
  statusProgress: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ConfirmDialogButtons.defaultProps = {
  onCancel: () => null,
  onConfirm: () => null,
  onClose: () => null,
  style: {},
}

export default React.memo(ConfirmDialogButtons)
