import omit from 'lodash/omit';

var removeTradingPair = function removeTradingPair(pairs, channel, candlesData) {
  if (pairs[channel]) {
    return [omit(pairs, channel), omit(candlesData, channel)];
  }

  return [omit(pairs, channel), candlesData];
};

export default removeTradingPair;