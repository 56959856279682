import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Classes } from '@blueprintjs/core'
import { withTheme } from '../../utils/misc'
import { defTheme, WITH_THEME } from './Ticker.constants'

const { CONTAINER } = WITH_THEME

const StyledDiv = withTheme.styled.div`
  ${props => props.theme[CONTAINER]}
  width: 200px;
`

const Ticker = ({
  pair, lastPrice, style,
}) => {
  const compTheme = { ...defTheme, ...style }

  const [baseToken, quoteToken] = pair

  return (
    <ThemeProvider theme={compTheme}>
      <StyledDiv className={(lastPrice && baseToken && quoteToken) || Classes.SKELETON}>
        {`1 ${baseToken} = ${lastPrice.toFixed(5)} ${quoteToken}`}
      </StyledDiv>
    </ThemeProvider>
  )
}

Ticker.propTypes = {
  pair: PropTypes.arrayOf(PropTypes.string).isRequired,
  lastPrice: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.array),
}

Ticker.defaultProps = {
  lastPrice: null,
  style: {},
}

export default React.memo(Ticker)
