import ExABI from './zeroExExchangeV2'

const monitorTransaction = async (
  web3,
  efx,
  orderHash,
  orderId = null,
  cbFn = () => null,
  nonce = null,
  signature = null,
) => {
  const ExchangeContract = new web3.eth.Contract(ExABI, efx.config['0x'].exchangeAddress)

  const sub = ExchangeContract.events
    .allEvents()
    .on('data', (event) => {
      if (event.returnValues.orderHash === orderHash) {
        sub.unsubscribe()
        cbFn(null, event)
      }
    })
    .on('error', (err) => {
      cbFn(err, orderHash)
    })

  if (orderId) {
    const td = setInterval(async () => {
      try {
        const activeOrders = await efx.getOrder(orderId, nonce, signature)

        const isActive = activeOrders.some((order => order.id === orderId))

        if (!isActive) {
          const orders = await efx.getOrdersHist(null, null, nonce, signature)

          orders
            .slice(0, 5)
            .filter(order => order.id === orderId)
            .forEach((order) => {
              if (order.status.includes('EXECUTED') || order.status.includes('PARTIALLY')) {
                cbFn(null, order)
                clearInterval(td)
              }
              if (order.status.includes('CANCELED')) {
                sub.unsubscribe()
                cbFn(null, order)
                clearInterval(td)
              }
            })
        }
      } catch (err) {
        clearInterval(td)
        cbFn(err, orderHash)
      }
    }, 5000)
  }

  return sub
}

export default monitorTransaction
