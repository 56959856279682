import { createAction } from 'redux-actions'
import {
  WALLET_UPDATE,
  WALLET_SWAP_PAIR_UPDATE,
  WALLET_SWAP_PAIR_AMOUNTS_UPDATE,
  WALLET_SWAP_PAIR_AMOUNTS_BASE_UNITS_UPDATE,
  WALLET_RESET,
  WALLET_BALANCES_UPDATE,
  WALLET_ALERT_SET,
  WALLET_BALANCES_RESET,
  WALLET_SWAP_STATUS_UPDATE,
  WALLET_SWAP_UPDATE,
  WALLET_SELECTED_ACCOUNT_SET,
} from './const'
import { initWalletState } from './walletReducer'

export const updateBalancesWallet = createAction(WALLET_BALANCES_UPDATE, (symbol, balances) => ({
  [symbol]: balances,
}))

export const resetBalancesWallet = createAction(WALLET_BALANCES_RESET, symbols =>
  symbols.reduce((acc, symbol) => {
    const bal = {
      [symbol]: {
        token: null,
        wrapper: null,
        tokenUnits: null,
        wrapperUnits: null,
        unlocking: false,
        status: null,
      },
    }
    return { ...acc, ...bal }
  }, {}))

export const resetWallet = createAction(WALLET_RESET, () => {
  const {
    accounts, selectedAccount, source, error, status, statusMsg, balances,
  } = initWalletState

  return {
    accounts,
    selectedAccount,
    source,
    error,
    status,
    statusMsg,
    balances,
  }
})

export const updateWallet = createAction(WALLET_UPDATE)

export const setAlertWallet = createAction(WALLET_ALERT_SET, (field, msg = null, type = null) => {
  switch (field) {
    case 'from':
      return { from: { msg, type } }
    case 'to':
      return { to: { msg, type } }
    case 'resetSelect':
      return {
        to: { msg: null, type: null },
        from: { msg: null, type: null },
      }
    case 'wallet':
      return { wallet: { msg, type } }
    case 'swap':
      return { swap: { msg, type } }
    default:
      return { other: { msg, type } }
  }
})

export const setSwapPairWallet = createAction(
  WALLET_SWAP_PAIR_UPDATE,
  (fromToken, toToken, pair) => {
    let reverse = false
    if (fromToken.symbol === pair[1]) {
      reverse = true
    }
    return {
      fromToken,
      toToken,
      pair,
      reverse,
    }
  },
)

export const setSelectedAccountWallet = createAction(
  WALLET_SELECTED_ACCOUNT_SET,
  (address, path = null) => ({
    address,
    path,
  }),
)

export const updateSwapWallet = createAction(WALLET_SWAP_UPDATE)

export const setSwapStatusWallet = createAction(WALLET_SWAP_STATUS_UPDATE, status => ({
  status,
}))

export const setSwapAmountsWallet = createAction(
  WALLET_SWAP_PAIR_AMOUNTS_UPDATE,
  (fromTokenAmount, toTokenAmount, price) => ({
    fromTokenAmount,
    toTokenAmount,
    price,
  }),
)

export const setSwapAmountsBaseUnitsWallet = createAction(
  WALLET_SWAP_PAIR_AMOUNTS_BASE_UNITS_UPDATE,
  (fromTokenAmountBaseUnits, toTokenAmountBaseUnits, price) => ({
    fromTokenAmountBaseUnits,
    toTokenAmountBaseUnits,
    price,
  }),
)
