import conf from '../../env';

const getConfigEfx = async () => {
  const url = `${conf.api}/trading/r/get/conf`;

  const response = await fetch(url, { method: 'POST' });

  return response.json();
};

export default getConfigEfx;
