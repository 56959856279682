import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import tvcd from 'tvcd/src/tvcd';
import PropTypes from 'prop-types';
import TradingViewChart from '../TradingViewChart';
import tvData from '../../../services/tvData';
import { DEFAULT_TRADING_VIEW_OPTIONS, TIMEFRAMES } from '../../../const/tv';

const StyledContainer = styled.div`
  height: 100%;
`;

const TIME_FRAME = '1m';

const SELECTED_EXCHANGE = 'BitFinex';

const TradingViewContainer = ({ symbols, onChartReady }) => {
  const exchangeService = useRef(tvcd('bitfinex'));
  const options = {
    TIME_FRAME: '1',
    interval: '1',
    datafeed: tvData(exchangeService.current, SELECTED_EXCHANGE, symbols),
    symbol: `${symbols[0]}/${symbols[1]}`,
    time_frames: TIMEFRAMES[SELECTED_EXCHANGE.toLowerCase()],
    debug: false
  };

  const TradinViewOptions = { ...DEFAULT_TRADING_VIEW_OPTIONS, ...options };

  useEffect(() => {
    exchangeService.current.addTradingPair([symbols[0], symbols[1]], {
      timeFrame: TIME_FRAME
    });

    return () => {
      console.log('umounted');
    };
  }, [symbols]);

  return (
    <StyledContainer>
      {symbols && SELECTED_EXCHANGE && (
        <TradingViewChart
          key="exchange.exchangeName"
          exchangeService={exchangeService.current}
          TradinViewOptions={TradinViewOptions}
          onChartReady={widget => {
            onChartReady(widget);
          }}
        />
      )}
    </StyledContainer>
  );
};

TradingViewContainer.propTypes = {
  symbols: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChartReady: PropTypes.func
};

TradingViewContainer.defaultProps = {
  onChartReady: () => {}
};

function areEqual(prevProps, nextProps) {
  return prevProps.symbols.toString() === nextProps.symbols.toString();
}

export default React.memo(TradingViewContainer, areEqual);
