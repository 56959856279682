import PropTypes from 'prop-types';
import React from 'react';
import CryptoIcon, { ICON_COLORS } from '../CryptoIcon';
import { WITH_THEME } from './TokensSelect.constants';
import { withTheme } from '../../utils/misc';
import { default as ColorTokenIcon } from '../../../../atoms/TokenIcon';

const StyledIcon = withTheme(CryptoIcon, WITH_THEME.TOKEN_ICON)``;

const TokenIcon = ({
  symbol,
  className,
  size,
  color,
  withText,
  onClick,
  isImg
}) => (
  <div>
    {isImg ? (
      <ColorTokenIcon symbol={symbol} size={size} asText={symbol} />
    ) : (
      <StyledIcon
        symbol={symbol}
        size={size}
        withText={withText}
        color={color}
        className={className}
        onClick={onClick}
      />
    )}
  </div>
);

TokenIcon.propTypes = {
  className: PropTypes.string,
  symbol: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
  withText: PropTypes.bool,
  isImg: PropTypes.bool
};

TokenIcon.defaultProps = {
  className: null,
  size: 32,
  color: ICON_COLORS.BLACK,
  withText: false,
  isImg: false,
  onClick: () => null
};

export default React.memo(TokenIcon);
