import { WALLETS } from '../../../components/WalletsBox/WalletsBox.constants'
import { LEDGER_ERROR } from '../../../const/errors'
import connectLedger from './utils/connectLedger'
import unsubscribe from './utils/unsubscribe'
import monitorNewBlock from './utils/monitorNewBlock'
import getEthBalancesWithWei from './utils/getEthBalancesWithWei'
import { getTokensBalances, web3Wrapper } from '../../web3'
import listAccounts from './utils/listAccounts'
import initHelper from '../initHelper'
import { ERC20Abi } from '../../../const'

const helperLedger = () => {
  const source = WALLETS.LEDGER
  let ERC20Contract
  let networkId
  let subNewBlock
  let web3
  let path = 'live'

  return {
    getWeb3: () => web3,
    init: async ({ onWalletInteraction }) => {
      let accountsWithBalances = []

      try {
        web3 = await connectLedger(onWalletInteraction)

        const accounts = await listAccounts(path, 0, 5)

        accountsWithBalances = await getEthBalancesWithWei(web3, accounts)

        networkId = await web3.eth.net.getId()

        if (!accounts.length) {
          throw Error(LEDGER_ERROR.IS_LOCKED)
        }

        ERC20Contract = new web3.eth.Contract(ERC20Abi)
      } catch (err) {
        throw err
      }

      return [accountsWithBalances, networkId]
    },

    unsubscribe: async () => unsubscribe(subNewBlock),

    subscribe: async (onAccounts, onNewBlock) => {
      try {
        if (subNewBlock) {
          await subNewBlock.unsubscribe()
        }
      } catch (err) {
        return err
      }

      subNewBlock = monitorNewBlock(web3, onNewBlock)

      return { unsubscribe: () => unsubscribe(subNewBlock) }
    },

    getAccounts: async (addressPath, start, n) => {
      path = addressPath

      const accounts = await listAccounts(path, start, n)

      const accountsWithBalances = await getEthBalancesWithWei(web3, accounts)

      return accountsWithBalances
    },

    getBalances: async (account, fromToken, toToken) =>
      getTokensBalances(account, fromToken, toToken, web3, ERC20Contract),

    getSource: () => ({ source }),

    close: async () => {
      web3Wrapper.reset()
      unsubscribe(subNewBlock)
    },
  }
}

export default () => Object.assign({}, initHelper(), helperLedger())
