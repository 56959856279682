import moment from 'moment';
var formatter = {
  tradingview: function tradingview(data) {
    var T = data.T,
        O = data.O,
        C = data.C,
        H = data.H,
        L = data.L,
        V = data.V;
    return {
      time: moment(T).startOf('minute').valueOf(),
      open: O,
      close: C,
      high: H,
      low: L,
      volume: V
    };
  },
  default: function _default(data) {
    var T = data.T,
        O = data.O,
        C = data.C,
        H = data.H,
        L = data.L,
        V = data.V;
    return {
      time: moment(T).startOf('minute').valueOf(),
      open: O,
      close: C,
      high: H,
      low: L,
      volume: V
    };
  }
};
export default formatter;