import _slicedToArray from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var formatter = {
  tradingview: function tradingview(data) {
    var _data = _slicedToArray(data, 6),
        time = _data[0],
        open = _data[1],
        close = _data[2],
        high = _data[3],
        low = _data[4],
        volume = _data[5];

    return {
      time: time,
      open: open,
      close: close,
      high: high,
      low: low,
      volume: volume
    };
  },
  default: function _default(data) {
    var _data2 = _slicedToArray(data, 6),
        time = _data2[0],
        open = _data2[1],
        close = _data2[2],
        high = _data2[3],
        low = _data2[4],
        volume = _data2[5];

    return {
      time: time,
      open: open,
      close: close,
      high: high,
      low: low,
      volume: volume
    };
  }
};
export default formatter;