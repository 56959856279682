import { WALLETS } from '../../components/WalletsBox/WalletsBox.constants'
import helperMetamask from './metamask'
import helperPortis from './portis'
import helperLedger from './ledger'

const wallets = {
  [WALLETS.LEDGER]: helperLedger,
  [WALLETS.METAMASK]: helperMetamask,
  [WALLETS.PORTIS]: helperPortis,

}
export default wallets
