import { handleActions } from 'redux-actions'
import {
  addChannelWs, removeChannelWs, updateTickerWs, updateBooksWs, resetBooksWs,
} from './tickerActions'
import { omit } from '../../utils/misc'
import { updateOrderBooks } from '../../utils/dex'

export const initTickerState = {
  tickers: {},
  channels: {},
  books: {},
}

const tickerReducer = handleActions({
  [updateTickerWs]: (state, action) => {
    const { pair } = state.channels[action.payload.chanId]

    return {
      ...state,
      tickers: { ...state.tickers, [pair]: { ...action.payload, pair } },
    }
  },

  [updateBooksWs]: (state, action) => {
    const chainIds = Object.keys(action.payload.msg)

    let newBooks = {}

    chainIds.forEach((chanId) => {
      if (state.channels[chanId]) {
        const { pair } = state.channels[chanId]
        const msgs = action.payload.msg[chanId]
        newBooks = { [pair]: { books: {}, chanId: Number(chanId), pair } }

        newBooks[pair].books = msgs.reduce((acc, msg) => updateOrderBooks(
          msg, acc,
        ), ((state.books[pair] && state.books[pair].books) || {}))
      }
    })

    return {
      ...state,
      books: { ...state.books, ...newBooks },
    }
  },

  [resetBooksWs]: (state, action) => {
    const { channel } = state.channels[action.payload]

    if (channel === 'book') {
      return {
        ...state,
        books: {},
      }
    }
    return {
      ...state,
    }
  },

  [addChannelWs]: (state, action) => ({
    ...state,
    channels: { ...state.channels, ...action.payload },
  }),

  [removeChannelWs]: (state, action) => ({
    ...state,
    channels: omit(state.channels, [action.payload]),
  }),
},
initTickerState)

export default tickerReducer
