import ChartLine from 'components/atoms/ChartLine/ChartLine';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const NoChartData = styled.div`
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  margin: auto;
  text-align: center;
  height: 80px;
`;

const ChartWrapper = styled.div`
  height: 80px;
`;

const mapData = arr =>
  arr
    .map(el => ({
      x: new Date(el.time),
      y: el.close
    }))
    .filter(point => {
      const oneDayAgo = moment()
        .subtract(24, 'hours')
        .valueOf();
      return moment(point.x).valueOf() >= oneDayAgo;
    });

const TokenLineChart = ({ data, chartWidth }) => {
  const mappedData = useMemo(() => mapData(data), [data]);

  if (data.length < 2) {
    return <NoChartData>No data</NoChartData>;
  }

  const chartData = {
    series: [
      {
        data: mappedData
      }
    ]
  };

  return (
    <ChartWrapper>
      <ChartLine data={chartData} chartWidth={chartWidth} />
    </ChartWrapper>
  );
};

TokenLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

TokenLineChart.defaultProps = {
  chartWidth: '332'
};

export default React.memo(TokenLineChart);
