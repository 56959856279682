const monitorNewBlock = (
  web3, cbFn,
) => {
  let subscription
  try {
    subscription = web3.eth.subscribe('newBlockHeaders', (err) => {
      if (err) {
        cbFn(err, null)
      }
    })
      .on('data', (blockHeader) => {
        cbFn(null, blockHeader)
      })
      .on('error', (err) => {
        if (err) {
          cbFn(err, null)
        }
      })
  } catch (err) {
    cbFn(err, null)
  }

  return subscription
}

export default monitorNewBlock
