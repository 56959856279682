// Add your own proxy service if needed
const corsProxy = '';
export const api = `${corsProxy}https://test.ethfinex.com/trustless/v1`;
export const wsUrl = 'wss://test.ethfinex.com/ws/2';
export const wsPingTime = 2000;
export const wsTimeout = 5000;
export const wsRetryDelay = 2000;
export const wsMaxRetry = Infinity;

// Add your own Portis App Id
export const portisAppId = 'eaee8f7f-b131-4f98-88ad-8ed4b24db60b';
export const KOVAN = 'kovan';
export const MAINNET = 'mainnet';
export const network = { id: 42, name: KOVAN };
export const providerUrl =
  'https://eth-kovan.alchemyapi.io/jsonrpc/x-tRjho6wz229YCx8pftqHQm3vPxhfYA';
export const rpcUrlWeb3 =
  'https://kovan.infura.io/v3/564c354d54514e138db390e6576bde5f';
export const wsUrlWeb3 =
  'wss://kovan.infura.io/ws/v3/564c354d54514e138db390e6576bde5f';
export const efxNonceExpiration = 3600 * 3;

export const zeroExContractAddress =
  '0x35dD2932454449b14Cee11A94d3674a936d5d7b2';

export const assetsUrl = 'https://www.bitfinex.com';

export const quoteTokens = ['ETH', 'USD'];

export const pair = ['ETH', 'USD'];

export const fromToken = {
  decimals: 18,
  wrapperAddress: '0x479885b7494ab031456a0a56c672a7d6c09e4eaa',
  minOrderSize: 0.1,
  symbol: 'ETH'
};

export const toToken = {
  decimals: 6,
  wrapperAddress: '0x07ba0ff5b8a7a005f554527783ed529c532ba07b',
  tokenAddress: '0xd92eee71478e115fcc5342fa44242e7896b8af3b',
  minOrderSize: 10,
  settleSpread: 0,
  symbol: 'USD'
};
