import PropTypes from 'prop-types'
import React from 'react'
import {
  Dialog, H1, Icon, H6, ProgressBar, Intent, Checkbox,
} from '@blueprintjs/core'
import { ThemeProvider } from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import { withTheme, withMemo } from '../../utils/misc'
import { defTheme, WITH_THEME } from './ConfirmDialog.constants'
import CryptoIcon from '../CryptoIcon'
import Ticker from '../Ticker'
import ConfirmDialogButtons from './ConfirmDialog.buttons'
import SimpleAlert from '../SimpleAlert'
import useTransactionProgress from '../../hooks/useTransactionProgress'

const {
  CONTAINER, DIALOG, DIALOG_TITLE, DIALOG_CONTENT,
} = WITH_THEME

const Div = withTheme.styled.div`
  ${props => props.theme[CONTAINER]}
`

const StyledOverlay = withTheme(Dialog, DIALOG)`
`

const StyledH1 = withTheme(H1, DIALOG_TITLE)`
`

const WalletAddress = withTheme.styled.div`
  padding-top:5px;
  padding-bottom: 25px;
`

const TokenAmount = withTheme.styled.div`
  font-size: 1.5em;
`

const IconDiv = withTheme.styled.div`
  @media only screen and (min-width : 768px) {
      margin-top: 30px;
  }
`

const StatusDiv = withTheme.styled.div`
  padding-top: 5px;
  padding-bottom: 2px;
`

const TermsDiv = withTheme.styled.div`
  text-align: center;
  padding-top: 5px;
  padding-bottom: 2px;
`

const ProgressDiv = withTheme.styled.div`
  padding-top: 5px;
  padding-bottom: 2px;
`

const TickerContainer = withTheme.styled.div`
  padding-top: 10px;
`

const WrapperContent = withTheme.styled.div`
${props => props.theme[DIALOG_CONTENT]}
`

const StyledLabel = withTheme.styled(H6)`
  text-transform: uppercase;
  font-size: 12px;
`

const ConfirmDialog = ({
  accountAddress,
  alertIntentTransaction,
  alertTextTransaction,
  alertIntentWallet,
  alertTextWallet,
  fromTokenAmount,
  fromTokenSymbol,
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  pair,
  price,
  style,
  statusProgress,
  toTokenAmount,
  toTokenSymbol,
}) => {
  const theme = { ...defTheme, ...style }

  const currentStatus = statusProgress[statusProgress.length - 1]

  const [progress] = useTransactionProgress(statusProgress)

  return (
    <ThemeProvider theme={theme}>
      <Div theme={theme}>
        <StyledOverlay
          canOutsideClickClose={false}
          canEscapeKeyClose={false}
          isOpen={isOpen}
          usePortal={false}
          onClose={onCancel}
        >
          <WrapperContent>
            <Row around='xs'>
              <Col xs={12} sm>
                <StyledH1>SWAP</StyledH1>
                {alertTextTransaction && (
                  <Row between='xs'>
                    <Col xs={12}>
                      <SimpleAlert
                        text={alertTextTransaction}
                        visible
                        type={alertIntentTransaction}
                        delay={10000}
                      />
                    </Col>
                  </Row>
                )}
                {alertTextWallet && (
                  <Row between='xs'>
                    <Col xs={12}>
                      <SimpleAlert
                        text={alertTextWallet}
                        visible
                        type={alertIntentWallet}
                        delay={10000}
                      />
                    </Col>
                  </Row>
                )}
                <WalletAddress>
                  <Row around='xs'>
                    <Col xs={12}>Your Wallet</Col>
                    <Col xs={12}>{accountAddress}</Col>
                  </Row>
                </WalletAddress>
                <Row around='xs'>
                  <Col xs={12} sm>
                    <Row center='xs'>
                      <Col xs={12}>
                        <StyledLabel>From</StyledLabel>
                      </Col>
                      <Col xs={12}>
                        <Row between='xs'>
                          <Col>
                            <CryptoIcon symbol={fromTokenSymbol} />
                          </Col>
                          <Col>
                            <TokenAmount>
                              {`${parseFloat(fromTokenAmount)} ${fromTokenSymbol}`}
                            </TokenAmount>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row center='xs' middle='xs'>
                      <Col xs={12}>
                        <IconDiv>
                          <Icon icon='double-chevron-right' iconSize={30} />
                        </IconDiv>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm>
                    <Row center='xs'>
                      <Col xs={12}>
                        <StyledLabel>To</StyledLabel>
                      </Col>
                      <Col xs={12}>
                        <Row between='xs'>
                          <Col>
                            <CryptoIcon symbol={toTokenSymbol} />
                          </Col>
                          <Col>
                            <TokenAmount>
                              {`~${parseFloat(toTokenAmount)} ${toTokenSymbol}`}
                            </TokenAmount>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TickerContainer>
                      <Ticker pair={pair} lastPrice={price} />
                    </TickerContainer>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <ProgressDiv>
                      <ProgressBar
                        animate={false}
                        stripes={false}
                        value={progress}
                        intent={Intent.PRIMARY}
                      />
                    </ProgressDiv>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <StatusDiv>{currentStatus}</StatusDiv>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <TermsDiv>
                      <Checkbox checked label='I do accept terms and conditions.' />
                    </TermsDiv>
                  </Col>
                </Row>
              </Col>
            </Row>
          </WrapperContent>
          <ConfirmDialogButtons
            statusProgress={statusProgress}
            onConfirm={onConfirm}
            onClose={onClose}
            onCancel={onCancel}
            style={style}
          />
        </StyledOverlay>
      </Div>
    </ThemeProvider>
  )
}

ConfirmDialog.propTypes = {
  alertIntentTransaction: PropTypes.oneOf([
    Intent.NONE,
    Intent.PRIMARY,
    Intent.SUCCESS,
    Intent.WARNING,
    Intent.DANGER,
  ]),
  alertTextTransaction: PropTypes.string,
  alertIntentWallet: PropTypes.oneOf([
    Intent.NONE,
    Intent.PRIMARY,
    Intent.SUCCESS,
    Intent.WARNING,
    Intent.DANGER,
  ]),
  alertTextWallet: PropTypes.string,
  accountAddress: PropTypes.string,
  fromTokenAmount: PropTypes.string.isRequired,
  fromTokenSymbol: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  pair: PropTypes.arrayOf(PropTypes.string).isRequired,
  price: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.array),
  toTokenAmount: PropTypes.string.isRequired,
  toTokenSymbol: PropTypes.string.isRequired,
  statusProgress: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ConfirmDialog.defaultProps = {
  alertIntentTransaction: Intent.NONE,
  alertTextTransaction: null,
  alertIntentWallet: Intent.NONE,
  alertTextWallet: null,
  accountAddress: null,
  onCancel: () => null,
  onConfirm: () => null,
  onClose: () => null,
  price: null,
  style: {},
}

export default withMemo(ConfirmDialog, ['statusProgress', 'alertTextTransaction', 'alertTextWallet'])
