import { v3 } from 'murmurhash'

const parseTokenRegistry = (response) => {
  const { tokenRegistry, exchangeSymbols } = response['0x']

  const tokenRegistryWithSymbol = Object.keys(tokenRegistry).reduce((acc, token) => {
    acc[token] = { ...tokenRegistry[token], symbol: token }
    return acc
  }, {})

  let pairsHash = {}

  const exchangePairs = exchangeSymbols.map((symbol) => {
    const pair = symbol.match(/([A-Z]{1,3})/g)

    let key = v3(pair.join(''))

    const hash = {
      [key]: {
        pair,
        reverse: false,
      },
    }

    const reversePair = [...pair].reverse()

    key = v3(reversePair.join(''))

    const reverseHash = {
      [key]: {
        pair,
        reverse: true,
      },
    }

    pairsHash = { ...pairsHash, ...hash, ...reverseHash }

    return [...pair]
  }).sort()

  return {
    ...response['0x'], tokenRegistry: tokenRegistryWithSymbol, exchangePairs, pairsHash,
  }
}

export default parseTokenRegistry
