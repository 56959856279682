import { createAction } from 'redux-actions'
import {
  DEX_ALERT_TRANSACTION_SET,
  DEX_API_CONF_UPDATE,
  DEX_AUTH_SIGNATURES_RESTORE,
  DEX_AUTH_SIGNATURES_UPDATE,
  DEX_STATUS_TRANSACTION_SET,
  DEX_TRANSACTIONS_RESTORE,
  DEX_TRANSACTION_ADD,
  DEX_TRANSACTION_DELETE,
  DEX_TRANSACTION_UPDATE,
  DEX_VALUES_TRANSACTION_SET,
} from './const'

export const updateApiConfDex = createAction(DEX_API_CONF_UPDATE)

export const addTransaction = createAction(
  DEX_TRANSACTION_ADD,
  (hash, tData) => ({
    [hash]: { ...tData },
  }),
)

export const deleteTransation = createAction(DEX_TRANSACTION_DELETE)

export const restoreTransactions = createAction(DEX_TRANSACTIONS_RESTORE)

export const updateTransaction = createAction(
  DEX_TRANSACTION_UPDATE,
  (hash, tData) => ({
    [hash]: { ...tData },
  }),
)

export const updateAuthSignatures = createAction(
  DEX_AUTH_SIGNATURES_UPDATE,
  (account, nonce, signature) => ({
    [account]: { nonce, signature },
  }),
)

export const restoreAuthSignatures = createAction(DEX_AUTH_SIGNATURES_RESTORE)

export const setAlertTransaction = createAction(
  DEX_ALERT_TRANSACTION_SET,
  (hash, msg, type = 'none', time) => ({
    [hash]: { msg, type, time },
  }),
)

export const setStatusTransaction = createAction(
  DEX_STATUS_TRANSACTION_SET,
  (hash, status) => ({
    [hash]: status,
  }),
)

export const setValuesTransaction = createAction(
  DEX_VALUES_TRANSACTION_SET,
  (hash, value) => ({
    [hash]: { ...value },
  }),
)
