var makeTimeChunks = function makeTimeChunks(start, end, chunkSize) {
  var startTime = start;
  var chunks = [];
  var endTime = end;

  for (var i = startTime - 1; i < endTime; i += chunkSize) {
    var fromTime = i + 1;
    var toTime = i + chunkSize > endTime ? end : i + chunkSize;
    chunks.push({
      fromTime: fromTime,
      toTime: toTime
    });
  }

  return chunks;
};

export default makeTimeChunks;