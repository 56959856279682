import unlockTokens from './unlockTokens'

const revertSwap = async (efx, steps) => {
  const runSwapStep = (step) => {
    const [action, args] = step

    switch (action) {
      case 'unlock': {
        return unlockTokens(efx, ...args)
      }
      case 'cancelOrder': {
        return efx.cancelOrder(...args)
      }
      default:
        return null
    }
  }

  const results = steps.reduce(async (previousAsync, step) => {
    await previousAsync
    return runSwapStep(step)
  }, Promise.resolve())

  return results
}

export default revertSwap
