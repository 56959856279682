import tokensData from './tokensData';
import selectedToken from './selectedToken';
import chart from './chart';

const actions = {
  selectedToken,
  tokensData,
  chart
};

export default actions;
