import PropTypes from 'prop-types';
import React from 'react';
import TokensTableRow from 'components/molecules/TokensTableRow';
import { Box } from 'grommet';
import { Scrollbars } from 'react-custom-scrollbars';

const renderTokensTable = tokens =>
  Object.values(tokens)
    .filter(token => token.symbol !== 'USD')
    .map((token, key) => {
      const {
        symbol,
        name,
        meta: { address }
      } = token;
      return (
        <Box
          pad={{ bottom: '2px', right: '5px' }}
          id={`tokens-table-row-${symbol}-${key}`}
          // eslint-disable-next-line react/no-array-index-key
          key={`tokens-table-row-${symbol}-${key}`}
        >
          <TokensTableRow
            tokenSymbol={symbol}
            tokenName={name}
            address={address}
          />
        </Box>
      );
    });

const TokensTable = ({ tokens }) => (
  <Box height="100%" background="white">
    <Scrollbars
      autoHideTimeout={1000}
      renderThumbHorizontal={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            opacity: '0.9'
          }}
        />
      )}
    >
      {renderTokensTable(tokens)}
    </Scrollbars>
  </Box>
);

TokensTable.propTypes = {
  tokens: PropTypes.objectOf(PropTypes.object).isRequired
};

TokensTable.defaultProps = {};

export default React.memo(TokensTable);
