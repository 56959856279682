import styled, { css } from 'styled-components'
import memoizeOne from 'memoize-one'
import React from 'react'

const injectCss = themeName => props => (props.theme && props.theme[themeName]) || ''

const withTheme = (Component, themeName) => CssInJs => styled((props) => {
  const { theme, ...rest } = props
  return <Component {...rest} />
})`
    ${css(CssInJs)}
    ${css(memoizeOne(injectCss)(themeName))}
  `

withTheme.styled = styled

export default withTheme
