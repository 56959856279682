import React, { useEffect } from 'react';
import { Box, Grid } from 'grommet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import actions from 'store/actions';
import { DEFAULT_ENDPOINT } from 'const';
import getDataSetLabels from 'utils/data/getDataSetLabels';
import TokenBarChart from 'components/molecules/TokenBarChart';
import get from 'lodash/get';
import TokenIcon from 'components/atoms/TokenIcon';
import SimpleText from 'components/atoms/SimpleText';

export const mapVolumeData = arr => {
  const mappedData = [];
  const mappedLabels = [];

  arr.forEach(el => {
    mappedData.push(el.volume_usd);
    mappedLabels.push(el.date);
  });
  return { series: [mappedData], labels: mappedLabels };
};

export const mapTransactionsData = arr => {
  const mappedData = [];
  const mappedLabels = [];

  arr.forEach(el => {
    mappedData.push(el.number_of_txns);
    mappedLabels.push(el.date);
  });
  return { series: [mappedData], labels: mappedLabels };
};

const TokenView = ({
  tokenSymbol,
  tokenName,
  dataPeriod,
  fetchTokensTransactions,
  fetchTokensVolume,
  fetchTokensCandles,
  fetchTokensTickers,
  transactionsData,
  volumeData
}) => {
  useEffect(() => {
    const dataSetLabel = getDataSetLabels(dataPeriod);
    // fetchTokensTransactions([tokenSymbol], dataSetLabel.transactions)
    // fetchTokensVolume([tokenSymbol], dataSetLabel.volume)
    // fetchTokensCandles([tokenSymbol], DEFAULT_ENDPOINT.relayName, DEFAULT_ENDPOINT.networkId, '15m')
    // fetchTokensTickers([tokenSymbol], DEFAULT_ENDPOINT.relayName, DEFAULT_ENDPOINT.networkId)
  }, [
    dataPeriod,
    fetchTokensCandles,
    fetchTokensTickers,
    fetchTokensTransactions,
    fetchTokensVolume,
    tokenSymbol
  ]);

  return (
    <Grid
      fill
      rows={['50px', 'flex', 'flex']}
      columns={['flex']}
      areas={[
        { name: 'token-meta', start: [0, 0], end: [1, 0] },
        { name: 'token-volume', start: [0, 1], end: [1, 1] },
        { name: 'token-transactions', start: [0, 2], end: [1, 2] }
      ]}
      gap="small"
    >
      <Box
        gridArea="token-meta"
        direction="row"
        align="center"
        background="light-2"
        pad={{ left: 'xsmall' }}
      >
        <Box margin="xxsmall">
          <TokenIcon symbol={tokenSymbol} />
        </Box>
        <Box margin="xxsmall">
          <SimpleText weight="bold">{tokenSymbol}</SimpleText>
        </Box>
        <Box margin="xxsmall">
          <SimpleText>{tokenName}</SimpleText>
        </Box>
      </Box>
      <Box
        gridArea="token-volume"
        background="white"
        align="center"
        pad={{
          top: 'xxsmall',
          left: 'xxsmall',
          right: 'xxsmall',
          bottom: 'xxsmall'
        }}
      >
        <SimpleText size="small">VOLUME 30 DAY</SimpleText>
        <TokenBarChart data={volumeData} mapFn={mapVolumeData} />
      </Box>

      <Box
        gridArea="token-transactions"
        background="white"
        align="center"
        pad={{
          top: 'xxsmall',
          left: 'xxsmall',
          right: 'xxsmall',
          bottom: 'xxsmall'
        }}
      >
        <SimpleText size="small">TRANSACTIONS 30 DAY</SimpleText>
        <TokenBarChart data={transactionsData} mapFn={mapTransactionsData} />
      </Box>
    </Grid>
  );
};

TokenView.propTypes = {
  tokenSymbol: PropTypes.string.isRequired,
  tokenName: PropTypes.string.isRequired,
  dataPeriod: PropTypes.string.isRequired,
  fetchTokensTransactions: PropTypes.func.isRequired,
  fetchTokensVolume: PropTypes.func.isRequired,
  fetchTokensCandles: PropTypes.func.isRequired,
  fetchTokensTickers: PropTypes.func.isRequired,
  volumeData: PropTypes.arrayOf(PropTypes.object).isRequired,
  transactionsData: PropTypes.arrayOf(PropTypes.object).isRequired
};

TokenView.defaultProps = {};

const mapStatoToProps = (state, ownProps) => {
  const { tokenSymbol } = ownProps;
  const { volume, transactions } = state.tokensData;

  const dataSetLabel = getDataSetLabels(ownProps.dataPeriod);
  return {
    ...ownProps,
    volumeData: get(volume, [tokenSymbol, dataSetLabel.volume], []),
    transactionsData: get(
      transactions,
      [tokenSymbol, dataSetLabel.transactions],
      []
    )
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTokensTransactions: (tokens, dataSet) =>
    dispatch(actions.tokensData.transactions.fetch(tokens, dataSet)),
  fetchTokensVolume: (tokens, dataSet) =>
    dispatch(actions.tokensData.volume.fetch(tokens, dataSet)),
  fetchTokensCandles: (tokens, relayName, networdId, timeFrame) =>
    dispatch(
      actions.tokensData.candles.start(tokens, relayName, networdId, timeFrame)
    ),
  fetchTokensTickers: (tokens, relayName, networdId) =>
    dispatch(actions.tokensData.tickers.start(tokens, relayName, networdId))
});

export default connect(
  mapStatoToProps,
  mapDispatchToProps
)(React.memo(TokenView));
