import PropTypes from 'prop-types';
import React from 'react';
import { MenuItem, Menu, InputGroup } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from 'react-reveal/Fade';
import TokenIcon from './TokensSelect.icon';
import conf from '../../env';
import Footer from './TokensSelect.footer';
import Header from './TokensSelect.header';
import { withTheme } from '../../utils/misc';
import { WITH_THEME } from './TokensSelect.constants';

const {
  SELECT_MENU_FOOTER,
  SELECT_MENU_SEARCH,
  SELECT_MENU,
  SELECT_MENU_CONTAINER
} = WITH_THEME;

const StyledFooter = withTheme.styled.div`
  ${props => props.theme[SELECT_MENU_FOOTER]}
`;

const StyledInputGroup = withTheme(InputGroup, SELECT_MENU_SEARCH)`
`;

const StyledMenu = withTheme(Menu, SELECT_MENU)`
`;

const DIV = withTheme.styled.div`
  ${props => props.theme[SELECT_MENU_CONTAINER]}
  border-radius: 3px;
  margin: -6px;
`;

const Tokens = ({ list, onItemSelect }) => {
  const tokenlist = list.map(token => (
    <MenuItem
      onClick={() => onItemSelect(token)}
      key={`select-item-${token}`}
      icon={<TokenIcon symbol={token} size={24} isImg />}
    />
  ));
  return (
    <div key={`token-list-${list.length}`}>
      <Fade>
        <div>{tokenlist}</div>
      </Fade>
    </div>
  );
};

Tokens.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  onItemSelect: PropTypes.func
};

Tokens.defaultProps = {
  onItemSelect: () => null
};

const MenuItems = ({
  list,
  onItemSelect,
  onItemSearch,
  header,
  footer,
  setOpen
}) => (
  <DIV>
    {header || (
      <Header
        tokens={conf.quoteTokens}
        onClick={onItemSelect}
        setOpen={setOpen}
      />
    )}
    <StyledInputGroup
      large
      leftIcon="search"
      onChange={onItemSearch}
      placeholder="Seach..."
    />
    <StyledMenu>
      <Scrollbars
        autoHeight
        autoHideTimeout={1000}
        renderThumbHorizontal={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              opacity: '0.9'
            }}
          />
        )}
      >
        <Tokens list={list} onItemSelect={onItemSelect} />
      </Scrollbars>
      {footer || (
        <StyledFooter>
          <Footer />
        </StyledFooter>
      )}
    </StyledMenu>
  </DIV>
);

MenuItems.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  footer: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  header: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  onItemSearch: PropTypes.func,
  onItemSelect: PropTypes.func,
  setOpen: PropTypes.func
};

MenuItems.defaultProps = {
  footer: null,
  header: null,
  onItemSearch: () => null,
  setOpen: () => null,
  onItemSelect: () => null
};

export default React.memo(MenuItems);
