import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  Button,
  AnchorButton,
  Tooltip,
  Position,
  Intent,
  Spinner
} from '@blueprintjs/core';

import { Col, Row } from 'react-styled-flexboxgrid';
import { withTheme } from '../../utils/misc';
import {
  WALLET_STATUS,
  WITH_THEME,
  SUPPORTED_WALLETS,
  WALLETS
} from './WalletsBox.constants';
import WalletsContext from './WalletsBox.context';
import WalletsBalances from './WalletsBox.balances';
import FlexDiv from './WalletsBox.flexDiv';
import SimpleAlert from '../SimpleAlert';
import AccountsList from './WalletBox.accounts';

const { PANEL_WALLETS_BUTTON } = WITH_THEME;

const WalletButton = withTheme(Button, PANEL_WALLETS_BUTTON)`
`;

const WrapperButton = withTheme.styled.div`
  && .bp3-button {
        :hover {
          background: none;
      }
`;

const WrapperWalletsBox = withTheme.styled.div`
  min-height: 150px;
  margin-right: auto;
  margin-left: auto;
  max-width: 380px;
  @media (max-width: 400px) {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const WrapperBalances = withTheme.styled.div`
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const WrapperAccount = withTheme.styled.div`
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const WrapperAccountsList = withTheme.styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const WrapperSpinner = withTheme.styled.div`
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const WrapperAlert = withTheme.styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const renderWalletsList = (list, onClick, openPanel) =>
  list.map(wallet => (
    <Col key={`wallet-${wallet}`}>
      <WalletButton
        text={wallet}
        minimal
        large
        onClick={() => onClick(openPanel)(wallet.toLowerCase())}
      />
    </Col>
  ));

const WalletsBoxPanels = ({
  network,
  onResetWallet,
  onClick,
  openPanel,
  closePanel
}) => {
  const {
    isReverseSwap,
    onGetAccounts,
    onSelectAccount,
    onSelectFullBalance,
    onUnlockTokens,
    selectedWallet
  } = useContext(WalletsContext);

  const {
    selectedAccount: { address },
    accounts,
    source
  } = selectedWallet;

  const { fromToken, toToken } = selectedWallet.swap;

  return (
    <WrapperWalletsBox>
      {(selectedWallet.statusMsg.msg || selectedWallet.error.wallet.msg) && (
        <Row between="xs">
          <Col xs={12}>
            <WrapperAlert>
              <SimpleAlert
                text={
                  selectedWallet.error.wallet.msg ||
                  selectedWallet.statusMsg.msg
                }
                type={
                  selectedWallet.error.wallet.type ||
                  selectedWallet.statusMsg.type
                }
                visible
                delay={10000}
              />
            </WrapperAlert>
          </Col>
        </Row>
      )}
      {selectedWallet.status === WALLET_STATUS.LOGGING && (
        <Row between="xs">
          <Col xs={12}>
            <WrapperSpinner>
              <Spinner intent={Intent.PRIMARY} />
            </WrapperSpinner>
          </Col>
        </Row>
      )}
      {selectedWallet.status === WALLET_STATUS.LOGGED_OUT && (
        <Row between="xs">
          <Col xs={12}>
            {selectedWallet.statusMsg.msg && (
              <>
                <Row center='xs'>
                  <Col>
                    <WalletButton
                      text='BACK'
                      minimal
                      large
                      onClick={() => {
                        onResetWallet()
                        closePanel()
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {!selectedWallet.statusMsg.msg && (
              <Row center="xs">
                <Col>
                  {renderWalletsList(SUPPORTED_WALLETS, onClick, openPanel)}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}
      {selectedWallet.status === WALLET_STATUS.LOGGED_IN && (
        <>
          <WrapperAccount>
            <Row>
              <Col xs={12}>
                <FlexDiv flex row key={`account-address-${address}`}>
                  <FlexDiv address>{address}</FlexDiv>
                  <FlexDiv browse>
                    <Tooltip content='Browse on etherscan.io' position={Position.BOTTOM}>
                      <WrapperButton>
                        <AnchorButton
                          href={`https://${
                            network.id === 42 ? `${network.name}.` : ''
                          }etherscan.io/address/${address}`}
                          rightIcon='share'
                          minimal
                          target='_blank'
                          small
                        />
                      </WrapperButton>
                    </Tooltip>
                  </FlexDiv>
                </FlexDiv>
              </Col>
            </Row>
          </WrapperAccount>
          {source === WALLETS.LEDGER && (
            <WrapperAccountsList>
              <Row>
                <Col xs={12}>
                  <AccountsList
                    onSelectAccount={onSelectAccount}
                    accounts={accounts}
                    onGetAccounts={onGetAccounts}
                  />
                </Col>
              </Row>
            </WrapperAccountsList>
          )}
          <WrapperBalances>
            <Row>
              <Col xs={12}>
                <WalletsBalances
                  balances={selectedWallet.balances}
                  fromToken={fromToken}
                  isReverseSwap={isReverseSwap}
                  onSelectFullBalance={onSelectFullBalance}
                  onUnlock={onUnlockTokens}
                  toToken={toToken}
                />
              </Col>
            </Row>
          </WrapperBalances>
        </>
      )}
    </WrapperWalletsBox>
  );
};

WalletsBoxPanels.propTypes = {
  closePanel: PropTypes.func.isRequired,
  network: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  onClick: PropTypes.func,
  onResetWallet: PropTypes.func,
  openPanel: PropTypes.func.isRequired
};

WalletsBoxPanels.defaultProps = {
  onClick: () => null,
  onResetWallet: () => null
};

export default React.memo(WalletsBoxPanels);
