import { createActions } from 'redux-actions';

const selectedToken = createActions(
  {
    BASE_TOKEN: {
      SET: token => {
        console.log(token);
        return token;
      }
    },
    QUOTE_TOKEN: {
      SET: token => {
        console.log(token);
        return token;
      }
    }
  },
  { namespace: '_' }
);

export default { ...selectedToken };
