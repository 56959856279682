import { useEffect, useState, useRef } from 'react'
import { SWAP_STEPS } from '../../const'
import { swapStepUtils } from '../../utils/validation'

const useTransactionProgress = (statusProgress = []) => {
  const [progress, setProgress] = useState(0)

  const stepStatus = useRef(swapStepUtils(SWAP_STEPS))

  useEffect(() => {
    setProgress(stepStatus.current.getProgress(statusProgress))
  }, [statusProgress])

  return [progress]
}

export default useTransactionProgress
