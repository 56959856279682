import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'grommet-icons'
import SimpleText from 'components/atoms/SimpleText'
import { Box, DropButton } from 'grommet'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'

const links = ['About Us', 'Contacts']

const LinkWrapper = styled.div`
  ${props => !props.isMobile && `
    padding-right: 15px;
  `}
`

const Links = ({ isMobile }) => (
  <Box pad={isMobile ? 'small' : 'none'} direction={isMobile ? 'column' : 'row'} flex>
    {links.map(link => (
      <LinkWrapper isMobile={isMobile} key={`top-bar-link-${kebabCase(link)}`}>
        <SimpleText>{link}</SimpleText>
      </LinkWrapper>
    ))}
  </Box>
)

Links.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

Links.defaultProps = {}

const TopBarLinks = ({ isMobile }) => {
  const [open, setOpen] = useState(false)
  return isMobile ? (
    <DropButton
      plain
      icon={<Menu />}
      open={open}
      onClick={() => setOpen(!open)}
      dropContent={<Links isMobile={isMobile} />}
      dropProps={{ align: { top: 'bottom', right: 'right' } }}
    />
  ) : (
    <Links isMobile={isMobile} />
  )
}

TopBarLinks.propTypes = {
  isMobile: PropTypes.bool,
}

TopBarLinks.defaultProps = {
  isMobile: true,
}

export default TopBarLinks
