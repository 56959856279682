const onSearchToken = (cbFn, tokens) => (event) => {
  const filter = event.target.value.toLowerCase()

  const filteredList = Object.keys(tokens).reduce((acc, key) => {
    const { name, symbol } = tokens[key]

    if (name.toLowerCase().includes(filter) || symbol.toLowerCase().includes(filter)) {
      return { ...acc, [key]: tokens[key] }
    }
    return acc
  }, {})

  cbFn(filteredList)
}

export default onSearchToken
