import makeSubs from './makeSubs';
export var subPairs = function subPairs(sendFn, pairs) {
  var msgs = makeSubs(pairs);
  msgs.forEach(function (msg) {
    sendFn(JSON.stringify(msg));
  });
};
export var unsubPairs = function unsubPairs(sendFn, chanIds) {
  chanIds.forEach(function (chanId) {
    var msg = JSON.stringify({
      event: 'unsubscribe',
      chanId: chanId
    });
    sendFn(msg);
  });
};