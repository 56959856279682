import { isMinOrderSize, isPositiveNumber, isBalanceSufficient } from '.'
import { pipe } from '../misc'

const noop = result => result

const validateAmounts = (
  fromToken,
  toToken,
  fromTokenAmount,
  toTokenAmount,
  balance,
  options = {
    isPositiveNumber: true,
    isMinOrderSize: true,
    isBalanceSufficient: true,
  },
) => {
  try {
    pipe(
      options.isPositiveNumber ? isPositiveNumber : noop,
      options.isMinOrderSize ? isMinOrderSize(fromToken) : noop,
      options.isBalanceSufficient ? isBalanceSufficient(fromToken, balance) : noop,
    )(fromTokenAmount)

    pipe(
      options.isPositiveNumber ? isPositiveNumber : noop,
      options.isMinOrderSize ? isMinOrderSize(toToken) : noop,
    )(toTokenAmount)
  } catch (err) {
    throw err
  }
}

export default validateAmounts
