export const updateKeys = (prices) => {
  const newp = {}
  Object.keys(prices).forEach((key) => {
    newp[key] = { ...prices[key] }
  })
  return newp
}


const getBooks = book => ({
  ...book,
  bids: updateKeys(book.bids),
  asks: updateKeys(book.asks),
  psnap: {
    asks: [...book.psnap.asks],
    bids: [...book.psnap.bids],
  },
})

export default getBooks
