import Portis from '@portis/web3'
import { PORTIS_ERROR } from '../../../../const/errors'
import { web3Wrapper } from '../../../web3'

const { LOGIN_DENIED } = PORTIS_ERROR

const connectPortis = (portisAppId, networkName) => {
  try {
    const portis = new Portis(portisAppId, networkName)

    const web3 = web3Wrapper.init(portis.provider, false)

    return [web3, portis]
  } catch (err) {
    throw new Error(LOGIN_DENIED)
  }
}

export default connectPortis
