import { getTokenBalance, calculateAmounts } from '../misc'
import { formatError, toBaseUnitAmount } from '../format'
import { Intent } from '../../const'
import { validateAmounts } from '../validation'

export const switchTokens = (fromToken, toToken, tokenSymbol, pair, reverse) => {
  if (reverse) {
    if (tokenSymbol === pair[1]) {
      return [fromToken, toToken, reverse]
    }
    return [toToken, fromToken, !reverse]
  }
  if (tokenSymbol === pair[1]) {
    return [toToken, fromToken, !reverse]
  }
  return [fromToken, toToken, reverse]
}

const onSelectFullBalance = (wallet, getPriceLevel) => (amountString, tokenSymbol) => {
  const {
    setSwapAmountsWallet,
    setAlertWallet,
    updateSwapWallet,
    setSwapAmountsBaseUnitsWallet,
  } = wallet.dispatch
  const {
    swap: {
      fromToken, toToken, pair, reverse,
    },
    balances,
  } = wallet.state
  // const amount = amountString
  const select = 'from'

  if (amountString === '') {
    setSwapAmountsWallet(amountString, '')
    return
  }

  let price

  const [switchedFromToken, switchedToToken, switchedReverse] = switchTokens(
    fromToken,
    toToken,
    tokenSymbol,
    pair,
    reverse,
  )

  wallet.dispatch.setSwapPairWallet(switchedFromToken, switchedToToken, pair)

  try {
    price = getPriceLevel(amountString, switchedReverse)
  } catch (err) {
    setAlertWallet(select, formatError(err), Intent.WARNING)
    return
  }

  const [fromTokenAmount, toTokenAmount] = calculateAmounts(amountString, price, switchedReverse, select)
  const fromTokenAmountBaseUnits = getTokenBalance(switchedFromToken.symbol, balances)
  const toTokenAmountBaseUnits = toBaseUnitAmount(toTokenAmount, switchedToToken.decimals)

  try {
    validateAmounts(switchedFromToken, switchedToToken, fromTokenAmount, toTokenAmount, null, {
      isPositiveNumber: false,
      isMinOrderSize: true,
      isBalanceSufficient: false,
    })
    setAlertWallet(select, null, null)
  } catch (err) {
    setAlertWallet(select, formatError(err), Intent.DANGER)
  }

  updateSwapWallet({ lastUserInput: select })
  setSwapAmountsWallet(fromTokenAmount, toTokenAmount, price)
  setSwapAmountsBaseUnitsWallet(fromTokenAmountBaseUnits, toTokenAmountBaseUnits, price)
}

export default onSelectFullBalance
