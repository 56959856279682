import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  NumericInput, ControlGroup, FormGroup, Intent, Position, Tooltip, Icon,
} from '@blueprintjs/core'
import { ThemeProvider } from 'styled-components'
import { toDashed, withTheme } from '../../utils/misc'
import TokensMenu from './TokensSelect.menu'
import { defTheme, WITH_THEME } from './TokensSelect.constants'
import useWindowSize from './TokenSelect.utils'

const { CONTAINER, SELECT_NUMERIC_INPUT } = WITH_THEME

const Div = withTheme.styled.div`
      ${props => props.theme[CONTAINER]}
    `

const StyledNumericInput = withTheme(NumericInput, SELECT_NUMERIC_INPUT)`
  input { 
    text-align: right;
  }
`

const StyledToolTip = withTheme(Tooltip)`
  & .bp3-popover-target {
    width: 100%;
  }
`

const StyledTooltipText = withTheme.styled.div`
  display: flex;
  div:nth-child(1) {
    flex-grow: 1;
  };
  div:nth-child(2) {
    margin-left: 10px;
  };
`

const TooltipContent = ({ text, onClickClose }) => (
  <StyledTooltipText>
    <div>
      {text}
    </div>
    <div>
      <Icon icon='cross' onClick={onClickClose} />
    </div>
  </StyledTooltipText>
)

TooltipContent.propTypes = {
  text: PropTypes.string,
  onClickClose: PropTypes.func,
}

TooltipContent.defaultProps = {
  text: null,
  onClickClose: () => null,
}

const filterValue = (cb, prevAmount, setPrevAmount) => (_amountNumber, amountString) => {
  if (amountString === '.') {
    cb('0.')
    setPrevAmount('0.')
    return
  }

  if (!Number.isNaN(Number(amountString))) {
    cb(amountString)
    setPrevAmount(amountString)
  } else {
    cb(prevAmount)
  }
}

const TokensSelect = ({
  alertIntent,
  alertText,
  amountValue,
  footer,
  header,
  inputAmountDisabled,
  label,
  list,
  onAmountChange,
  onItemSearch,
  onItemSelect,
  query,
  selectedToken,
  style,
}) => {
  const [prevAmount, setPrevAmount] = useState()

  const theme = { ...defTheme, ...style }

  const [isOpen, setOpen] = useState(false)

  const [alert, setAlert] = useState(alertText)

  const [windowSize] = useWindowSize()

  const formId = `select-${toDashed(label) || 'no-label'}-${selectedToken}`

  useEffect(() => {
    setAlert(alertText)
    const td = setInterval(() => {
      setAlert(null)
    }, 5000)
    return () => clearInterval(td)
  }, [alertText])

  return (
    <ThemeProvider theme={theme}>
      <Div theme={theme}>
        <FormGroup
          label={label}
          labelFor={formId}
          intent={alertIntent}
        >
          <StyledToolTip
            disabled={!alert}
            content={<TooltipContent text={alert} onClickClose={() => setAlert(null)} />}
            position={Position.BOTTOM}
            isOpen={!!alert}
            intent={Intent.WARNING}
          >
            <ControlGroup id={`${formId}`} vertical={windowSize.innerWidth < 768}>
              <TokensMenu
                list={list}
                onItemSelect={onItemSelect}
                selectedToken={selectedToken}
                header={header}
                footer={footer}
                onItemSearch={onItemSearch}
                query={query}
                isOpen={isOpen}
                setOpen={setOpen}
              />
              <StyledNumericInput
                disabled={inputAmountDisabled}
                intent={alertIntent}
                fill
                large
                placeholder='Enter amount...'
                buttonPosition='none'
                onValueChange={filterValue(onAmountChange, prevAmount, setPrevAmount)}
                value={amountValue || ''}
              />
            </ControlGroup>
          </StyledToolTip>
        </FormGroup>
      </Div>
    </ThemeProvider>
  )
}

TokensSelect.propTypes = {
  alertIntent: PropTypes.oneOf([Intent.NONE,
    Intent.PRIMARY,
    Intent.SUCCESS,
    Intent.WARNING,
    Intent.DANGER]),
  alertText: PropTypes.string,
  inputAmountDisabled: PropTypes.bool,
  amountValue: PropTypes.string,
  footer: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  header: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  label: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  onAmountChange: PropTypes.func,
  onItemSearch: PropTypes.func,
  onItemSelect: PropTypes.func,
  query: PropTypes.string,
  selectedToken: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.array),
}

TokensSelect.defaultProps = {
  inputAmountDisabled: false,
  alertIntent: Intent.NONE,
  alertText: null,
  amountValue: undefined,
  footer: null,
  header: null,
  label: null,
  list: [''],
  onAmountChange: () => null,
  onItemSearch: () => null,
  onItemSelect: () => null,
  query: '',
  selectedToken: () => null,
  style: {},
}

export default React.memo(TokensSelect)
