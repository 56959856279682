import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@blueprintjs/core';
import styled from 'styled-components';
import { ICON_COLORS, SYMBOLS } from './CryptoIcon.constants';
import EfxCryptoIcons from '../EFXCryptoIcons';

const StyledEfxIcon = symbol => styled(EfxCryptoIcons[symbol])`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

const DivIcon = styled.div`
  flex: 1;
  flex-grow: 0;
`;

const DivText = styled.div`
  flex: 1;
  font-size: 0.8em;
  flex-grow: 1;
  text-align: center;
`;

const DivContainer = styled.div`
  display: flex;
  font-size: ${props => props.size}px;
`;

const CryptoIcon = ({ symbol, className, size, color, withText, onClick }) => {
  const EfxIcon = EfxCryptoIcons[symbol] ? StyledEfxIcon(symbol) : Icon;

  return (
    <DivContainer className={className} size={size}>
      <DivIcon size={size}>
        <EfxIcon
          alt={`${symbol}-icon`}
          onClick={onClick}
          size={size}
          color={color}
        />
      </DivIcon>
      {withText && <DivText>{symbol}</DivText>}
    </DivContainer>
  );
};

CryptoIcon.propTypes = {
  className: PropTypes.string,
  symbol: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
  withText: PropTypes.bool
};

CryptoIcon.defaultProps = {
  symbol: SYMBOLS.ETH,
  className: '',
  size: 32,
  color: ICON_COLORS.BLACK,
  withText: false,
  onClick: () => null
};

export default CryptoIcon;
