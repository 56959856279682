import { BIDS } from '../../const'

const sortAggBook = prices => (side) => {
  const pLevels = Object.keys(prices).sort((a, b) => {
    if (side === BIDS) {
      return +a >= +b ? -1 : 1
    }
    return +a <= +b ? -1 : 1
  })
  let total = 0

  const pSnap = pLevels.map((p) => {
    total = prices[p].amount + total
    return [p, prices[p].amount, total]
  })
  return pSnap
}

export default sortAggBook
