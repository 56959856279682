import { DEFAULT_TOKEN } from '../../EfxSwapUi.constants'

const getPairData = (data, pair, dex) => {
  let pairData = { ...data.tickers[`${pair}`] } || DEFAULT_TOKEN

  const settleSpread = (
    Object.keys(dex.tokenRegistry).length && dex.tokenRegistry.USD.settleSpread
  ) || 0

  if (pair.slice(-3) === 'USD') {
    pairData.lastPrice *= (1 + settleSpread)
  }

  if (data.books[pair]) {
    pairData = {
      ...pairData,
      chanIdBooks: data.books[pair].chanId,
      books: { ...data.books[pair].books },
    }
  }

  return pairData
}

export default getPairData
