import { getEthBalances } from '../../../web3'

const getEthBalancesWithWei = async (web3, accounts) => {
  const accountsAddress = accounts.map(account => account.address)

  const balancesWei = await getEthBalances(accountsAddress, web3)

  const accountsWithBalances = accounts.map((account, i) => {
    const balance = web3.utils.fromWei(balancesWei[i], 'ether')

    return { ...account, balance, balanceWei: balancesWei[i] }
  })
  return accountsWithBalances
}
export default getEthBalancesWithWei
