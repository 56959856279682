import { SWAP_STATUS, SWAP_STEPS } from '../../const'

const {
  ERROR, SUBMISSION_ERROR, CANCELLED, SUCCESS, UNLOCK_ERROR,
} = SWAP_STATUS

const swapStepUtils = (progressSteps = SWAP_STEPS) => {
  let currStepIndex = 0
  const steps = progressSteps

  return {
    getSteps: () => steps,
    getProgress: (status) => {
      const currentStatus = status[status.length - 1]

      if ([ERROR, SUBMISSION_ERROR, CANCELLED, SUCCESS, UNLOCK_ERROR].includes(currentStatus)) {
        return 1
      }

      if (status.length !== 1) {
        return Math.ceil((status.length / SWAP_STEPS.length) * 100) / 100
      }

      return 0
    },
    validate: (status) => {
      if (currStepIndex >= steps.length) {
        currStepIndex = 0
        throw Error(`A transaction can have a maximum of ${steps.length} steps`)
      }
      if (!steps.includes(status)) {
        currStepIndex = 0
        throw Error(`${status} is not a valid transaction status`)
      }
      currStepIndex += 1

      return status
    },
  }
}

export default swapStepUtils
