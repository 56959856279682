var processStreamEvent = function processStreamEvent(event) {
  var msg = JSON.parse(event.data);

  if (msg && msg.data && msg.data.k) {
    var ticker = msg.data.s;
    var interval = msg.data.k.i;
    var data = [msg.data.k.t, Number(msg.data.k.o), Number(msg.data.k.h), Number(msg.data.k.l), Number(msg.data.k.c), Number(msg.data.k.v), msg.data.k.x];
    return [ticker, data, interval];
  }

  return null;
};

export default processStreamEvent;