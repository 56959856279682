import { WALLETS } from '../../../components/WalletsBox/WalletsBox.constants';
import { METAMASK_ERROR } from '../../../const/errors';
import connectMetamask from './utils/connectMetamask';
import monitorNewBlock from './utils/monitorNewBlock';
import monitorAccounts from './utils/monitorAccounts';
import unsubscribe from './utils/unsubscribe';
import { getTokensBalances, web3Wrapper } from '../../web3';
import { ERC20Abi } from '../../../const';
import initHelper from '../initHelper';

const helperMetamask = () => {
  const source = WALLETS.METAMASK;
  let ERC20Contract;
  let networkId;
  let subMetamask;
  let subNewBlock;
  let web3;

  return {
    getWeb3: () => web3,
    init: async () => {
      let accounts = [];

      try {
        web3 = await connectMetamask();

        accounts = await web3.eth.getAccounts();

        networkId = await web3.eth.net.getId();

        if (!accounts.length) {
          throw Error(METAMASK_ERROR.IS_LOCKED);
        }

        accounts = accounts.reduce(
          (acc, account) => [...acc, { address: account, path: null }],
          []
        );

        ERC20Contract = new web3.eth.Contract(ERC20Abi);
      } catch (err) {
        throw err;
      }

      return [accounts, networkId];
    },

    unsubscribe: async () => {
      unsubscribe(subNewBlock, subMetamask);
    },

    subscribe: async (onAccounts, onNewBlock) => {
      try {
        if (subNewBlock) {
          await subNewBlock.unsubscribe();
        }
      } catch (err) {
        return err;
      }
      try {
        if (subMetamask) {
          subMetamask.removeAllListeners();
        }
      } catch (err) {
        return err;
      }

      subMetamask = await monitorAccounts(web3, onAccounts);

      subNewBlock = monitorNewBlock(web3, onNewBlock);

      return { unsubscribe: () => unsubscribe(subNewBlock, subMetamask) };
    },

    getAccounts: async () => web3.eth.getAccounts(),

    getBalances: async (account, fromToken, toToken) =>
      getTokensBalances(account, fromToken, toToken, web3, ERC20Contract),

    getSource: () => ({ source }),

    close: async () => {
      web3Wrapper.reset();
      unsubscribe(subNewBlock, subMetamask);
    }
  };
};

export default () => ({ ...initHelper(), ...helperMetamask() });
