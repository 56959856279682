import './EfxSwapUI.scss';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import {
  AnchorButton,
  Icon,
  Intent,
  Tooltip,
  Position
} from '@blueprintjs/core';
import Ticker from './components/Ticker';
import TokensSelect from './components/TokensSelect';
import conf from './env';
import WalletsBox from './components/WalletsBox';
import { withTheme } from './utils/misc';
import useInitApp from './EfxSwapUI.init';
import { defTheme, WITH_THEME } from './EfxSwapUi.constants';
import {
  onAmountChange,
  onCancelSwap,
  onCancelTransaction,
  onConfirmSwap,
  onGetAccounts,
  onOpenSwap,
  onSelectFullBalance,
  onSelectWallet,
  onSwitchSelect,
  onTokenSelect,
  onUnlockTokens
} from './utils/forms';
import ConfirmDialog from './components/ConfirmDialog';
import { WALLET_STATUS } from './components/WalletsBox/WalletsBox.constants';
import { SWAP_STATUS } from './const';
import TransactionsBox from './components/TransactionsBox';
import SectionContainer from './components/SectionContainer';

const { SELECT_CONTAINER, SWAP_BUTTON, APP_CONTAINER } = WITH_THEME;

const SelectContainer = withTheme(Row)`
  ${props => props.theme[SELECT_CONTAINER]}
`;

const SwapButton = withTheme(AnchorButton, SWAP_BUTTON)`
`;

const WrapperApp = withTheme.styled.div`
  ${props => props.theme[APP_CONTAINER]}
`;

const EfxSwapUi = ({
  from,
  to,
  reverseSwap,
  style,
  onSwapSubmitFn,
  options
}) => {
  const [prevFrom, setPrevFrom] = useState(from);
  const [prevTo, setPrevTo] = useState(to);

  const theme = { ...defTheme, ...style };
  const [wallet, dex, data, ws, pairData] = useInitApp(
    from,
    to,
    reverseSwap,
    conf.network
  );
  const {
    resetWallet,
    updateWallet,
    setSelectedAccountWallet
  } = wallet.dispatch;
  const { exchangePairs, tokenRegistry } = dex.state;
  const {
    swap: {
      fromToken,
      toToken,
      pair,
      fromTokenAmount,
      toTokenAmount,
      price,
      status,
      hash,
      reverse
    },
    accounts,
    statusMsg
  } = wallet.state;
  const transaction = hash ? dex.state.transactions[hash] : null;

  const tokensList = useCallback(
    () =>
      Object.keys(tokenRegistry).filter(
        token => !conf.quoteTokens.includes(token)
      ),
    [tokenRegistry]
  );
  const onTokenSelectFrom = useCallback(
    token =>
      onTokenSelect(wallet, dex, ws, pairData, 'from')(toToken.symbol)(token),
    [dex, pairData, toToken.symbol, wallet, ws]
  );
  const onTokenSelectTo = useCallback(
    token =>
      onTokenSelect(wallet, dex, ws, pairData, 'to')(fromToken.symbol)(token),
    [wallet, dex, ws, pairData, fromToken.symbol]
  );
  const onAmountChangeFrom = useCallback(
    onAmountChange(wallet, pairData, 'from'),
    [wallet, pairData]
  );
  const onAmountChangeTo = useCallback(onAmountChange(wallet, pairData, 'to'), [
    wallet,
    pairData
  ]);
  const isLoading = Object.keys(data.books).length;
  const onSwapDialogConfirm = useCallback(
    () => onConfirmSwap(wallet, dex, pairData),
    [dex, pairData, wallet]
  );
  const onSwapDialogCancel = useCallback(() => onCancelSwap(wallet, dex), [
    dex,
    wallet
  ]);
  const onSwapDialogClose = useCallback(() => {
    const payload = { timestamp: null, hash: null, status: SWAP_STATUS.NEW };
    wallet.dispatch.setAlertWallet('swap', null, null);
    wallet.dispatch.updateSwapWallet(payload);
  }, [wallet.dispatch]);
  const onCancel = useCallback(
    async (orderId, swapHash) =>
      onCancelTransaction(dex.helper, dex.dispatch, orderId, swapHash),
    [dex.helper, dex.dispatch]
  );

  useEffect(() => {
    if (from !== prevFrom) {
      onTokenSelectFrom(from);
      setPrevFrom(from);
    }
  }, [from, onTokenSelectFrom, prevFrom]);

  useEffect(() => {
    if (to !== prevTo) {
      onTokenSelectTo(to);
      setPrevTo(to);
    }
  }, [to, onTokenSelectTo, prevTo]);

  return (
    <WrapperApp theme={theme.app} id="swap-ui">
      <Grid fluid>
        <SelectContainer middle="xs" around="xs">
          <Col xs={12} sm>
            <Row middle="xs" around="xs">
              <>
                <Col xs={12} sm>
                  <TokensSelect
                    alertIntent={wallet.state.error.from.msg && Intent.DANGER}
                    alertText={wallet.state.error.from.msg}
                    amountValue={fromTokenAmount}
                    exchangePairs={exchangePairs}
                    inputAmountDisabled={!isLoading}
                    label="From"
                    list={tokensList()}
                    onAmountChange={onAmountChangeFrom}
                    onItemSelect={onTokenSelectFrom}
                    selectedToken={fromToken.symbol}
                    style={theme.select}
                  />
                </Col>
                <Col>
                  <Row center="xs" sm>
                    <Col>
                      <Icon
                        icon="exchange"
                        iconSize={30}
                        onClick={onSwitchSelect(
                          wallet,
                          pairData.helper.getPriceLevel
                        )}
                        style={{ cursor: 'pointer' }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm>
                  <TokensSelect
                    alertIntent={wallet.state.error.to.msg && Intent.DANGER}
                    alertText={wallet.state.error.to.msg}
                    amountValue={toTokenAmount}
                    exchangePairs={exchangePairs}
                    inputAmountDisabled={!isLoading}
                    label="To"
                    list={tokensList()}
                    onAmountChange={onAmountChangeTo}
                    onItemSelect={onTokenSelectTo}
                    selectedToken={toToken.symbol}
                    style={theme.select}
                  />
                </Col>
              </>
            </Row>
          </Col>
        </SelectContainer>
        <Row>
          <Col xs>
            <Ticker
              lastPrice={Number(price || pairData.state.lastPrice)}
              pair={pair}
              style={theme.ticker}
            />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Row center="xs">
              <Col>
                <Tooltip
                  content="Please select a wallet."
                  disabled={wallet.state.status === WALLET_STATUS.LOGGED_IN}
                  position={Position.BOTTOM}
                >
                  <SwapButton
                    disabled={
                      !(wallet.state.status === WALLET_STATUS.LOGGED_IN) ||
                      !fromTokenAmount ||
                      !toTokenAmount ||
                      wallet.state.error.from.msg ||
                      wallet.state.error.to.msg
                    }
                    large
                    minimal
                    text="SWAP"
                    onClick={useCallback(() => onOpenSwap(wallet, dex), [
                      dex,
                      wallet
                    ])}
                    theme={theme.app}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <SectionContainer
          headerTitle="ACCOUNTS"
          options={options.ui.walletSection}
        >
          <Row>
            <Col xs>
              <Row center="xs">
                <Col xs>
                  <WalletsBox
                    isReverseSwap={reverse}
                    network={conf.network}
                    onGetAccounts={useCallback(
                      (path, start, n) =>
                        onGetAccounts(
                          updateWallet,
                          wallet.helper.getAccounts
                        )((path, start, n)),
                      [updateWallet, wallet.helper.getAccounts]
                    )}
                    onResetWallet={resetWallet}
                    onSelectAccount={useCallback(
                      (address, path) =>
                        setSelectedAccountWallet(address, path),
                      [setSelectedAccountWallet]
                    )}
                    onSelectWallet={useCallback(
                      selectedWallet => onSelectWallet(wallet)(selectedWallet),
                      [wallet]
                    )}
                    onUnlockTokens={useCallback(
                      (symbol, amount) =>
                        onUnlockTokens(wallet, dex)(symbol, amount),
                      [dex, wallet]
                    )}
                    onSelectFullBalance={useCallback(
                      (amount, symbol) => {
                        onSelectFullBalance(
                          wallet,
                          pairData.helper.getPriceLevel
                        )(amount, symbol);
                      },
                      [pairData.helper.getPriceLevel, wallet]
                    )}
                    selectedWallet={wallet.state}
                    style={theme.wallets}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <Row center="xs">
                <Col xs>
                  {wallet.state.status === WALLET_STATUS.LOGGED_IN && (
                    <TransactionsBox
                      onCancel={onCancel}
                      style={theme.transactions}
                      transactions={dex.state.transactions || {}}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </SectionContainer>

        {status !== SWAP_STATUS.NEW && (
          <Row>
            <Col xs>
              <ConfirmDialog
                accountAddress={accounts[0].address}
                alertIntentTransaction={transaction.alert.type}
                alertTextTransaction={transaction.alert.msg}
                alertIntentWallet={statusMsg.type}
                alertTextWallet={statusMsg.msg}
                fromTokenAmount={fromTokenAmount || '0.00000'}
                fromTokenSymbol={fromToken.symbol}
                isOpen={status !== SWAP_STATUS.NEW}
                onCancel={onSwapDialogCancel}
                onClose={onSwapDialogClose}
                onConfirm={onSwapDialogConfirm}
                onSubmit={onSwapSubmitFn}
                pair={pair}
                price={Number(price)}
                statusProgress={transaction.status}
                style={theme.dialog}
                toTokenAmount={toTokenAmount || '0.00000'}
                toTokenSymbol={toToken.symbol}
              />
            </Col>
          </Row>
        )}
      </Grid>
    </WrapperApp>
  );
};

EfxSwapUi.propTypes = {
  from: PropTypes.string,
  onSwapSubmitFn: PropTypes.func,
  reverseSwap: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.object),
  to: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object]))
};

EfxSwapUi.defaultProps = {
  from: conf.pair[0],
  to: conf.pair[1],
  reverseSwap: false,
  style: {},
  onSwapSubmitFn: () => null,
  options: {
    ui: {
      walletSection: { collapse: { isOpen: true }, help: { text: '' } }
    }
  }
};

export default EfxSwapUi;
