import { SWAP_STATUS } from '../../../../const'

const { SUBMIT_PENDING, SUBMIT_DONE } = SWAP_STATUS

const submitOrder = async ({
  dexHelper,
  tradePair,
  swapAmount,
  swapPrice,
  signedOrder,
  orderExpireTime,
  onStatus,
}) => {
  try {
    onStatus(SUBMIT_PENDING)

    if (process.env.NODE_ENV !== 'production') {
      console.log(`efx.submitOrder(${tradePair}, 
        ${swapAmount}, ${swapPrice}, 1, ${new Date().getTime()}, ${signedOrder}, ${orderExpireTime})`)
    }

    const response = await dexHelper.submitOrder(
      tradePair,
      swapAmount,
      swapPrice,
      1,
      new Date().getTime(),
      signedOrder,
      orderExpireTime,
    )

    onStatus(SUBMIT_DONE)

    return response
  } catch (err) {
    throw err
  }
}

export default submitOrder
