import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'

const StyledDiv = styled.div`
  cursor: pointer;
`

const HelpIcon = ({ onClick }) => (
  <StyledDiv onClick={onClick}>
    <Icon icon='help' />
  </StyledDiv>
)

HelpIcon.propTypes = {
  onClick: PropTypes.func,
}

HelpIcon.defaultProps = {
  onClick: () => {},
}

export default React.memo(HelpIcon)
