import { BigNumber } from 'bignumber.js'

const toBaseUnitAmount = (amount, decimals) => {
  const aUnit = new BigNumber(10).pow(decimals)
  const unit = new BigNumber(amount)
    .times(aUnit)
    .integerValue(BigNumber.ROUND_FLOOR)
    .toFixed()
  return unit
}

export default toBaseUnitAmount
