/* eslint no-underscore-dangle:0 */

const isApproved = async () => {
  const { ethereum } = window
  if (ethereum && ethereum._metamask && ethereum._metamask.isApproved) {
    return window.ethereum._metamask.isApproved()
  }

  return true
}

export default isApproved
