import PropTypes from 'prop-types'
import React, {
  useEffect, useState,
} from 'react'
import {
  Callout, Icon, Intent,
} from '@blueprintjs/core'
import Fade from 'react-reveal/Fade'
import { withTheme } from '../../utils/misc'

const StyledMsg = withTheme.styled.div`
  display: flex;
  div:nth-child(1) {
    flex-grow: 1;
  };
  div:nth-child(2) {
    margin-left: 10px;
    cursor: pointer;
  };
`

export const bgColor = {
  [Intent.SUCCESS]: '#5A873F',
  [Intent.DANGER]: '#DE5F66',
  [Intent.WARNING]: '#DCA509',
}

const Alert = withTheme.styled(Callout)`
  min-width: 200px;
  background-color: ${props => bgColor[props.intent]};
  && {
    background-color: ${props => bgColor[props.intent]};
    & .bp3-icon:first-child {
      color: white;
    }
    & div {
      background-color: ${props => bgColor[props.intent]};
      color: white;
    }
  }
`

const SimpleAlert = ({
  visible, text, type, delay,
}) => {
  const [showAlert, setShowAlert] = useState(visible)

  useEffect(() => {
    setShowAlert(true)
  }, [text])

  useEffect(() => {
    const td = setInterval(() => {
      setShowAlert(false)
    }, delay)
    return () => clearInterval(td)
  }, [delay, text])

  return (
    <Fade
      when={text && showAlert}
      collapse
    >
      <Alert title={false} intent={type}>
        <StyledMsg>
          <div>
            {text}
          </div>
          <div>
            <Icon icon='cross' onClick={() => setShowAlert(false)} />
          </div>
        </StyledMsg>
      </Alert>
    </Fade>
  )
}

SimpleAlert.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  delay: PropTypes.number,
}

SimpleAlert.defaultProps = {
  text: null,
  type: null,
  visible: true,
  delay: 2000,
}

export default React.memo(SimpleAlert)
