import { Intent } from '../../const'
import { formatError } from '../format'

export const errorMsgText = symbol => `Error: could not unlock ${symbol} tokens`

const onUnlockTokens = (wallet, dex) => async (symbol, amount) => {
  wallet.dispatch.updateBalancesWallet(symbol, { unlocking: true })

  try {
    await dex.helper.initDex()

    await dex.helper.cancelOrders()

    await dex.helper.unlockTokens(symbol, amount, true)

    wallet.dispatch.setAlertWallet('wallet', `${symbol} tokens unlocked`, Intent.SUCCESS)
  } catch (err) {
    wallet.dispatch.updateBalancesWallet(symbol, { unlocking: false })
    wallet.dispatch.setAlertWallet('wallet', formatError(err, errorMsgText(symbol)), Intent.DANGER)
    return
  }

  wallet.dispatch.updateBalancesWallet(symbol, { unlocking: false, wrapper: '0' })
}

export default onUnlockTokens
