import { SWAP_STATUS, Intent } from '../../const'
import { SWAP_ERROR } from '../../const/errors'
import { formatError } from '../format'

const onCancelSwap = async (wallet, dex) => {
  const { hash } = wallet.state.swap

  const { revertSteps, status } = dex.state.transactions[hash]

  if (status === SWAP_STATUS.CONFIRMATION_PENDING) {
    const payload = { timestamp: null, hash: null, status: SWAP_STATUS.NEW }

    wallet.dispatch.updateSwapWallet(payload)

    dex.dispatch.deleteTransation(hash)
  } else if (revertSteps) {
    let error = false

    try {
      await dex.helper.revertSwap(revertSteps)
    } catch (err) {
      error = formatError(err)
    }

    const msg = error ? SWAP_ERROR.REVERT : SWAP_STATUS.REVERT_DONE
    const type = error ? Intent.DANGER : Intent.SUCCESS

    dex.dispatch.setAlertTransaction(hash, msg, type, Date.now())

    dex.dispatch.updateTransaction(hash, { message: error })
  }
  const payload = { timestamp: null, hash: null, status: SWAP_STATUS.NEW }

  wallet.dispatch.updateSwapWallet(payload)
}

export default onCancelSwap
