import { handleActions } from 'redux-actions';
import actions from 'store/actions';
import u from 'updeep';
import initialState from '../initialState';

const chartReducer = handleActions(
  {
    [actions.chart.set]: (state, action) => {
      return u({ ...action.payload }, state);
    }
  },
  initialState.chart
);

export default chartReducer;
