import { validateAmounts } from '../validation'
import { formatError } from '../format'
import { Intent } from '../../const'
import { getTokenBalance } from '../misc'

const onSwitchSelect = (wallet, getPriceLevel) => () => {
  const {
    swap: {
      fromTokenAmountBaseUnits,
      fromToken,
      fromTokenAmount,
      pair,
      reverse,
      toToken,
      toTokenAmount,
      toTokenAmountBaseUnits,
    },
    balances,
  } = wallet.state

  const {
    setAlertWallet,
  } = wallet.dispatch

  try {
    const price = getPriceLevel(toTokenAmount, !reverse)

    wallet.dispatch.setSwapPairWallet(toToken, fromToken, pair)

    wallet.dispatch.setSwapAmountsWallet(toTokenAmount, fromTokenAmount, price)

    wallet.dispatch.setSwapAmountsBaseUnitsWallet(
      toTokenAmountBaseUnits,
      fromTokenAmountBaseUnits,
      price,
    )
    let validationOptions = {
      isPositiveNumber: true,
      isMinOrderSize: true,
      isBalanceSufficient: true,
    }

    if (toTokenAmountBaseUnits) {
      validationOptions = {
        ...validationOptions,
        ...{ isPositiveNumber: false, isBalanceSufficient: true },
      }
    }

    const balance = getTokenBalance(toToken.symbol, balances)

    validateAmounts(toToken, fromToken, toTokenAmount, fromTokenAmount, balance, validationOptions)
    setAlertWallet('from', null, null)
  } catch (err) {
    setAlertWallet('from', formatError(err), Intent.DANGER)
  }
}

export default onSwitchSelect
