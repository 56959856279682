/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const debug = false;

const debugActionsMiddleWare = store => next => action => {
  if (debug) {
    console.log(action.type);
    console.log(action.payload);
  }

  return next(action);
};

export default debugActionsMiddleWare;
