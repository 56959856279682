import { normalizeColor } from 'grommet/utils/colors'

const theme = {
  global: {
    font: {
      size: '12px',
      family: "'Mali', sans-serif;",
    },
    colors: {
      brand: '#054186',
      border: '#e0e0e0',
      focus: '#2196F3',
    },
    drop: {
      background: '#ffffff',
    },
  },
  hover: {
    border: {
      color: undefined,
    },
  },
  textInput: {
    extend: props => `
      color: ${normalizeColor('gray', props.theme)};
      font-weight: 400;
      font-size: 13px;
      padding: 14px;
    `,
  },
}

export default theme
