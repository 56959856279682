import * as actions from './tickerActions'
import { isTicker, isBook } from '../../utils/ws'

let td
let buffer = {}

export const bufferTime = (msg, time, dispatch) => {
  const [chanId] = msg

  if (!buffer[chanId]) {
    buffer[chanId] = []
  }

  buffer[chanId].push(msg)

  const bufferMsg = () => {
    if (!td) {
      td = setTimeout(() => {
        if (buffer[chanId] && buffer[chanId].length) {
          const action = actions.updateBooksWs({
            msg: buffer,
          })
          dispatch(action)
        }
        clearInterval(td)
        td = null
        buffer = {}
        bufferMsg()
      }, time)
    }
  }
  bufferMsg()
}

const onMessageWs = dispatch => (err, msg) => {
  if (err) throw new Error(err)

  if (msg.event === 'subscribed') {
    if (td) {
      clearInterval(td)
      td = null
      buffer = {}
    }
    const { pair, channel } = msg
    const action = actions.addChannelWs({ [msg.chanId]: { pair, channel } })
    dispatch(action)
  }

  if (msg.event === 'unsubscribed' && msg.status === 'OK') {
    if (td) {
      clearInterval(td)
      td = null
      buffer = {}
    }

    let action = actions.resetBooksWs(msg.chanId)
    dispatch(action)

    action = actions.updateTickerWs({
      chanId: msg.chanId,
      lastPrice: 0,
      volume: 0,
      sub: false,
    })
    dispatch(action)

    action = actions.removeChannelWs(msg.chanId)
    dispatch(action)
  }


  if (isBook(msg)) {
    bufferTime(msg, 500, dispatch)
    return
  }

  if (isTicker(msg)) {
    const action = actions.updateTickerWs({
      chanId: msg[0],
      lastPrice: msg[1][6],
      volume: msg[1][7],
      sub: true,
    })
    dispatch(action)
  }
}

export default onMessageWs
