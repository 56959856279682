import _slicedToArray from "/home/whitenoise/Desktop/FlareDEX/flaredex-dev/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// Example REST time point response
// [
//   1499040000000,      // Open time
//   "0.01634790",       // Open
//   "0.80000000",       // High
//   "0.01575800",       // Low
//   "0.01577100",       // Close
//   "148976.11427815",  // Volume
//   1499644799999,      // Close time
//   "2434.19055334",    // Quote asset volume
//   308,                // Number of trades
//   "1756.87402397",    // Taker buy base asset volume
//   "28.46694368",      // Taker buy quote asset volume
//   "17928899.62484339" // Ignore.
// ]
var formatter = {
  tradingview: function tradingview(data) {
    var _data = _slicedToArray(data, 6),
        time = _data[0],
        open = _data[1],
        high = _data[2],
        low = _data[3],
        close = _data[4],
        volume = _data[5];

    return {
      time: time,
      open: open,
      close: close,
      high: high,
      low: low,
      volume: volume
    };
  },
  default: function _default(data) {
    var _data2 = _slicedToArray(data, 6),
        time = _data2[0],
        open = _data2[1],
        high = _data2[2],
        low = _data2[3],
        close = _data2[4],
        volume = _data2[5];

    return {
      time: time,
      open: open,
      close: close,
      high: high,
      low: low,
      volume: volume
    };
  }
};
export default formatter;