var u = require('updeep');

var _require = require('../../const'),
    lineType = _require.lineType;

module.exports = function (tvUtil, db, onInteraction$, positionId, positionUpdate) {
  var data = positionUpdate.data,
      style = positionUpdate.style;
  var POSITION_LINE = lineType.POSITION_LINE;
  var prevPosition = db.get(positionId, POSITION_LINE);
  data.id = positionId;
  var position = {
    data: u(data, prevPosition.data),
    style: u(style, prevPosition.style)
  };

  try {
    prevPosition.tvLine.remove();
    return tvUtil.position.add(position);
  } catch (err) {
    return {
      error: err.message
    };
  }
};