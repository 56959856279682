import { handleActions } from 'redux-actions'
import {
  resetBalancesWallet,
  resetWallet,
  setAlertWallet,
  setSelectedAccountWallet,
  setSwapAmountsBaseUnitsWallet,
  setSwapAmountsWallet,
  setSwapPairWallet,
  setSwapStatusWallet,
  updateBalancesWallet,
  updateSwapWallet,
  updateWallet,
} from './walletActions'
import { WALLET_STATUS, WALLETS } from '../../components/WalletsBox/WalletsBox.constants'
import conf from '../../env'
import { SWAP_STATUS } from '../../const'

export const initWalletState = {
  accounts: [],
  selectedAccount: { address: null, path: null },
  source: WALLETS.NONE,
  error: {
    from: { msg: null, type: null },
    to: { msg: null, type: null },
    other: { msg: null, type: null },
    wallet: { msg: null, type: null },
    swap: { msg: null, type: null },
  },
  balances: {
    [conf.pair[0]]: {
      token: null,
      wrapper: null,
      tokenUnits: null,
      wrapperUnits: null,
      unlocking: false,
      status: null,
    },
    [conf.pair[1]]: {
      token: null,
      wrapper: null,
      tokenUnits: null,
      wrapperUnits: null,
      unlocking: false,
      status: null,
    },
  },
  endpoint: {
    blockNumber: null,
    error: null,
    networkId: null,
  },
  status: WALLET_STATUS.LOGGED_OUT,
  statusMsg: { msg: null, type: null },
  swap: {
    fromToken: conf.fromToken,
    fromTokenAmount: undefined,
    fromTokenAmountBaseUnits: undefined,
    lastUserInput: 'from',
    pair: conf.pair,
    price: null,
    reverse: false,
    status: SWAP_STATUS.NEW,
    toToken: conf.toToken,
    toTokenAmount: undefined,
    toTokenAmountBaseUnits: undefined,
    hash: null,
    timestamp: null,
    network: conf.network,
  },
}

const walletReducer = handleActions({
  [setAlertWallet]: (state, action) => ({
    ...state,
    error: {
      ...state.error,
      ...action.payload,
    },
  }),

  [resetWallet]: (state, action) => ({
    ...state,
    ...action.payload,
  }),

  [updateBalancesWallet]: (state, action) => {
    const [symbol] = Object.keys(action.payload)

    return {
      ...state,
      balances: {
        ...state.balances,
        [symbol]: {
          ...state.balances[symbol],
          ...action.payload[symbol],
        },
      },
    }
  },

  [resetBalancesWallet]: (state, action) => ({
    ...state,
    balances: {
      ...action.payload,
    },
  }),

  [setSelectedAccountWallet]: (state, action) => ({
    ...state,
    selectedAccount: {
      ...action.payload,
    },
  }),

  [updateWallet]: (state, action) => ({
    ...state,
    ...action.payload,
  }),

  [updateSwapWallet]: (state, action) => ({
    ...state,
    swap: {
      ...state.swap,
      ...action.payload,
    },
  }),

  [setSwapPairWallet]: (state, action) => ({
    ...state,
    swap: {
      ...state.swap,
      ...action.payload,
    },
  }),

  [setSwapStatusWallet]: (state, action) => ({
    ...state,
    ...action.payload,
  }),

  [setSwapAmountsBaseUnitsWallet]: (state, action) => {
    const { fromTokenAmountBaseUnits, toTokenAmountBaseUnits, price } = action.payload

    return {
      ...state,
      swap: {
        ...state.swap,
        price,
        fromTokenAmountBaseUnits,
        toTokenAmountBaseUnits,
      },
    }
  },

  [setSwapAmountsWallet]: (state, action) => {
    const { fromTokenAmount, toTokenAmount, price } = action.payload

    return {
      ...state,
      swap: {
        ...state.swap,
        price,
        fromTokenAmount,
        toTokenAmount,
      },
    }
  },
},
initWalletState)

export default walletReducer
