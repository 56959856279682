import PropTypes from 'prop-types'
import React from 'react'
import {
  Button, Intent, Tooltip, Position, Spinner,
} from '@blueprintjs/core'
import memoizeOne from 'memoize-one'
import { toUnitAmount } from '../../utils/format'
import FlexDiv from './WalletsBox.flexDiv'
import { withTheme } from '../../utils/misc'

const WrapperButton = withTheme.styled.div`
  && .bp3-button {
    :hover {
      background: none;
    }
  }
`

const StyledSpinner = withTheme(Spinner)`
  padding-top: 5px;
`

const toolTipContent = unlocking =>
  (!unlocking ? (
    <div>
      Click to cancel pending swaps
      <br />
      and unlock tokens.
    </div>
  ) : (
    <div>Unlock pending.</div>
  ))

const BalanceRow = ({
  tokenBalances, tokenData, onUnlock, onSelectFullBalance,
}) => {
  const { symbol, decimals } = tokenData

  if (!tokenBalances) {
    return <div key={`balance-${symbol}`} />
  }

  const { wrapper, unlocking, token } = tokenBalances

  const isNumber = fn => (tk, dec) => {
    const amount = fn(tk, dec).toFormat(5)

    return amount === 'NaN' ? false : amount
  }

  const tokenBalance = memoizeOne(isNumber(toUnitAmount))(token, decimals)
  const wrapperBalance = memoizeOne(isNumber(toUnitAmount))(wrapper, decimals)

  return (
    <FlexDiv flex row key={`balances-${symbol}`}>
      <FlexDiv col>
        <FlexDiv flex>
          <FlexDiv symbol>{symbol}</FlexDiv>
          <FlexDiv lock>
            {!!Number(wrapper) && (
              <Tooltip content={toolTipContent(unlocking)} position={Position.BOTTOM}>
                <WrapperButton>
                  {unlocking ? (
                    <StyledSpinner intent={Intent.SUCCESS} size={15} />
                  ) : (
                    <Button
                      id={`wallet-unlock-button-${symbol}`}
                      minimal
                      icon='unlock'
                      onClick={() => {
                        onUnlock(symbol, wrapper)
                      }}
                      intent={Intent.WARNING}
                      small
                    />
                  )}
                </WrapperButton>
              </Tooltip>
            )}
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
      <FlexDiv col>
        <FlexDiv amount skeleton={!tokenBalance} selectable={token && token !== '0'}>
          <span
            onKeyPress={(event) => {
              if ((token && token !== '0')) {
                return null
              }
              if (event.key === 'Enter') {
                onSelectFullBalance(tokenBalance, symbol)
              }
            }}
            onClick={() => ((token && token !== '0') ? onSelectFullBalance(tokenBalance, symbol) : null)}
            role='link'
            tabIndex='-1'
          >
            {tokenBalance}
          </span>
        </FlexDiv>
        <FlexDiv amount skeleton={!wrapperBalance}>
          <span>{wrapperBalance}</span>
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  )
}

BalanceRow.propTypes = {
  tokenBalances: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  ).isRequired,
  onUnlock: PropTypes.func.isRequired,
  onSelectFullBalance: PropTypes.func.isRequired,
  tokenData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  ).isRequired,
}

export default React.memo(BalanceRow)
