import CRC from 'crc-32'
import {
  ORDER_BOOK_ERROR, ASKS, BIDS, CS, HB,
} from '../../const'
import { sortAggBook, getBooks } from '../format'


const updateOrderBooks = (msg, currBooks) => {
  let books = {
    ...{
      bids: {},
      asks: {},
      psnap: {
        asks: [],
        bids: [],
      },
      mcnt: 0,
    },
    ...currBooks,
  }

  if (!Array.isArray(msg)) {
    throw new Error(ORDER_BOOK_ERROR.INVALID_MESSAGE)
  }


  if (msg[1] === HB) {
    return getBooks(books)
  }

  if (msg[1] === CS) {
    const checksum = msg[2]

    const csData = []

    const bidsKeys = books.psnap[BIDS]

    const asksKeys = books.psnap[ASKS]

    for (let i = 0; i < 25; i += 1) {
      if (bidsKeys[i]) {
        const [priceKey] = bidsKeys[i]

        const { price, amount } = books.bids[priceKey]

        csData.push(price, amount)
      }
      if (asksKeys[i]) {
        const [priceKey] = asksKeys[i]

        const { price, amount } = books.asks[priceKey]

        csData.push(price, -amount)
      }
    }

    const csStr = csData.join(':')
    const csCalc = CRC.str(csStr)

    if (csCalc !== checksum) {
      throw new Error(ORDER_BOOK_ERROR.CHECKSUM_ERROR)
    }
    return getBooks(books)
  }

  if (Array.isArray(msg[1][0])) {
    books = {
      bids: {},
      asks: {},
      psnap: {
        asks: [],
        bids: [],
      },
      mcnt: 0,
    }

    msg[1].forEach((pp) => {
      const [price, cnt, amount] = pp
      const side = amount >= 0 ? BIDS : ASKS

      books[side][price] = {
        price, cnt, amount: Math.abs(amount),
      }
    })
  } else {
    const [price, cnt, amount] = msg[1]

    if (!cnt) {
      if (amount > 0) {
        if (books[BIDS][price]) {
          delete books[BIDS][price]
        }
      } else if (amount < 0) {
        if (books[ASKS][price]) {
          delete books[ASKS][price]
        }
      }
    } else {
      const side = amount >= 0 ? BIDS : ASKS

      books[side][price] = {
        price,
        cnt,
        amount: Math.abs(amount),
      }
    }
  }

  books.psnap = {
    asks: sortAggBook(books.asks)(ASKS),
    bids: sortAggBook(books.bids)(BIDS),
  }

  books.mcnt += 1
  return getBooks(books)
}

export default updateOrderBooks
