import { BigNumber } from 'bignumber.js'

const toUnitAmount = (amount, decimals) => {
  const aUnit = new BigNumber(10).pow(decimals)
  const unit = new BigNumber(amount)
    .integerValue(BigNumber.ROUND_FLOOR)
    .div(aUnit)

  return unit
}

export default toUnitAmount
