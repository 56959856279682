export var BITFINEX = 'bitfinex';
export var BITMEX = 'bitmex';
export var BINANCE = 'binance';
export var POLONIEX = 'poloniex';
export var BITTREX = 'bitrex';
export var EXCHANGE_NOT_SUPPORTED = 'Exchange not supported';
export var EXCHANGE_NAME = {
  BITMEX: BITMEX,
  BITFINEX: BITFINEX,
  BINANCE: BINANCE,
  POLONIEX: POLONIEX,
  BITTREX: BITTREX
};
export var ERROR = {
  EXCHANGE_NOT_SUPPORTED: EXCHANGE_NOT_SUPPORTED
};