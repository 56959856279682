import Eth from '@ledgerhq/hw-app-eth'
import TransportU2F from '@ledgerhq/hw-transport-u2f'
import HDKey from 'hdkey'
import { publicToAddress, toChecksumAddress } from 'ethereumjs-util'

// As per Trustless code

const listAccounts = async (path = 'legacy', start, n) => {
  const transport = await TransportU2F.create()
  const eth = new Eth(transport)
  const accounts = []
  switch (path) {
    case 'legacy': {
      const root = await eth.getAddress("44'/60'/0'", false, true)
      const hdKey = new HDKey()
      hdKey.publicKey = Buffer.from(root.publicKey, 'hex')
      hdKey.chainCode = Buffer.from(root.chainCode, 'hex')

      for (let i = 0; i < n; i += 1) {
        const account = hdKey.derive(`m/${start + i}`)
        account.address = `0x${publicToAddress(account.publicKey, true).toString('hex')}`
        account.address = toChecksumAddress(account.address)
        account.path = `44'/60'/0'/${start + i}`
        accounts.push(account)
      }
      break
    }
    case 'live': {
      for (let i = 0; i < n; i += 1) {
        const currentPath = `44'/60'/${start + i}'/0/0`
        // eslint-disable-next-line no-await-in-loop
        const account = await eth.getAddress(currentPath)
        account.path = currentPath
        accounts.push(account)
      }
      break
    }
    default: {
      const account = await eth.getAddress(path)
      account.path = path
      accounts.push(account)
    }
  }

  return accounts
}

export default listAccounts
