import React, { useEffect, useRef } from 'react';
import { Box, Grid, Anchor } from 'grommet';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import TopBar from 'components/molecules/TopBar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import TokensList from 'components/pages/TokensList';
import TokenView from 'components/pages/TokenView';
import TvTickerPrice from 'components/molecules/TvTickerPrice';
import { formatTokenAmount } from 'utils/format';
import get from 'lodash/get';
import moment from 'moment';
import EfxSwapUi from '../../organism/EfxSwapUi';
import TradingViewContainer from '../../organism/TradingViewContainer';
import TvControls from '../../molecules/TvControls';
import {
  selectStyle,
  tickerStyle,
  walletsStyle,
  appStyle,
  dialogStyle,
  transactionsStyle
} from '../../../const/swapUiTheme';

const EfxSwapUiTheme = {
  app: appStyle,
  select: selectStyle,
  ticker: tickerStyle,
  wallets: walletsStyle,
  dialog: dialogStyle,
  transactions: transactionsStyle
};

const EfxSwapUiOptions = {
  ui: {
    walletSection: { collapse: { isOpen: true }, help: { text: '' } }
  }
};

export const makeLayout = ui => {
  let isMobile = true;
  let rows = ['50px', 'flex'];
  let areas = [
    { name: 'header', start: [0, 0], end: [1, 0] },
    { name: 'main', start: [0, 1], end: [1, 1] }
  ];

  if (ui.windowSize.innerWidth > ui.breakpoint.tablet) {
    rows = ['50px', 'flex', '25px'];
    areas = [
      { name: 'header', start: [0, 0], end: [1, 0] },
      { name: 'main', start: [0, 1], end: [1, 1] },
      { name: 'footer', start: [0, 2], end: [1, 2] }
    ];
    isMobile = !isMobile;
  }

  return { rows, areas, isMobile };
};

const StyledBox = styled(Box)`
  @media ${props => props.theme.ui.device.tablet} {
    padding: 12px;
  }
`;

const from = {
  '1h': moment()
    .subtract(1, 'hours')
    .valueOf(),
  '6h': moment()
    .subtract(6, 'hours')
    .valueOf(),
  '1d': moment()
    .subtract(1, 'days')
    .valueOf(),
  '3d': moment()
    .subtract(3, 'days')
    .valueOf(),
  '1m': moment()
    .subtract(1, 'months')
    .valueOf(),
  '1y': moment()
    .subtract(1, 'years')
    .valueOf()
};

const Home = ({ theme, tokens, selectedToken, tickersData, chart }) => {
  const { ui } = theme;
  const { rows, areas, isMobile } = makeLayout(ui);
  const tvChart = useRef(undefined);
  const { baseToken, quoteToken } = selectedToken;
  const { timeFrame } = chart;

  useEffect(() => {
    if (tvChart.current) {
      const setVisibleRange = () => {
        const now = moment();
        const range = {
          from: Math.floor(from[timeFrame.text] / 1000),
          to: Math.floor(now.valueOf() / 1000)
        };
        tvChart.current.setVisibleRange(range);
      };
      if (timeFrame.text === '1h') {
        tvChart.current.setResolution('1', setVisibleRange);
      }

      if (timeFrame.text === '1d') {
        tvChart.current.setResolution('5', setVisibleRange);
      }

      if (timeFrame.text === '3d') {
        tvChart.current.setResolution('15', setVisibleRange);
      }

      if (timeFrame.text === '1m') {
        tvChart.current.setResolution('60', setVisibleRange);
      }

      if (timeFrame.text === '1y') {
        tvChart.current.setResolution('1D', setVisibleRange);
      }
    }
  }, [chart, timeFrame]);

  return (
    <Router>
      <Grid fill rows={rows} columns={['flex']} gap="small" areas={areas}>
        <StyledBox gridArea="header" elevation="xsmall" pad="medium">
          <TopBar />
        </StyledBox>
        <Box gridArea="main" background="light-4">
          <Box
            width="1440px"
            margin={{ left: 'auto', right: 'auto' }}
            height="100%"
          >
            <Switch>
              <Route
                path="/token/"
                render={routerProps => {
                  const [, , tokenSymbol] = routerProps.location.pathname.split(
                    '/'
                  );

                  if (Object.keys(tokens).includes(tokenSymbol.toUpperCase())) {
                    const { symbol, name } = tokens[tokenSymbol.toUpperCase()];
                    return (
                      <TokenView
                        tokenSymbol={symbol}
                        tokenName={name}
                        dataPeriod="30day"
                      />
                    );
                  }

                  return `404 - Token ${tokenSymbol.toUpperCase()} is not available`;
                }}
              />
              <Route
                exact
                path="*"
                render={() => {
                  return (
                    <Box direction="row" height="100%">
                      <Box width="200px">
                        <TokensList />
                      </Box>
                      <Box
                        width="100%"
                        height="100%"
                        background="white"
                        pad={{ right: '5px', left: '5px', top: '0px' }}
                      >
                        <Box height="50%" width="100%">
                          <Box
                            height="10%"
                            direction="row"
                            justify="between"
                            alignContent="center"
                          >
                            <Box>
                              <TvControls />
                            </Box>
                            <Box>
                              <TvTickerPrice
                                symbols={[baseToken.symbol, quoteToken.symbol]}
                                curPrice={formatTokenAmount(
                                  tickersData.lastPrice || 0
                                )}
                              />
                            </Box>
                          </Box>

                          <Box
                            height="90%"
                            border={{ color: '#CCCCCC', size: 'xsmall' }}
                            pad="xsmall"
                          >
                            <TradingViewContainer
                              symbols={[baseToken.symbol, 'USD']}
                              onChartReady={widget => {
                                widget.chart();
                                tvChart.current = widget.chart();
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          height="50%"
                          border={{ color: '#CCCCCC', size: 'xsmall' }}
                          pad="xsmall"
                        >
                          <EfxSwapUi
                            style={EfxSwapUiTheme}
                            from={baseToken.symbol}
                            to="USD"
                            options={EfxSwapUiOptions}
                          />
                        </Box>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Switch>
          </Box>
        </Box>
        {!isMobile && (
          <Box gridArea="footer" background="light-2">
            <Anchor target="_blank" href="https://www.tokenanalyst.io">
              Contacts - FAQs
            </Anchor>
          </Box>
        )}
      </Grid>
    </Router>
  );
};

Home.propTypes = {
  tokens: PropTypes.objectOf(PropTypes.object).isRequired,
  chart: PropTypes.objectOf(PropTypes.object).isRequired,
  selectedToken: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  ).isRequired,
  tickersData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number]))
    .isRequired
};

Home.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  const { symbol } = state.selectedToken.baseToken;
  const { tickers } = state.tokensData;

  return {
    ...ownProps,
    chart: state.chart,
    tokens: state.tokens,
    selectedToken: state.selectedToken,
    tickersData: get(tickers, [symbol], {})
  };
};

export default connect(mapStateToProps)(withTheme(React.memo(Home)));
