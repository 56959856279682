import { handleActions } from 'redux-actions';
import actions from 'store/actions';
import u from 'updeep';
import initialState from '../initialState';

const tokensDataReducer = handleActions(
  {
    [actions.tokensData.tickers.reset]: (state, action) => {
      const tokenSymbol = action.payload;

      return u({ tickers: { [tokenSymbol]: null } }, state);
    },

    [actions.tokensData.tickers.set]: (state, action) => {
      const [tokenSymbol, data] = action.payload;
      return u({ tickers: { [tokenSymbol]: data } }, state);
    }
  },
  initialState.tokensData
);

export default tokensDataReducer;
