import steps from './steps'

const runSwapStep = async (step, args) => {
  if (steps[step]) {
    try {
      const response = await steps[step](args)
      return response
    } catch (err) {
      throw err
    }
  } else {
    const error = `'${step}' is not a valid transaction step`
    throw Error(error)
  }
}

export default runSwapStep
