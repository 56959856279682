import { css } from 'styled-components';
import { WITH_THEME as SELECT } from './components/TokensSelect/TokensSelect.constants';
import { WITH_THEME as TICKER } from './components/Ticker/Ticker.constants';
import { WITH_THEME as WALLETS } from './components/WalletsBox/WalletsBox.constants';
import { WITH_THEME as DIALOG } from './components/ConfirmDialog/ConfirmDialog.constants';
import { WITH_THEME as TRANSACTIONS } from './components/TransactionsBox/TransactionsBox.constants';

export const DEFAULT_TOKEN = {
  pair: '-',
  lastPrice: 0,
  volume: 0,
  sub: false
};

export const WITH_THEME = {
  APP_CONTAINER: 'AppContainer',
  SELECT_CONTAINER: 'SelectContainer',
  SWAP_BUTTON: 'SwapButton'
};

const fontColor = '#000000';
export const bgColor = '#ffffff';
const borderColor = '#EBEBEB';

const transactionsStyle = {
  [TRANSACTIONS.ACTIONS]: css`
    svg {
      * {
        fill: ${borderColor};
      }
      color: ${borderColor};
    }
  `,
  [TRANSACTIONS.PROGRESS_BAR]: css`
    color: ${borderColor};
  `,
  [TRANSACTIONS.CANCELLATION_POPOVER]: css`
    color: ${fontColor};
    padding: 10px;
    box-shadow: 0 1px 3px #292b3d, 0 1px 2px #292b3d;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid ${borderColor};
    background-color: ${bgColor};
    & .bp3-heading {
      color: ${fontColor};
    }
    & div:first-of-type {
      text-align: center;
    }
    & .bp3-button:first-of-type {
      :hover {
        color: gray;
      }
      color: ${fontColor};
      font-weight: 900;
      margin: 2px;
      font-weight: 300;
      border: 2px solid ${borderColor};
      background-color: ${borderColor};
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    & .bp3-button:nth-of-type(2) {
      text-transform: uppercase;
      color: ${fontColor};
      border: 1px solid ${borderColor};
      margin: 2px 2px 2px 2px;
      border-radius: 2px 2px 2px 2px;
      margin-bottom: 5px;
      :hover {
        color: ${fontColor};
      }
      && {
        color: ${fontColor};
      }
    }
  `,
  [TRANSACTIONS.CONTAINER]: css`
    color: ${fontColor};
    & h6 {
      color: ${fontColor};
    }
    && svg {
      * {
        fill: ${fontColor};
      }
      color: ${fontColor};
    }
  `
};

const dialogStyle = {
  [DIALOG.DIALOG]: css`
    top: 150px;
    position: absolute;
    color: ${fontColor};
    padding: 0px 5px 15px 5px;
    background-color: ${bgColor};
    & .bp3-dialog-header {
      background-color: ${bgColor};
    }
    @media only screen and (min-width: 768px) {
      width: 600px;
    }
  `,
  [DIALOG.DIALOG_CONFIRM_BUTTON]: css`
    &&& {
      :hover {
        color: gray;
      }
      color: ${fontColor};
      font-weight: 900;
      margin: 2px;
      font-weight: 300;
      border: 2px solid ${borderColor};
      background-color: ${bgColor};
      width: 200px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
  `,
  [DIALOG.DIALOG_CANCEL_BUTTON]: css`
    text-transform: uppercase;
    color: ${fontColor};
    border: 1px solid ${borderColor};
    margin: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    width: 200px;
    margin-bottom: 5px;
    :hover {
      color: ${fontColor};
    }
    && {
      color: ${fontColor};
    }
  `,
  [DIALOG.DIALOG_CONTENT]: css`
    padding: 20px;
    color: ${fontColor};
    .bp3-heading {
      color: ${fontColor};
      padding-bottom: 5px;
    }
    svg {
      * {
        fill: ${fontColor};
      }
      color: ${fontColor};
    }
  `,
  [DIALOG.DIALOG_TITLE]: css`
    color: ${fontColor};
  `
};

const appStyle = {
  [WITH_THEME.APP_CONTAINER]: css`
    background-color: ${bgColor};
    color: ${fontColor};
  `,
  [WITH_THEME.SELECT_CONTAINER]: css`
    min-height: 275px;
    @media only screen and (min-width: 768px) {
      min-height: 85px;
    }
  `,
  [WITH_THEME.SWAP_BUTTON]: css`
    &&& {
      :hover {
        color: ${fontColor};
      }
      color: ${fontColor};
      font-weight: 900;
      margin: 2px;
      font-weight: 300;
      border: 2px solid ${borderColor};
      background-color: ${bgColor};
      width: 200px;
    }
  `
};

const walletsStyle = {
  [WALLETS.ACCOUNTS_LIST_CONTAINER]: css`
    background-color: ${bgColor};
    color: ${fontColor};
    & .bp3-button {
      :hover {
        color: inherit;
        background-color: inherit;
      }
      :focus {
        outline: none;
      }
      font-size: 10px;
      color: ${fontColor};
      text-transform: uppercase;
    }
  `,
  [WALLETS.ACCOUNTS_LIST_SELECT]: css`
    background-color: ${bgColor};
    color: inherit;
    font-size: 10px;
    text-transform: uppercase;
    border: 0px;
    outline: 0px;
    margin-left: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    width: 80px;
    cursor: pointer;
    & option {
      background-color: inherit;
    }
  `,
  [WALLETS.CONTAINER]: css``,
  [WALLETS.PANEL]: css`
    div {
      background-color: ${bgColor};
    }
    min-height: 300px;
  `,
  [WALLETS.PANEL_WALLETS_BUTTON]: css`
    text-transform: uppercase;
    color: ${fontColor};
    border: 1px solid ${borderColor};
    margin: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    width: 150px;
    :hover {
      color: ${fontColor};
    }
    && {
      color: ${fontColor};
    }
  `,
  [WALLETS.PANEL_TITLE]: css`
    color: ${fontColor};
  `
};

const tickerStyle = {
  [TICKER.CONTAINER]: css`
    opacity: 0.5;
    color: ${fontColor};
    margin-bottom: 10px;
    margin-bottom: 25px;
  `
};

const selectStyle = {
  [SELECT.CONTAINER]: css`
    && .bp3-popover-content {
      background-color: ${bgColor};
    }
    && .bp3-label {
      color: ${fontColor};
      font-weight: 700;
      margin-left: 5px;
    }
    background-color: ${bgColor};
  `,
  [SELECT.SELECT_MENU_CONTAINER]: css`
    border: 1px solid ${borderColor};
    background-color: ${bgColor};
  `,
  [SELECT.SELECT_MENU_FOOTER]: css`
    img {
      height: 20px;
      width: 20px;
      float: right;
      clear: right;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-right: 5px;
    }
  `,
  [SELECT.SELECT_NUMERIC_INPUT]: css`
    margin: 2px 2px 2px 0px;
    &&& .bp3-control-group {
      > :only-child {
        border-radius: 0px;
      }
    }
  `,
  [SELECT.SELECT_MENU]: css`
    && {
      background-color: ${bgColor};
      color: ${fontColor};
    }
  `,
  [SELECT.SELECT_MENU_HEADER]: css`
    && {
      background-color: ${bgColor};
      color: ${fontColor};
    }
  `,
  [SELECT.SELECT_MENU_SEARCH]: css`
    margin: 10px;
    input {
      box-shadow: none;
      border-radius: 0px;
      border: 1px solid ${borderColor};
    }
  `,
  [SELECT.SELECT_BUTTON]: css`
    &&& {
      :hover {
        color: ${fontColor};
      }
      color: ${fontColor};
      border: 1px solid ${borderColor};
      margin: 2px 2px 0px 0px;
      border-radius: 2px 0px 0px 2px;
      width: 100%;
      @media only screen and (min-width: 768px) {
        width: 100%;
        text-align: center;
        margin: 2px 0px 2px 0px;
        border-right-width: 0px;
      }
      &&&& {
        text-align: center;
      }
    }
  `,
  [SELECT.TOKEN_ICON]: css`
    && svg {
      * {
        fill: ${fontColor};
      }
      color: ${fontColor};
    }
  `
};

export const defTheme = {
  app: appStyle,
  select: selectStyle,
  ticker: tickerStyle,
  wallets: walletsStyle,
  dialog: dialogStyle,
  transactions: transactionsStyle
};
