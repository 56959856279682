import { Intent } from '@blueprintjs/core'
import { WALLET_STATUS, WALLETS } from '../../components/WalletsBox/WalletsBox.constants'
import { formatError } from '../../utils/format'

const onWallet = (cb, source) => (err, accounts, path = null) => {
  let payload

  if (err) {
    const statusMsg = {
      msg: formatError(err),
      type: Intent.DANGER,
    }

    payload = {
      accounts: [],
      statusMsg,
      source: WALLETS.NONE,
      status: WALLET_STATUS.LOGGED_OUT,
      selectedAccount: { address: null, path: null },
    }

    cb(payload)
    return
  }
  const updatedAccounts = accounts.map(account => ({ address: account, path }))

  const selectedAccount = source === (WALLETS.METAMASK || WALLETS.PORTIS)
    ? { address: accounts[0], path: null }
    : { address: null, path: null }

  payload = { accounts: updatedAccounts, selectedAccount }

  cb(payload)
}

export default onWallet
