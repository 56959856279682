import { timer } from 'rxjs';
import {
  delayWhen,
  filter,
  map,
  mergeMap,
  retryWhen,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { fetchDataTickersFromWs$ } from 'store/epics/observables';
import actions from 'store/actions';

const makeSymbolsArray = tokens =>
  Object.values(tokens).map(token => `${token.symbol}USD`);

const fetchTokensDataTickersEpic = action$ =>
  action$.pipe(
    ofType(actions.tokensData.tickers.start.toString()),
    mergeMap(action =>
      fetchDataTickersFromWs$(
        makeSymbolsArray(action.payload.tokens),
        action.payload.relayName,
        action.payload.networkId
      ).pipe(
        filter(val => val && val[1] !== 'hb'),
        filter(val => val[1].length !== 0),
        map(data => {
          const [tokenSymbol, symbolTicker] = data;

          const ticker = {
            bid: symbolTicker[0],
            bidSize: symbolTicker[1],
            ask: symbolTicker[2],
            askSize: symbolTicker[3],
            dailyChange: symbolTicker[4],
            dailyPercChange: symbolTicker[5],
            lastPrice: symbolTicker[6],
            volume: symbolTicker[7],
            high: symbolTicker[8],
            low: symbolTicker[9]
          };

          const act = actions.tokensData.tickers.set(tokenSymbol, ticker);

          return act;
        }),
        takeUntil(action$.ofType(actions.tokensData.tickers.stop.toString())),
        retryWhen(errors =>
          errors.pipe(
            // eslint-disable-next-line no-console
            tap(error => console.log(`${error} - Retrying...`)),
            delayWhen(() => timer(5000))
          )
        )
      )
    )
  );

export default fetchTokensDataTickersEpic;
