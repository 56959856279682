/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */

import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
// eslint-disable-next-line no-unused-vars
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import debugActionsMiddleWare from 'store/middlewares/debugActionsMiddleware';
import tvcd from 'tvcd/src/tvcd';

import rootEpic from './store/epics';
import rootReducer from './store/reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ERC20_TOKENS } from './const/tokens';

const dataSource = tvcd('bitfinex');

const initCandles = () => {
  Object.keys(ERC20_TOKENS.mainnet).forEach(token => {
    const { options } = dataSource;
    if (token !== 'USD') {
      dataSource.addTradingPair([token, 'USD'], {
        interval: options.intervals['15m']
      });
    }
  });

  dataSource.start();
};

initCandles();

const epicMiddleware = createEpicMiddleware();

const middlewares = [debugActionsMiddleWare, epicMiddleware];

const persistConfig = {
  key: 'flareSwap',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [],
  transforms: []
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = enhancers => {
  if (process.env.NODE_ENV === 'development') {
    Reactotron.configure({ name: 'FlareSwap App' })
      .use(reactotronRedux())
      .connect();

    middlewares.push(require('redux-immutable-state-invariant').default());

    return (
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
          enhancers,
          Reactotron.createEnhancer()
        )) ||
      compose(
        enhancers,
        Reactotron.createEnhancer()
      )
    );
  }
  return compose(enhancers);
};

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(persistedReducer, enhancer);

epicMiddleware.run(rootEpic);

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
