import conf from '../../env'

const makeNonce = () => Math.ceil(Date.now() / 1000 + conf.efxNonceExpiration).toString()

const makeSignature = async (signFn, account, signatures) => {
  if (!account || !signFn) {
    throw Error('Account or sign function not provided.')
  }

  const nonce = makeNonce()
  let authSignature

  if (signatures && signatures[account] && signatures[account].nonce) {
    if (Number(signatures[account].nonce) - Math.ceil(Date.now() / 1000 + 120) < 0) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Signature expired. Generating new signature.')
      }

      const signature = await signFn(nonce)

      authSignature = { nonce, signature: signature.toString() }
    } else {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Signature expired. Generating new signature.')
      }

      authSignature = { ...signatures[account] }
    }
  } else {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Signature does not exist. Generating new signature.')
    }

    const signature = await signFn(nonce)
    authSignature = { nonce, signature: signature.toString() }
  }

  return authSignature
}

export default makeSignature
