import { getConfigEfx } from '../../utils/dex'
import { parseTokenRegistry } from '../../utils/misc'

const getConfDex = async () => {
  try {
    const config = await getConfigEfx()

    return parseTokenRegistry(config)
  } catch (err) {
    throw err
  }
}

export default getConfDex
