import { css } from 'styled-components'

export const WITH_THEME = {
  TOKEN_ICON: 'TokenIcon',
  CONTAINER: 'Container',
  SELECT: 'Select',
  SELECT_BUTTON: 'SelectButton',
  SELECT_MENU: 'Menu',
  SELECT_MENU_CONTAINER: 'MenuContainer',
  SELECT_MENU_FOOTER: 'MenuFooter',
  SELECT_MENU_HEADER: 'MenuHeader',
  SELECT_MENU_SEARCH: 'MenuSearch',
  SELECT_NUMERIC_INPUT: 'NumericInput',
}

export const defTheme = {
  [WITH_THEME.SELECT]: css`
  `,
  [WITH_THEME.CONTAINER]: css`
  `,
  [WITH_THEME.SELECT_MENU_CONTAINER]: css`
    `,
  [WITH_THEME.SELECT_MENU_FOOTER]: css`
  `,
  [WITH_THEME.SELECT_NUMERIC_INPUT]: css`
  `,
  [WITH_THEME.SELECT_MENU]: css`
  `,
  [WITH_THEME.SELECT_MENU_HEADER]: css`
  `,
  [WITH_THEME.SELECT_MENU_SEARCH]: css`
  `,
  [WITH_THEME.SELECT_BUTTON]: css`
  `,
  [WITH_THEME.TOKEN_ICON]: css`
  `,
}
