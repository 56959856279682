const dataSets = {
  '24h': {
    transactions: 'count_24h_rolling_v5',
    volume: 'volume_24h_rolling_v5',
  },
  '30day': {
    transactions: 'count_30day_v5',
    volume: 'volume_30day_v5',
  },
}

const getDataSetLabels = dataPeriod => dataSets[dataPeriod]

export default getDataSetLabels
