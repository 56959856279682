import { useState, useEffect } from 'react'

const throttle = (func, interval) => {
  let timeout
  return () => {
    const context = this
    const later = () => {
      timeout = false
    }
    if (!timeout) {
      func.apply(context)
      timeout = true
      setTimeout(later, interval)
    }
  }
}

const getSize = () => ({
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  outerHeight: window.outerHeight,
  outerWidth: window.outerWidth,
})

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    const throttledHandleResize = () => throttle(() => {
      setWindowSize(getSize())
    }, 200)

    window.addEventListener('resize', throttledHandleResize)

    return () => {
      window.removeEventListener('resize', throttledHandleResize)
    }
  }, [])

  return [windowSize]
}

export default useWindowSize
