import pingWs from './pingWs';
import reconnectWs from './reconnectWs';
import conf from '../../env';
import { omit } from '../misc';

const connectWs = (url, opts = {}) => {
  const defOpts = {
    timeout: conf.wsTimeout,
    retryDelay: conf.wsRetryDelay,
    maxRetry: conf.wsMaxRetry,
    initSubs: []
  };
  const connOpts = { ...defOpts, ...opts };
  const ws = new WebSocket(url);
  let pongTime = new Date().getTime();
  let td;
  const { onOpen, onClose, onMessage, onError } = connOpts;

  const isStale = pingTime => {
    if (pingTime - pongTime > connOpts.timeout) {
      ws.close(3000, 'Connection timeout.');
      if (td) {
        clearInterval(td);
      }
      if (onError) {
        onError(new Error('Stale connection detected.'), null);
      }
    }
  };

  ws.subs = connOpts.subs || {};

  ws.addEventListener('open', event => {
    td = pingWs(ws.send.bind(ws), isStale);
    const { initSubs } = connOpts;
    const subs = Object.keys(ws.subs);

    if (initSubs.length && !subs.length) {
      initSubs.forEach(sub => {
        ws.send(JSON.stringify(sub));
      });
    } else {
      subs.forEach(sub => {
        ws.send(JSON.stringify(ws.subs[sub]));
        ws.subs = omit(ws.subs, sub);
      });
    }

    if (onOpen) {
      onOpen(null, event);
    }
  });

  ws.addEventListener('close', event => {
    if (td) {
      clearInterval(td);
    }

    if (event.code !== 1000 && event.code !== 1005) {
      reconnectWs(url, { ...connOpts, subs: { ...ws.subs } });
    }

    if (onClose) {
      onClose(null, event);
    }
  });

  ws.addEventListener('error', err => {
    if (onError) {
      onError(err, null);
    }
  });

  ws.addEventListener('message', event => {
    const msg = JSON.parse(event.data);

    switch (msg.event) {
      case 'subscribed':
        if (msg.channel === 'ticker') {
          ws.subs = {
            ...ws.subs,
            [msg.chanId]: {
              event: 'subscribe',
              channel: 'ticker',
              symbol: `t${msg.pair}`
            }
          };
        } else {
          ws.subs = {
            ...ws.subs,
            [msg.chanId]: {
              event: 'subscribe',
              channel: 'book',
              symbol: `t${msg.pair}`,
              freq: 'F1',
              prec: 'P0'
            }
          };
        }
        break;
      case 'unsubscribed':
        ws.subs = omit(ws.subs, [msg.chanId]);
        break;
      case 'pong':
        pongTime = msg.ts;
        break;
      default:
    }

    if (onMessage) {
      onMessage(null, msg);
    }
  });
  return ws;
};

export default connectWs;
