import { catchError } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import * as tokensData from 'store/epics/tokensData';

const tokensDataEpics = [tokensData.fetchTokensDataTickersEpic];

// https://github.com/redux-observable/redux-observable/issues/263#issuecomment-334625730

const combineAndIsolateEpics = (...epics) => (...args) => {
  // eslint-disable-next-line no-shadow
  const isolatedEpics = epics.map(epic => (...args) =>
    epic(...args).pipe(
      catchError((e, source) => {
        // eslint-disable-next-line no-console
        console.warn(
          `${epic.name} terminated with error: ${e}, restarting it...`
        );
        return source;
      })
    )
  );
  return combineEpics(...isolatedEpics)(...args);
};

const rootEpic = combineAndIsolateEpics(...tokensDataEpics);

export default rootEpic;
