import React from 'react'
import { TextInput } from 'grommet'

const SimpleTextInput = ({ ...props }) => <TextInput {...props} />

SimpleTextInput.propTypes = {}

SimpleTextInput.defaultProps = {}

export default SimpleTextInput
